import React from 'react';
import CustomSelect from './CustomSelect.web';
import {MenuItem, Paper, TextField} from '@material-ui/core'
import { ICurrencyInfo } from '../../../blocks/social-media-account-registration/src/AccountinfoController.web';
import { Autocomplete } from '@material-ui/lab'
import './customAutocomplete.css'
interface Props{
    countryList:string[];
    setFieldValue:any;
    onSelected:(countryName:string)=>void;
    countryLoading:boolean;
    tabIndex?:number;
    value:string;
    error?:string;
    currencyList:ICurrencyInfo[];
    onBlur?:any;
}

export const CustomPaper = (props:any) => {
    return <Paper elevation={6} {...props} 
      style={{
        color: "#8083A3",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
      }}
    />;
  };

const CountryTextInput:React.FC<Props> = ({countryList,setFieldValue,onSelected,countryLoading,tabIndex,value,error,currencyList,onBlur}) => {
    const getCurrencyIdForCountry=(selectedCountry:string)=>{
        const findCurrency=currencyList.find((item)=>item.attributes?.name===selectedCountry);
       return findCurrency?.id || "";
    }
    return(
      <>
        <div className="typography-label">Country*</div>
        <Autocomplete
          PaperComponent={CustomPaper}
          value={value}
          onChange={(e: any, newValue: any) => {
            e.preventDefault();
            setFieldValue("country",newValue);
            setFieldValue("state","");
            setFieldValue("city","");
            setFieldValue("time_zone","");
            setFieldValue("currency",getCurrencyIdForCountry(newValue));
            onSelected(newValue)
          }}
          options={countryList}
          getOptionLabel={(option: any) => option}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder="Select your Country"
              name="country"
              className="custom-autocomplete-textfield"
            />
          )}
          id="country"
          data-testid="country"
          style={{paddingTop:"5px"}}
          onBlur={onBlur}
        />
      </>
    )
}
 
export default CountryTextInput;