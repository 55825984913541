import { IBlock } from "./../../../framework/src/IBlock";
import { Message } from "./../../../framework/src/Message";
import { BlockComponent } from "./../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "./../../../framework/src/Messages/MessageEnum";
import { runEngine } from "./../../../framework/src/RunEngine";
import React from "react";
// Customizable Area Start
export const configJSON = require("./config.js");
import { ApiCall } from './../../../components/src/ApiCall.web';
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import {toast} from 'react-toastify'
import { getNavigationMessage } from "../../../components/src/Navigation.web"


export interface ITaxInfo {
    name: string;
    sponsor_country: string;
    freelancer_country: string;
    cost: string;
    total_cost: number;
    lancer_currency:{
        currency:string;
        currency_symbol:string;
        amount:number;
    }
}

export interface IProjectCost {
    currency_conversion:any;
    totalProjectCost: number;
    firstMilestoneCost: number;
    taxForTotalProject: ITaxInfo[];
    taxForFirstMilestone: ITaxInfo[];
    totalProjectCostWithTax: number;
    totalFirstMilestonCostWithTax: number;
}

export type IBid = {
    bid: {
      data: {
        attributes: {
          is_favourite_freelancer: boolean;
        };
      };
    };
  };

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface ManageStatus {
    // Customizable Area Start
    message: string,
    status: number
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    isOpenNewBidPopup: boolean;
    anchorEl: any;
    sentNewBidToFreeLancer: boolean;
    isOpenRejectModal: boolean;
    isOpenAcceptModal: boolean;
    AcceptedNewBidFromFreelancer: boolean;
    RejectNewBidBySponsor: boolean;
    ratings: number;
    newBidAmount: string;
    AuctionBiddingData: any;
    showAllProjectsData: any;
    NewBidSendBySponsorData: any;
    getNegotiationData: Array<string | object>;
    fileName: any;
    arr: any[];
    MaganestatusData: ManageStatus,
    negotiate_id: number,
    newBidStatus: string,
    hireFreelancerLoading:boolean;
    termsAndService:{
        loading:boolean;
        title:string;
        description:string;
        open:boolean;
    },
    selectedOption: string;
    projectCost:IProjectCost|null;
    termsAccepted:boolean;
    isFavourite:boolean;
    bidLoader:boolean;
    loader:boolean;
    // Customizable Area End
}
interface SS {
    id: any;
    isUserLoggedIn: boolean;
}

export default class AdminConsoleToggleController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    AuctionBiddingBidGetAPICallID: any;
    NewBidSendBySponsorPostAPICallID: any;
    NegotiationBidGetAPICallID: any;
    ManageStatusPostAPICallID: any;
    ShowProjectsGetAPICallID: any;
    hireFreelancerApiCAllId:string="";
    getTermsAndServiceApiCallId:string="";
    getTaxParamsForBidApiCallId:string="";
    bidAction:"accepted"|"rejected"| "new_bid"|""="";
    favouritesAPICallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            isOpenNewBidPopup: false,
            anchorEl: null,
            sentNewBidToFreeLancer: false,
            isOpenRejectModal: false,
            isOpenAcceptModal: false,
            AcceptedNewBidFromFreelancer: false,
            RejectNewBidBySponsor: false,
            ratings: 4,
            newBidAmount: "",
            AuctionBiddingData: null,
            showAllProjectsData: {},
            NewBidSendBySponsorData: {},
            getNegotiationData: [],
            fileName: [],
            arr: [
                {
                    title: "Title not found",
                    description: "Description not found",
                    days: "date not found",
                    cost: 0,
                },
            ],
            MaganestatusData: {
                message: "Your negotiation",
                status: 404
            },
            negotiate_id: NaN,
            newBidStatus: "",
            hireFreelancerLoading:false,
            termsAndService:{
                loading:false,
                title:"",
                description:"",
                open:false,
            },
            selectedOption: '', 
            projectCost:null,
            termsAccepted:false,
            isFavourite:false,
            bidLoader:false,
            loader: true,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.handleAuctionBiddingGetApi();
        this.getProjectCostWithTax();
    }
    inputRef = React.createRef();


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    isValidResponse = (responseJson: any) => {
        return responseJson;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: any) => {
        return responseJson || responseJson.errors;
    };
    handleTermsChange=(e:any)=>{
        this.setState({
             termsAccepted:e.target.checked
        })
    }
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.AuctionBiddingBidGetAPICallID) {
            this.getAuctionBiddingSuccess(responseJson);
        }
        if (apiRequestCallId === this.NewBidSendBySponsorPostAPICallID) {
            this.getNewBidSendBySponsorSuccess(responseJson);
        }
        if (apiRequestCallId === this.NegotiationBidGetAPICallID) {
            this.getNegotiationBidSuccess(responseJson);
        }
        if (apiRequestCallId === this.ManageStatusPostAPICallID) {
            this.getManageStatusSuccess(responseJson);
        }
        if (apiRequestCallId === this.ShowProjectsGetAPICallID) {
            this.getShowProjectsSuccess(responseJson);
        }
        if(apiRequestCallId===this.hireFreelancerApiCAllId){
            this.handleHireFreelancerPayload(responseJson);
        } 
        if(apiRequestCallId===this.getTermsAndServiceApiCallId){
            this.handleTermsAndServicePayload(responseJson);
        }
        if(apiRequestCallId===this.getTaxParamsForBidApiCallId){
            this.handleProjectCostApiResponse(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start
    handleProjectCostApiResponse=(responseJson:any)=>{
         if(responseJson?.response){
            this.setState({projectCost:responseJson?.response})
         }
    }
    handleTermsAndServicePayload=(responseJson:any)=>{
        if(responseJson?.terms_of_service?.description) {
            this.setState({
                termsAndService:{
                    loading:false,
                    title:responseJson?.terms_of_service?.title || "",
                    description:responseJson?.terms_of_service?.description || "",
                    open:true
                }
            })
        }else if (responseJson?.terms_of_service?.url){
            window.open(responseJson.terms_of_service.url, '_blank');
        }
            
    }
    handleHireFreelancerPayload=(responseJson:any)=>{
        this.setState({
            hireFreelancerLoading:false
        })
        if(responseJson?.status===200 && responseJson?.message){
            const message =`${this.state.AuctionBiddingData.attributes?.profile_name} successfully hired for this project.`
            toast.success(message);
            this.props.navigation?.navigate("Projects",{type:"projectinprogress"})
        }else{
           toast.error("Oops! Something went wrong.")
        }
    }
    sendMessage=()=>{
        const freelancerId=this.state.AuctionBiddingData?.attributes?.freelancer_id;
        const projectId=this.state.AuctionBiddingData?.attributes?.project_id;
        const createChatMessage: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        createChatMessage.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "Chat"
        );
        createChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
         
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
          createConversation:true,
          userId:freelancerId,
          projectId:projectId,
          userType:"freelancer"
        });
        createChatMessage.addData(
          getName(MessageEnum.NavigationRaiseMessage),
          raiseMessage
        );
        this.send(createChatMessage);
    }
    handleAuctionBiddingGetApi = async () => {
        this.AuctionBiddingBidGetAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getAuctionApiMethod,
            endPoint: `${configJSON.AuctionBiddingBidsGetApiEndPoint}/${this.props.navigation.getParam("id")}?calculate=true`,
        });
    }
    // Customizable Area End
    // Customizable Area Start
    
    getAuctionBiddingSuccess = (res: IBid) => {
        this.setState({ AuctionBiddingData: res.bid?.data,isFavourite:res.bid?.data.attributes.is_favourite_freelancer , bidLoader:false, newBidAmount:""}, () => {
            this.handleNegotiationBidGetApi();
            this.handleShowProjectsGetApi();
        })
        
    }

    getAuctionBiddingFailuerCallBack = () => {
    }
    // Customizable Area End

    // Customizable Area Start
    handleNewBidSendBySponsorPostApi = async () => {
        this.bidAction="new_bid";
        const body = JSON.stringify({
            "bid": {
                lancer_bid_id: this.state.AuctionBiddingData.id,
                negotiation_amount: this.state.newBidAmount
            }
        })
        this.NewBidSendBySponsorPostAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.placeBidApiMethod,
            endPoint: configJSON.NewBidSendBySponsorApiEndPoint,
            body: body
        });
    }
    // Customizable Area End
    // Customizable Area Start
    getNewBidSendBySponsorSuccess = (res: any) => {
        let message="";
        if(this.bidAction==="accepted"){
            message="Quote Successfully Accepted."
        }else if(this.bidAction==="rejected"){
            message="Quote Successfully Rejected."
        }else if(this.bidAction==="new_bid"){
            message="New Quote Sent Successfully!"
            if(res && res.message && !res.negotiation && res.status===200){
                message=res?.message;
            }
        }


        this.setState({ NewBidSendBySponsorData: res,isOpenNewBidPopup:false,newBidAmount:"" ,bidLoader:false}, () => {
            toast.success(message)
            this.bidAction="";
        })
        this.handleNegotiationBidGetApi();
    }
    // Customizable Area End

    // Customizable Area Start
    handleNegotiationBidGetApi = async() => {
        this.NegotiationBidGetAPICallID =await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getAuctionApiMethod,
            endPoint: `${configJSON.NewBidResponseApiEndPoint}?project_id=${this.state.AuctionBiddingData?.attributes?.project_id}&bid_id=${this.props?.navigation?.getParam("id")}`,
        });
    }
    getNegotiationBidSuccess = (res: any) => {
        this.setState({ getNegotiationData: res.negotiations }, () => {
        })
    }
    isNegotiationsFromSponsorSite=(roleName:string|null)=>{
        if(!roleName){
            return false;
        }
        return roleName?.toLowerCase()?.includes("sponsor") || roleName?.toLowerCase()?.includes("both");
    }
    // Customizable Area End

    // Customizable Area Start
    handleManageStatusPostApi = async () => {
        const body = JSON.stringify({
            bid_id: this.state.AuctionBiddingData.id,
            negotiate_id: this.state.negotiate_id,
            status: this.state.newBidStatus
        })
        this.NewBidSendBySponsorPostAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.placeBidApiMethod,
            endPoint: configJSON.ManageStatusApiEndPoint,
            body: body
        });
    }
    handleCloseTermsAndServiceModal=(open:boolean)=>{
        this.setState((prev)=>({
            termsAndService:{
                ...prev.termsAndService,
                open:open
            }
        }))
    }
    getManageStatusSuccess = (res: any) => {
        this.setState({ MaganestatusData: res },()=>{
        })
    }
    // Customizable Area End

    // Customizable Area Start
    handleShowProjectsGetApi = async () => {
        this.ShowProjectsGetAPICallID = await ApiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.getAuctionApiMethod,
            endPoint: `${configJSON.ShowProjectApiEndPoint}/${this.state.AuctionBiddingData?.attributes?.project_id}`,
        });
    }
    getShowProjectsSuccess = (res: any) => {
        this.setState({ arr: res.data?.attributes?.project_milestones, showAllProjectsData: res.data?.attributes })
        this.setState({loader:false})
    }
    // Customizable Area End


    //  Sponsor logic
    // Customizable Area Start
    handleOpenPopup = (event: any) => {
        this.setState({ isOpenNewBidPopup: true, anchorEl: event?.currentTarget });
    }

    handleClosePopup = () => {
        this.setState({ isOpenNewBidPopup: false , newBidAmount:"",bidLoader:false});
    };

    handleSentAmount = () => {
        this.setState({bidLoader:true})
        const nonDuplicatedAmount = Number(this.state.newBidAmount);
        if(!this.state.newBidAmount){
            toast.error(configJSON.enterBidAmtLabel)
            this.setState({bidLoader:false})
            return;
        }
        if(nonDuplicatedAmount>0 && !isNaN(nonDuplicatedAmount)){
            this.handleNewBidSendBySponsorPostApi();
        }else{
            toast.error(configJSON.validBidAmtLabel)
            this.setState({bidLoader:false})
        }
    };
    handleCloseSponsorPopup = () => {
        this.setState({ sentNewBidToFreeLancer: false });
    };
    handleOpenRejectPopupModal = (e: any, id: number) => {
        this.setState({ isOpenRejectModal: true, negotiate_id: id, newBidStatus: e.target?.value });
    };
    handleOpenAcceptPopupModal = (e: any, id: number) => {
        this.setState({ isOpenAcceptModal: true, negotiate_id: id, newBidStatus: e.target?.value })
    };
    handleCloseAcceptPopupModal = () => {
        this.setState({ isOpenAcceptModal: false })
    };
    handleAcceptedNewBidFromFreelancer = () => {
        this.bidAction="accepted";
        this.setState({ AcceptedNewBidFromFreelancer: true }, () => {
            this.handleCloseAcceptPopupModal();
            this.handleManageStatusPostApi();
        })
    }
    handleCloseRejectPopupModal = () => {
        this.setState({ isOpenRejectModal: false })
    };
    handleRejectNewBidBySponsor = () => {
        this.bidAction="rejected";
        this.setState({ RejectNewBidBySponsor: true }, () => {
            this.handleCloseRejectPopupModal();
            this.handleManageStatusPostApi();
        })
    }
    handleNewBidAmount = (e: any) => {
        this.setState({ newBidAmount: e.target?.value });
    };


    handleRatings = (event: any, newValue: any) => {
        this.setState({ ratings: newValue });
    };

    handleNavigateToSendAnOffer = () => {
        this.props.navigation.navigate("SendAnOffer", { id: this.props?.navigation?.getParam("id") });
    }


    handleGoBackButton = () => window.history.back();
    
    // Customizable Area End

    // Customizable Area Start
    getHeader=async()=>{
        const authtoken = await getStorageData("loginSuccessToken");
        const header = {
          token: authtoken
        };
        return header;
      }
    handleContinue = async() => {
        this.setState({hireFreelancerLoading:true})
        const bidId= this.props?.navigation?.getParam("id");
        const formData=new FormData();
        formData.append("bid_id",bidId);
        const header=await this.getHeader();
        const apiMessage=CreateRestApiMessage({
            apiUrl:configJSON.hireFreelancerApiUrl,
            method:"POST",
            body:formData,
            header:header,
        })
        this.hireFreelancerApiCAllId = apiMessage.messageId;
        runEngine.sendMessage(apiMessage.id, apiMessage);
    };
    getTermOfServiceInfo = async(terms:string, responseJson: any) => {
        if(responseJson?.terms_of_service?.description) {
            this.setState(prev=>({
                termsAndService:{
                    ...prev.termsAndService,
                    loading:true,
                    open:true
                }
            }))
        }
        
        const header=await this.getHeader();
        const apiMessage=CreateRestApiMessage({
            apiUrl: `account_block/terms_of_services?title=${terms}`,
            method:"GET",
            body:null,
            header:header,
        })
        this.getTermsAndServiceApiCallId = apiMessage.messageId;
        runEngine.sendMessage(apiMessage.id, apiMessage);
     }
     getProjectCostWithTax = async() => {
        const header=await this.getHeader();
        const apiMessage=CreateRestApiMessage({
            apiUrl: `bx_block_profile/projects/get_project_costs_via_bid_id?bid_id=${this.props?.navigation?.getParam("id")}`,
            method:"GET",
            body:null,
            header:header,
        })
        this.getTaxParamsForBidApiCallId = apiMessage.messageId;
        runEngine.sendMessage(apiMessage.id, apiMessage);
     }
    

    handleAdd = () => {
        interface Obj {
            title: string;
            description: string;
            date: string;
            amount: number;
        }
        const object: Obj = {
            title: "Milestone Name1",
            description: "Lorem Ipsum dummy text",
            date: "07-11-2022",
            amount: 150,
        };
        this.setState({
            arr: [...this.state.arr, object],
        });
    };

    handleChange1 = (e: any, index: number) => {
        this.setState((prevState) => ({
            arr: prevState.arr.map((item: any, ind: any) =>
                ind === index ? { ...item, [`${e.target?.name}`]: e.target?.value } : item
            ),
        }));
    };

    handleDelete = (index: number) => {
        this.setState({
            arr: this.state.arr.filter((item, index1) => index1 !== index),
        });
    };
    handlePay = async() => {
        if(!this.state.termsAccepted){
            toast.error("Please accept terms & conditions!");
            return;
        }
        if(this.state.selectedOption.length == 0){
            toast.error("Please select deposit funds into Escrow option!");
            return;
        }
        await setStorageData('payLabel', this.state.selectedOption)
        const navigationMessage = getNavigationMessage('PayAndHire', this.props);
        navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.props.navigation.getParam("id"))
        this.send(navigationMessage);
    }

    handleOptionChange = async(event:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          selectedOption: event.target.value,
        });
        await setStorageData('payLabel', event.target.value || "full_project")
     };

    handlenavigation = () => {
    this.props.navigation.navigate("LancerPublicProfile",{profileId:this.state.AuctionBiddingData.attributes.profile_id})   
    }
    handleFavourites = async () => {
        this.setState({isFavourite:!this.state.isFavourite})
        const freelancerId=this.state.AuctionBiddingData.attributes.profile_id 
        const projectId=this.state.AuctionBiddingData.attributes.project_id 
        const token = localStorage.getItem("loginSuccessToken");
          let body = {
            favourite:{
              lancer_profile_id:freelancerId,
              project_id:projectId
            },
            project_id:projectId
          }
          const header = {
            "Content-Type": "application/json",
            token: token,
          };
          const requestMessage=CreateRestApiMessage({
            header:header,
            apiUrl:configJSON.addAndRemoveFavouritesAPIEndpoint,
            body:JSON.stringify(body),
            method:configJSON.placeBidApiMethod
        })
      
          this.favouritesAPICallId = requestMessage.messageId;
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;  
      } 

    
    // Customizable Area End
}
