import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import ChatInput from "./ChatInput.web";
import { IConversation, IMessage } from "../../../blocks/chat/src/ChatController";
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  editLoading: boolean;
  onEdit: (message:string,documents:any) => void;
  color:string;
  editMessage:IMessage | null;
  handleRemoveEditMessageAttachment:(id:number)=>void;
  selectedConversation:IConversation | null;
  sendingError:boolean
  setSendingError:any;
  isBoldState?:boolean;
  isItalicState?:boolean;
  toggleBold:any;
  toggleItalic:any;
}
const EditChatModal = ({ open, setOpen, editLoading, onEdit,color,editMessage,handleRemoveEditMessageAttachment ,selectedConversation, sendingError, setSendingError,
  isBoldState, isItalicState, toggleBold, toggleItalic
}: Props) => {
    const handleSubmit=(message:string,documents:any)=>{
       onEdit(message,documents);
    }
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { borderRadius: 16 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle id="max-width-dialog-title" style={{ paddingBottom: 0 }}>
        <strong>Edit Message</strong>
      </DialogTitle>
      <DialogContent>
        <ChatInput
          handleSendMessage={handleSubmit}
          handleSetRepliedMessage={()=>{}}
          repliedMessage={null}
          edit={true}
          color={color}
          editMessage={editMessage}
          handleRemoveEditMessageAttachment={handleRemoveEditMessageAttachment}
          selectedConversation={selectedConversation}
          sendingError={sendingError}
          setSendingError={setSendingError}
          isBoldState={isBoldState}
          isItalicState={isItalicState}
          toggleBold={toggleBold}
          toggleItalic={toggleItalic}
        />
      </DialogContent>
      <DialogActions style={{ margin: "15px" }}>
        <Button
          variant="contained"
          disableElevation
          style={{
            borderRadius: "9px",
            height: "40px",
            width: "100%",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(EditChatModal);
