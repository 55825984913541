import React from 'react';
import {Box, CircularProgress, Typography} from '@material-ui/core';
import { useAuth } from '../../../blocks/rolesandpermissions/src/AuthContext.web';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';

export const replaceBidToQuote=(value:string="")=>{
    return value?.replace("Bid","Quote");
}
const getLocalTime = (timeZone: string, time: string) => {
    const dateObj = new Date(time);
    const options: any = {
        timeZone,
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };
    try {
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const formattedTime = formatter.format(dateObj);
        return formattedTime;
    } catch (err) {
        delete options.timeZone;
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const formattedTime = formatter.format(dateObj);
        return formattedTime;
    }
};

interface Props{
    date:string;
    style?:any;
    className?:string;
}
export const TimeOnly:React.FC<Props>=({date,style={},className=""})=>{
    const currentUser=useAuth();
    const timeZone=currentUser?.user?.attributes?.time_zone || '';
    const time=getLocalTime(timeZone,date);
    return(
        <Typography className={className} style={{
            color: "#8083a3",
            fontSize: '10px',
            fontWeight: 500,
            ...style
        }}>
            {time}
        </Typography>
    )
}

export const isValidPhoneNumberForCountry = (
    phoneNumberString: string,
    country: CountryCode
  ): any => {
    try {
      const phoneNumber = parsePhoneNumberWithError(phoneNumberString, country);
  
      if (!phoneNumber) {
        return { isValid: false, error: 'Phone number format is not right' };
      }
  
      if (phoneNumber.country !== country) {
        return { isValid: false, error: 'Phone number does not match the selected country' };
      }
  
      if (!phoneNumber.isValid()) {
        return { isValid: false, error: 'Invalid phone number' };
      }
  
      const phoneLength = phoneNumber.nationalNumber.toString().length;
      if (phoneLength !== 10 && country === "IN") {
        return { isValid: false, error: 'Invalid phone number length' };
      } else if (phoneLength < 10 || phoneLength > 18) {
        return { isValid: false, error: 'Invalid phone number length' };
      }
  
      return { isValid: true, error: '' };
    } catch (error) {
      console.error('Phone number validation error:', error);
      return { isValid: false, error: 'Invalid phone number format' };
    }
  }

export const daysBetweenDates = (targetDateStr: any) => {
  const targetDate: any = new Date(targetDateStr);

  // Get today's date (without time)
  const today: any = new Date();
  today.setHours(0, 0, 0, 0); // Remove time part for accurate comparison

  // Calculate difference in milliseconds
  const diffInMs = Math.abs(targetDate - today);

  // Convert milliseconds to days
  const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
}

export const validateRequiredDays = (value:any, min:any, max:any, isFixedPriceProject:any) => {
  let error= "";
  let isValid2 = true;
  const unit = isFixedPriceProject ? "days" : "hours";

  const numericValue = Number(value);
  if(!numericValue){
    error=`Please enter required ${unit}`;
    isValid2 = false;
  }
  if (value && !Number.isInteger(numericValue)) {
    isValid2 = false;
    error = `Required ${unit} can't be in decimal`;
  } else if (value && numericValue <= min) {
    isValid2 = false;
    error = `Please enter valid required ${unit}`;
  } else if (value && numericValue >= max) {
    isValid2 = false;
    error = `Required ${unit} must be between 1 and ${max}`;
  }

  return { error, isValid2 };
}

export const checkDateLength = (valueName:any, value:any) => {
  if (valueName == 'startDate' || valueName == 'endDate') {
    const dateParts = value?.split("-");
    if (dateParts[0]?.length > 4) {
      value = `${dateParts[0].slice(0, 4)}-${dateParts[1] || ''}-${dateParts[2] || ''}`;
    }
  }
  return value;
}

export const checkDateFormats = (currentDate:any, toBeCheckedOn:any, toBeAdded:any) =>{
  if(toBeCheckedOn?.includes("year")){
    currentDate.add(toBeAdded,"years");
  }else if(toBeCheckedOn?.includes("month")){
    currentDate.add(toBeAdded,"months");
  }else if(toBeCheckedOn?.includes("hour")){
    currentDate.add(toBeAdded,"hours");
  }else if(toBeCheckedOn?.includes("day")){
    currentDate.add(toBeAdded,"days");
  }
  return currentDate;
}

export const LoaderWithLoadingText = () => {
  return (<Box position="relative" display="inline-flex" style={{
    marginTop: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  }}>
    <CircularProgress size={80} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary">Loading...</Typography>
    </Box>
  </Box>)
}

export const checkCompanyError = (value:any, state:any) => {
  if(value.trim()===""){
    state.companyAddressError = true
    return true
  } else {
    state.companyAddressError = false
    return false
  }
}