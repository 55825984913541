import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {  Field} from "formik";
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import CustomSelect from '../utill/CustomSelect.web';
import {Grid, MenuItem, TextField} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CustomPaper } from '../utill/CountryTextInput.web';

interface Props{
    setFieldValue:(key:string,value:string)=>void;
    index:number;
    getStateList:(country:string)=>void;
    getCityList:(state:string)=>void;
    countryLoading:boolean;
    experienceErrors:any;
    values:any;
    cityLoading:boolean;
    getStateLoading:boolean;
    countryList:string[];
    cityList:string[];
    stateList:string[];
    touched: any;
    onBlur: any;
}

const LocationContainer:React.FC<Props>=({
    setFieldValue,
    index,
    getStateList,
    countryLoading,
    experienceErrors,
    values,
    getCityList,
    cityLoading,
    getStateLoading,
    countryList:mCountryList,
    cityList:mCityList,
    stateList:mStateList,
    touched,
    onBlur
})=>{
    const [countryList,setCountryList]=useState<string[]>(mCountryList);
    const [stateList,setStateList]=useState<string[]>([]);
    const [cityList,setCityList]=useState<string[]>([]);
    const [selectedCountry,setSelectedCountry]=useState<string>("");
    const [selectedState,setSelectedState]=useState<string>("");
    const changingFieldName=`country_state_${index}`


    useEffect(()=>{
        setCountryList(mCountryList)
    },[countryLoading,mCountryList])

    useEffect(()=>{
        console.log({
            countryCheck:!getStateLoading && values?.career_experiences[index]?.country?.trim()===selectedCountry,
            indexCountry:values?.career_experiences[index]?.country?.trim(),
            selectedCountry:selectedCountry
        })
        const prevCountry=values?.career_experiences[index]?.country?.trim()
        if(!getStateLoading && values?.change_experiences_for===changingFieldName){
            setStateList(mStateList)
        }
    },[getStateLoading,mStateList]) 
    useEffect(()=>{
        const prevState=values?.career_experiences[index]?.state?.trim();
        if(!cityLoading && values?.change_experiences_for===changingFieldName){
            setCityList(mCityList)
        }
    },[cityLoading,mCityList])


  return (
    <>
      <div className="d-flex">
        <Grid item sm={12} xs={12}>
          <div className="typography-label">Country*</div>
          <Autocomplete
            PaperComponent={CustomPaper}
            value={values?.career_experiences[index]?.country}
            onChange={(e: any, newValue: any) => {
              setFieldValue(`career_experiences.${index}.country`, newValue);
              setFieldValue(`career_experiences.${index}.state`, "");
              setFieldValue(`career_experiences.${index}.city`, "");
              setFieldValue("change_experiences_for", changingFieldName);
              setSelectedCountry(newValue);
              getStateList(newValue)
            }}
            options={countryList}
            getOptionLabel={(option: any) => option}
            renderInput={(params: any) => (
              <TextField
                {...params}
                placeholder="Select Country"
                name={`career_experiences.${index}.country`}
                className="custom-autocomplete-textfield"
              />
            )}
            id={`career_experiences.${index}.country`}
            data-testid="country"
            style={{ paddingTop: "5px" }}
            onBlur={onBlur}
          />
          {experienceErrors[index]?.country && touched.map((item: any, idx: number) => {
            if (idx == index && item?.country)
              return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                {experienceErrors[index]?.country}
              </p>)

          })}
        </Grid>
        {
          values?.career_experiences[index]?.country?.trim() && stateList.length > 0 ? (
            <Grid item sm={12} xs={12} style={{marginTop:"5px"}}>
              <div className="typography-label">State*</div>
              <Autocomplete
                value={values?.career_experiences[index]?.state}
                data-testid="city"
                PaperComponent={CustomPaper}
                onChange={(e: any, newValue: any) => {
                  setFieldValue(`career_experiences.${index}.state`, newValue);
                  setSelectedState(newValue);
                  setFieldValue(`career_experiences.${index}.city`, "");
                  setFieldValue("change_experiences_for", changingFieldName);
                  getCityList(newValue)
                }}
                options={stateList}
                getOptionLabel={(option: any) => option}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select state"
                    name={`career_experiences.${index}.state`}
                    className="custom-autocomplete-textfield"
                  />
                )}
                id={`career_experiences.${index}.state`}
                disabled={!values?.career_experiences[index]?.country?.trim()}
                onBlur={onBlur}
              />
              {experienceErrors[index]?.state && touched.map((item: any, idx: number) => {
                if (idx == index && item?.state)
                  return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                    {experienceErrors[index]?.state}
                  </p>)

              })}
            </Grid>
          ) : (
            <div className="form-group" id="checkpls">
              <label
                className="helloooo"
                htmlFor={`career_experiences.${index}.state`}
              >
                State*
              </label>
              <Field
                placeholder="Enter state"
                disabled={!values?.career_experiences[index]?.country?.trim()}
                name={`career_experiences.${index}.state`}
                id={`career_experiences.${index}.state`}
                onChange={(e: any) => {
                  setFieldValue(`career_experiences.${index}.state`, e.target?.value);
                  setFieldValue(`career_experiences.${index}.city`, "");
                  setFieldValue("change_experiences_for", changingFieldName);
                  setSelectedCountry(e.target?.value);
                }}
                onBlur={onBlur}
              />
              {experienceErrors[index]?.state && touched.map((item: any, idx: number) => {
                if (idx == index && item?.state)
                  return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                    {values?.career_experiences[index]?.country?.trim() && stateList.length === 0 && experienceErrors[index]?.state}
                  </p>)

              })}
              {/* <InputError errors={touched[index]?.state && values?.career_experiences[index]?.country?.trim() && stateList.length===0 &&   experienceErrors[index]?.state?{state:"Please enter state"}:experienceErrors[index] || {}} name="state"/> */}
            </div>
          )
        }
      </div>

      {
        !cityLoading && cityList?.length === 0 && values?.career_experiences[index]?.country && values?.career_experiences[index]?.state ? (
          <div className="form-group" id="checkpls">
            <label
              className="helloooo"
              htmlFor={`career_experiences.${index}.city`}
            >
              City*
            </label>
            <Field
              name={`career_experiences.${index}.city`}
              id={`career_experiences.${index}.city`}
              placeholder="Enter City"
            />
            {experienceErrors[index]?.city && touched.map((item: any, idx: number) => {
              if (idx == index && item?.city)
                return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                  {!cityLoading && cityList?.length === 0 && values?.career_experiences[index]?.country && values?.career_experiences[index]?.state && experienceErrors[index]?.city}
                </p>)

            })}
            {/* <InputError errors={experienceTouched[index]?.city && !cityLoading && cityList?.length===0 && values?.career_experiences[index]?.country && values?.career_experiences[index]?.state && experienceErrors[index]?.city?{city:"Please enter city"}:experienceErrors[index] || {}} name="city"/> */}
          </div>
        ) : (
            <>
              <div className="typography-label">City*</div>
              <Autocomplete
                value={values?.career_experiences[index]?.city}
                data-testid="city"
                PaperComponent={CustomPaper}
                onChange={(e: any, newValue: any) => {
                  setFieldValue(`career_experiences.${index}.city`, newValue);
                }}
                options={cityList}
                getOptionLabel={(option: any) => option}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select city"
                    name={`career_experiences.${index}.city`}
                    className="custom-autocomplete-textfield"
                  />
                )}
                id={`career_experiences.${index}.city`}
                disabled={!values?.career_experiences[index]?.state?.trim()}
                style={{ paddingTop: "5px" }}
                onBlur={onBlur}
              />
              {experienceErrors[index]?.city && touched.map((item: any, idx: number) => {
                if (idx == index && item?.city)
                  return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                    {experienceErrors[index]?.city}
                  </p>)

              })}
          {/* <ExpandMoreIcon />
                                        <label
                                          className="helloooo"
                                          htmlFor={`career_experiences.${index}.city`}
                                        >
                                          City*
                                        </label>
                                        <Field
                                          name={`career_experiences.${index}.city`}
                                          id={`career_experiences.${index}.city`}
                                          as="select"
                                          disabled={!values?.career_experiences[index]?.state?.trim()}
                                        >
                                          <option value="" selected hidden>
                                            Select city
                                          </option>
                                          {
                                            cityLoading?(
                                              <option value="" disabled>Loading...</option>
                                            ):cityList?.map((city)=>(
                                              <option value={city} key={city}>{city}</option>
                                            ))
                                          }
                                        </Field> */}
        </>)
      }
    </>
  )

}


export default LocationContainer;