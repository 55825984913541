import React from 'react';
import { Box, Typography,  Divider, CardMedia, CircularProgress,Grid,Avatar } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { configJSON } from '../../blocks/customisableuserprofiles2/src/PublicProfileController.web';
import Rating from "@material-ui/lab/Rating";
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { location} from "../../blocks/auctionbidding/src/assets";
import ProjectLocalTime from './ProjectLocalTime.web';
import { format } from "date-fns";
import StarRatings from 'react-star-ratings';

interface SponsorFilterProps {
    instance: any
    isLoading: any
    profileInfo: any
    profileLancer: any
    isLoggedinUserSponsor: any
}

const SponsorFilter: React.FC<SponsorFilterProps> = ({ isLoading, profileInfo, profileLancer, instance, isLoggedinUserSponsor }) => {
  
    return (
        <>
            {isLoading ?
                <Box style={webStyle.loader as React.CSSProperties}>
                    <CircularProgress />
                </Box> :
                <>
                    {profileInfo && 
                    <Box className="resDetailMain">
                        <Box style={webStyle.mainprofilebox}>
                            <Box style={webStyle.titleboxprofile} className='resTitleBoxProfile'>
                                <Box style={webStyle.backIconbutton}
                                    data-test-id="backbutton"
                                    onClick={instance.onGoBack}
                                >
                                    <KeyboardBackspaceIcon />
                                    {configJSON.backBtn}
                                </Box>
                                {<Typography style={webStyle.headerTextBtn}>
                                    {!isLoggedinUserSponsor ? configJSON.sponsorDetailsText : configJSON.freelancerDetailsText}
                                </Typography>}
                            </Box>
                        </Box>
                        <Box mb={3} style={webStyle.projectDescriptionBox}>
                            <Box style={webStyle.progressDescriptionBoxContainer}>

                                <Grid container style={webStyle.frelancerDetails}>
                                    <Grid item   style={webStyle.AvatarBoxStyle}>
                                    <Box style={webStyle.profileAvatar}>
                                    <Avatar
                                         src={profileInfo?.attributes?.photo?.length > 0 ? profileInfo?.attributes.photo[0]?.url : ""}
                                         style={webStyle.avatarActive}
                                    />
                                        {/* <CardMedia
                                            style={webStyle.avatarActive}
                                            component="img"
                                            alt="profile"
                                            image={profileInfo?.attributes?.photo?.length > 0 ? profileInfo?.attributes.photo[0]?.url : ""}
                                        /> */}
                                         <div style={{...webStyle.freelancerSearchUserOnline, backgroundColor: profileInfo?.attributes?.availability ? "green" : "orange"}}/>
                                    </Box>
                                    </Grid>
                                    
                                       {/* ---------- grid item -------------------- */}
                                    <Grid item >
                                        <Typography style={webStyle.lancerNameText}>
                                            {profileInfo?.attributes?.full_name}
                                        </Typography>
                                        <Box style={webStyle.skillsBxs}>

                                            <Box style={webStyle.boxLocation}>
                           
                                                <Box style={webStyle.locationMainBoxStyle}>
                                                <img src={location} alt="location" style={webStyle.imageStyle}/>
                                                <Typography style={webStyle.timeAndCountry}>
                                                    {profileInfo?.attributes?.city},{" "}{profileInfo?.attributes?.state},{" "}{profileInfo?.attributes?.country}{" "}
                                                </Typography>
                                                </Box>   
                                                <Box style={{color:'#8083a3'}}>·</Box>
                                                <Box style={webStyle.timeMainBoxStyle}>
                                                    <AccessTimeIcon style={webStyle.imageStyle} />
                                                    <ProjectLocalTime timezoneData={profileInfo?.attributes?.time_zone } />
                                                    <Typography style={webStyle.localTimeStyle}>{configJSON.localTimeLabel}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                           
                            {/* ------------------- display main skills ------------------------------ */}
                            {isLoggedinUserSponsor && <>
                                <Divider />
                            <Box padding={3}>
                                <Box style={webStyle.flexItems}>                            
                                    <Box style={webStyle.skillGreyBox}>
                                        {profileInfo?.attributes?.skills.map((skill: any) => (
                                            <Box style={webStyle.skillGreyBoxCategory}>{skill.name}</Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box style={webStyle.flexItems2}>
                                    {profileInfo?.attributes?.experience_in_years && (
                                        <Box style={webStyle.flexItemChild}>
                                        <Typography style={webStyle.timeAndCountry}>Total Experience</Typography>
                                        <Typography style={webStyle.projectAndReviewsText}>
                                            {profileInfo.attributes.experience_in_years} Years
                                        </Typography>
                                        </Box>
                                    )}

                                    {profileInfo?.attributes?.past_completed_projects?.data && (
                                        <Box style={webStyle.flexItemChild}>
                                        <Typography style={webStyle.timeAndCountry}>Total Projects</Typography>
                                        <Typography style={webStyle.projectAndReviewsText}>
                                            {profileInfo.attributes.past_completed_projects.data.length} Projects
                                        </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            </>
                            }  
                            {!isLoggedinUserSponsor && (
                            <>
                                <Divider />
                                <Box padding={3} style={webStyle.flexItems}>
                                {profileInfo?.attributes?.account_detail?.data?.attributes?.created_at && (
                                    <Box style={webStyle.flexItemChild}>
                                    <Typography style={webStyle.timeAndCountry}>Member Since</Typography>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {format(new Date(profileInfo.attributes.account_detail.data.attributes.created_at), 'dd-MMM-yyyy')}
                                    </Typography>
                                    </Box>
                                )}

                                {profileInfo?.attributes?.past_completed_projects?.data?.length > 0 && (
                                    <Box style={webStyle.flexItemChild}>
                                    <Typography style={webStyle.timeAndCountry}>Total Projects</Typography>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {profileInfo.attributes.past_completed_projects.data.length} Projects
                                    </Typography>
                                    </Box>
                                )}

                                {profileInfo?.attributes?.company_details?.data?.[0]?.attributes?.company_name && (
                                    <Box style={webStyle.flexItemChild}>
                                    <Typography style={webStyle.timeAndCountry}>Company Name</Typography>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {profileInfo.attributes.company_details.data[0].attributes.company_name}
                                    </Typography>
                                    </Box>
                                )}

                                {profileInfo?.attributes?.company_details?.data?.[0]?.attributes?.number_of_employee > 0 && (
                                    <Box style={webStyle.flexItemChild}>
                                    <Typography style={webStyle.timeAndCountry}>No of Employee</Typography>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {profileInfo.attributes.company_details.data[0].attributes.number_of_employee} Employees
                                    </Typography>
                                    </Box>
                                )}

                                {profileInfo?.attributes?.company_details?.data?.[0]?.attributes?.company_contact && (
                                    <Box style={webStyle.flexItemChild}>
                                    <Typography style={webStyle.timeAndCountry}>Company Contact</Typography>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {profileInfo.attributes.company_details.data[0].attributes.company_contact}
                                    </Typography>
                                    </Box>
                                )}
                                </Box>
                            </>
                            )}
 
                        </Box>

                        {/* ------------------------------ reviews -------------------------------------- */}
                        <Box className="personal-details-wrapper" style={{
                            maxHeight:"350px" , overflowY:"auto"
                        }}>
                            <Box mb={3} style={{...webStyle.projectDescriptionBox, border: "0px", marginBottom: 0}}>
                                <Box padding={3}>
                                    <Typography style={webStyle.projectAndReviewsText}>
                                        {configJSON.pastProjectsLabel} ({profileInfo?.attributes?.past_completed_projects?.data?.length})
                                    </Typography>
                                    <Divider style={{margin: "10px -24px 0 -24px", backgroundColor: "rgba(0, 0, 0, 0.08)"}}/>
                                    {profileInfo?.attributes?.past_completed_projects?.data?.map((data: any, index: number) => {
                                        return (
                                            <>
                                            <Box style={{padding: "5px 0px"}}>
                                                {data.attributes?.project_details && (
                                                    <Box pt={2.5}>
                                                        <Typography style={webStyle.projectNameText}>
                                                            {data.attributes.project_details.project_name}
                                                        </Typography>
                                                        <Box p={0.2} style={{ display: "flex",alignItems: "first baseline", marginTop: "5px" }}>
                                                            <StarRatings
                                                                rating={data.attributes?.average_rating ? data.attributes?.average_rating : 0}
                                                                starDimension="12px"
                                                                starSpacing="2px"
                                                                starRatedColor="orange"
                                                                starEmptyColor="gray"
                                                                starHoverColor="orange"
                                                                data-test-id="ratingIcons" /> 
                                                                <Typography color="textSecondary" className="freelancer-search-ratings">
                                                                    <span style={{ fontSize: "12px", color: "#8083A3", marginLeft: "10px", fontWeight: "bold" }}>
                                                                    {data.attributes?.reviews ? `Posted On ${moment(data.attributes.reviews[0].created_at).format("DD-MM-YYYY")}` : null}
                                                                    </span>
                                                                </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            {data.attributes?.reviews &&  <Box>
                                                    <Typography style={webStyle.pastProjectDescription}>
                                                        {data.attributes.reviews[0]?.description}
                                                    </Typography>
                                                </Box>}
                                            { data.attributes?.reviews && <Box>
                                                    <Typography style={webStyle.pastProjectownerName}>
                                                        Feedback by {data.attributes.reviews[0]?.reviewer_profile.reviewer_name}
                                                    </Typography>
                                                </Box>}
                                                <Box>
                                                </Box>
                                            </Box>
                                            {profileInfo?.attributes?.past_completed_projects?.data?.length > (index + 1) && <Divider style={{margin: "10px -24px 0 -24px", backgroundColor: "rgba(0, 0, 0, 0.08)"}}/>}
                                            </>
                                        )
                                    })}
                                    {!profileInfo?.attributes?.past_completed_projects?.data?.length && 
                                    <Box paddingTop={2.5} paddingBottom={1} >
                                    <Typography style={webStyle.reviewTxtStyle}>
                                    {configJSON.NoData}
                                    </Typography>
                                    </Box>
                                    }

                                    
                                </Box>
                            </Box>
                        </Box>

                        {isLoggedinUserSponsor && 
                        <Box mb={3} style={webStyle.projectDescriptionBox}>
                            <Box p={3}>
                                <Typography style={webStyle.detailsExperience}>
                                    {configJSON.experienceDetailsText}
                                </Typography>
                                {profileInfo?.attributes?.work_experiences ? profileInfo?.attributes?.work_experiences?.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>

                                                <Box pt={2.5}>
                                                <Typography style={webStyle.projectNameText}>
                                                 {item.data?.attributes?.job_title}
                                                </Typography>
                                            </Box>
                                            <Typography style={webStyle.timeAndCountry}>
                                            {item.data?.attributes?.employer_name}

                                            </Typography>
                                            <Typography style={webStyle.timeAndCountry}>
                                                {item.data?.attributes?.description}
                                            </Typography>
                                        </div>
                                    )
                                }) : <Box paddingTop={2.5} paddingBottom={1} >
                                    <Typography style={webStyle.roleAndResponsibilites}>
                                      {configJSON.NoData}
                                    </Typography>
                                </Box>}
                            </Box>
                        </Box>
                        }

                        {isLoggedinUserSponsor && 
                        <Box mb={3} style={webStyle.projectDescriptionBox}>
                            <Box padding={3}>
                                <Typography style={webStyle.detailsExperience}>
                                    {configJSON.educationDetailsText}
                                </Typography>
                                {profileInfo?.attributes?.educational_qualification ? profileInfo?.attributes?.educational_qualification?.map((item: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            <Box pt={2.5}>
                                                <Typography style={webStyle.projectNameText}>
                                                {item.data?.attributes?.education}({item.data?.attributes?.field_study})
                                               
                                                </Typography>
                                            </Box>
                                            <Typography style={webStyle.timeAndCountry}>
                                            {item.data?.attributes?.university_college}
                                            </Typography>
                                        </div>
                                    )
                                }) : <Box paddingTop={2.5} paddingBottom={1} >
                                    <Typography style={webStyle.roleAndResponsibilites}>
                                    {configJSON.NoData}
                                    </Typography>
                                </Box>}
                            </Box>
                        </Box>
                        }
                    </Box>}
                </>
            }
        </>
    )
};

export const webStyle = {
    loader: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-20%, -50%)',
    },
    currentDay: {
        color: "#8083A3",
        fontSize: "12px",
        fontWeight: 500
    },
    roleAndResponsibilites: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#8083A3",
        fontFamily:'inter'
    },
    detailsExperience: {
        fontSize: "18px",
        fontWeight: 700,
        color: "#222"
    },
    timeAndCountry: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#8083A3"
    },
    locationOnIcon: {
        fontSize: "1rem",
        color: "rgba(0, 0, 0, 0.87)!important"
    },
    boxLocation: {
        display: "flex",
        justifyContent: "left",
        flexDirection:'row' as const,
        gap: '7px',
        alignItems: 'last baseline' as const
    },
    skillsText: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#8083A3"
    },
    skillsBxs: {
        alignItems: "center",
        justifyContent: "left",
        marginTop: "5px"
    },
    lancerNameText: {
        color: "#333",
        fontSize: "16px",
        fontWeight: 600
    },
    ratingMainBox: {
        display: "flex",
        gap: "10px"
    },
    ratingText: {
        color: "rgb(236, 149, 45)",
        fontSize: "1rem",
        marginRight: "2px"
    },
    smallText: {
        fontWeight: 500,
        fontSize: "12px",
        color: "#8083A3"
    } as const,
    pastProjectownerName: {
        fontSize: "12px",
        marginTop: "5px",
        color: "#8083A3",
        fontWeight: "bold"
    } as const,
    headerTextBtn: {
        color: "#222",
        fontSize: "28px",
        fontWeight: "bold",
    } as const,
    pastProjectDescription: {
        color: "#222",
        fontSize: "14px",
        fontWeight: 400
    } as const,
    projectNameText: {
        color: "#222",
        fontSize: "15px",
        fontWeight: 700
    } as const,
    projectAndReviewsText: {
        color: "#000",
        fontSize: "18px",
        fontWeight: 700
    } as const,
    freelancerSearchUserOnline: {
        position: "absolute",
        top: "60px",
        right: "3px",
        height: "10px",
        width: "10px",
        borderRadius: "50%",
    } as const,
    avatarActive: {
        height: "80px",
        width: "100%",
        borderRadius: "50%",
        objectFit: "cover",

        position: "relative"
    } as const,
    frelancerDetails: {
        display: "flex",
        alignItems: "center",
        gap:"20px",
        width:'100%',
        flexWrap:"wrap"
    } as const,
    profileAvatar: {
        position: "relative",
        width: "80px",
        height: "80px",
    } as const,
    lancerName: {
        fontSize: "20px",
        fontWeight: "400",
        margin: "0"
    },

    mainprofilebox: {
        display: "flex",
        flexDrection: "column",
        gap: "30px",
        fontFamily: "inter",
        // padding: " 32px 200px"
    },
    amtTextContent: {
        fontSize: "16px",
        color: "#222",
        fontWeight: 700,
        fontFamily: "Inter"
    },
    titleboxprofile: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        marginBottom: "20px"
    },
    amtTextHeader: {
        color: "8083A3",
        fontSize: "12px",
        fontFamily: "Inter"
    },
    progressDescriptionText: {
        fontSize: "14px",
        color: "#222",
        fontWeight: 400,
        fontFamily: "Inter"
    },
    progressDescriptionBoxContainer: {
        borderRadius: "15px",
        padding: "20px"
    },
    projectDescriptionBox: {
        borderRadius: "15px",
        marginBottom: "24px",
        border: "1px solid rgb(228, 230, 232)",
        cursor: "pointer",
        // marginRight: "200px",
        // marginLeft: "200px",
    },
    skillGreyBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: "0.5vw",
        // padding: "0 1vw 1vw 1vw",
        flexWrap:"wrap" as const,
    },
    skillGreyBoxCategory: {
        background: "#E4E6E8",
        color: "#8083A3",
        padding: "6px 20px",
        borderRadius: "20px",
        margin: "4px"
    },
    progressDescriptionTextBox: {
        padding: "1vw 2vw"
    },
    amtDetailsContainer: {
        display: "flex",
        justifyContent: " space-between",
        alignItems: "center",
        padding: "1vw 2vw"
    },
    backIconbutton: {
        color: "#222",
        fontSize: "14px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f2f2f2",
        height: "42px",
        borderRadius: "23px",
        width: "max-content",
        padding: "0 16px",
        gap: "10px",
        cursor: "pointer"
    } as const,

    AvatarBoxStyle:{
        display:'flex',
        alignItems:'center',
      
    },
    locationMainBoxStyle:{
        display:'flex',
        paddingTop:'5px',
        alignItems:'center'
    },
    timeMainBoxStyle:{
        paddingTop:'5px',
        display:'flex',
        alignItems:'center'
    },
    imageStyle:{
        height:'15px',
        width:'15px',
        color:'#8083a3',
        marginRight:'5px'
    },
    localTimeStyle:{
        color :'#8083a3'
        ,fontSize: '12px',
         fontWeight: 400,
        fontFamily:'inter',
        marginLeft:'5px'
        },
        reviewTxtStyle: {
            fontSize: "14px",
            fontWeight: 500,
            color: "#8083A3",
            fontFamily:'inter',
            paddingTop:'10px'
        },
        ratingStarStyle:{
            fontSize:'14px'
        },
        flexItems: {
            display: 'grid',
            width: '70%',
            gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        },
        flexItems2: {
            display: 'grid',
            padding: '10px 0px 0px 10px',
            width: '70%',
            gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        },
        flexItemChild: {
            minWidth: '100px',
        },
        mainmodal: {
            padding:"0px",
          },
        dialogContent: {
            padding: "0px",
        }
};

export default SponsorFilter;
