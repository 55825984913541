import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  Paper,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { configJSON } from "./ProjecttemplatesController";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
import Sponsorlistingcontroller, { Props } from "../../joblisting/src/Sponsorlistingcontroller.web";
import CustomConfirmDialog from "../../../components/src/utill/CustomConfirmDialog.web";
// Customizable Area End


export default class EditExistingDraftPage extends Sponsorlistingcontroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tabClassNames = (chipActive: boolean) =>
    chipActive
      ? this.props.classes.activeTabStyle
      : this.props.classes.tabStyle;
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { loading, projectdata, totalProjectsCount } = this.state;
    const isShowMore = projectdata?.length > 0 && totalProjectsCount > projectdata?.length;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <ProjectFilterWeb
          onSearch={this.searchProjectHandler}
          onFilter={this.filterProjectHandler}
          onCancelFilter={this.cancelFilterProjectHandler}
          activeTabColor="#00A7A2"
        />
        <Box className={classes.projectchipsContainer} data-test="active-chip">
          {this.state.chipNames.map((chip: any, index: number) => (
            <Button
              data-testid={`active-chip`}
              key={chip.name}
              className={this.tabClassNames(chip.isActive)}
              onClick={() => this.handleChipClick(index)}
            >{chip.name}</Button>
          ))}
        </Box>
        {
          !loading && projectdata?.length === 0 && (
            <Paper className="border-block p-0 projectloading-main" style={{ height: "auto" }}>
              <div className="emptyDraftPage">
              <div className='projectloading' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h2 style={{ padding: "10px", fontWeight: "bolder" }}>Oops !</h2>
                <p style={{ margin: "0px", fontWeight: "bolder", color: "#505251" }}>Project Drafts are Empty</p>
                <p style={{ margin: "0px", padding: "10px", fontWeight: "normal", color: "gray" }}>Let's post a project for your next opening</p>
                <div className="dashboardBtn">
                  <Button
                    id="btnchange"
                    data-testid={`dashboardbtn`}
                    className={classes.dashboardBtnStyle}
                  onClick={()=>this.props.navigation.navigate("SponsorDashboard")}
                  >
                    {configJSON.dashboardbutton}
                  </Button>
                  <Button
                   data-testid={`postbutton`}
                    id="btnchange"
                    className={classes.continueBtnStyle}
                    onClick={()=>this.props.navigation.navigate("Projects",{type:"createNewProject"})}>
                    {configJSON.postbutton}
                  </Button>
                </div>
              </div>
              </div>
            </Paper>
          )
        }
        {
          loading && projectdata?.length === 0 && (
            <Paper className="border-block p-0 projectloading-main" >
              <div className='projectloading'>
                <CircularProgress size={28} />
              </div>
            </Paper>
          )
        }
        {
          projectdata?.length > 0 && projectdata?.map((projectDetails: any) => {
            const estimatedBudget = projectDetails?.attributes?.budget_type === "hourly_price" ? `${projectDetails?.attributes?.currency_conversion?.to.currency_symbol}${projectDetails?.attributes?.currency_conversion?.to.amount} /hr` : `${projectDetails?.attributes?.currency_conversion?.to.currency_symbol}${projectDetails.attributes.currency_conversion?.to.amount}`
            return (
              <Box
                className={classes.mainContainer}
                key={projectDetails.attributes.id}
              >
                <Paper className={classes.borderBlock}>
                  <Box className={classes.designLogoBlock}>
                    <Typography className={classes.titleStyle}>
                      {projectDetails.attributes.project_name}
                    </Typography>
                    <span className={classes.fixedPriceStyle}>
                      {projectDetails.attributes.budget_type === "hourly_price" ? "Hourly Rated" : "Fixed Price"} 
                    </span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                    <span className={classes.fixedPriceStyle}>
                    Est.Time: {projectDetails.attributes.EST_time}
                    </span><span style={{ color: 'gray', margin: '0 5px' }}> • </span>
                    <span className={classes.fixedPriceStyle}>
                      {projectDetails.attributes.posted_at}
                    </span>
                    <br />
                    <Box className={classes.chipsContainer}>
                      {projectDetails.attributes.skills.map((skill: any) => (
                        <ul id="ul" key={skill.id}>
                          <li>
                            <Chip
                              data-test="chipdata"
                              label={skill.name}
                              className={classes.chipStyle}
                            />
                          </li>
                        </ul>
                      ))}
                    </Box>
                    <Typography className={classes.descriptionStyle}>
                      {projectDetails.attributes.description}
                    </Typography>
                    <Box className={classes.designContent}>
                      <Box className={classes.detailsContainer}>
                        <Typography
                          className={classes.designDetailsDescription}
                        >
                          {configJSON.estimatedBudgetLabelTxt}
                        </Typography>
                        <Typography className={classes.designDetailsHeading}>
                          {estimatedBudget}
                        </Typography>
                      </Box>
                      <Box className={classes.detailsContainer}>
                        <Typography
                          className={classes.designDetailsDescription}
                        >
                          {configJSON.experianceLabelTxt}
                        </Typography>
                        <Typography className={classes.designDetailsHeading}>
                          {projectDetails.attributes.expected_experience} years
                        </Typography>
                      </Box>
                      <Box className={classes.detailsContainer}>
                        <Typography
                          className={classes.designDetailsDescription}
                        >
                          {configJSON.languageLabelTxt}
                        </Typography>
                        <Typography className={classes.designDetailsHeading}>
                          {projectDetails.attributes.language_of_converse}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                <Box component="hr" />
                <Box className={classes.btnContainerStyle}>
                  <Button
                    className={classes.continueBtnSyle}
                    id="btn1"
                    onClick={
                      this.props.getSingleProject && this.props.getSingleProject.bind(this, projectDetails.id)
                    }
                  >
                    {configJSON.reuseAndEditBtnTxt} 
                  </Button>
                  {
                    this.props.name === "draft" && (
                      <Button
                        data-testid="deleteBtn"
                        className={classes.backBtnStyle}
                        id="btn2"
                        onClick={() => this.deleteConfirmationDialog(projectDetails.id)}
                      >
                        {configJSON.deleteBtnTxt}
                      </Button>
                    )
                  }
                </Box>
              </Box>
            );
          })
        }
        <CustomConfirmDialog 
          data-testid="openDialogClick"
          open={this.state.isOpen} 
          onClose={this.handleCloseDialog}
          message="Are you sure you want to delete this draft?"
          yesText="Yes"
          cancelText="Cancel"
          onConfirm={this.deleteProject}
        />
        {
          loading && projectdata?.length > 0 && (
            <Paper className="border-block p-0 projectloading-main" >
              <div className='projectloading'>
                <CircularProgress size={28} />
              </div>
            </Paper>
          )
        }
        {
          isShowMore && !loading && (
            <div className="news-button">
              <Button
                data-testid={`active-chip`}
                className={this.tabClassNames(true)}
                onClick={this.handleProjectLoadMore}
              >
                Show More
              </Button>
            </div>
          )
        }
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = (theme: any) => ({
  mainContainer: {
    border: "1px solid #E4E6E8",
    borderRadius: 14,
    marginTop: 30
  },
  searchBarBox: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    gap: "30px"
  },
  tabStyle: {
    borderRadius: 20,
    background: "#fff",
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 700,
    marginRight: 10,
    textTransform: "capitalize" as "capitalize",
    padding: "7px 25px",
    height: "40px",
    '&:hover': {
      background: "#fff",
    }
  },
  filterBtnContainerStyle: {
    marginTop: 30
  },
  filterBtnStyle: {
    color: "#fff",
    borderRadius: "26px",
    fontSize: "14px",
    fontWeight: 600,
    padding: "0px 18px",
    background: "#00A7A2",
    height: "40px",
    width: "100px",
    border: "none",
    cursor: "pointer"
  },
  searchIconStyle: {
    backgroundColor: "#00A7A2",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    border: "none",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  searchFieldStyle: {
    paddingRight: "5px",
    width: "100%",
    borderRadius: "35px"
  },
  clearIconStyle: {
    backgroundColor: "#EC952D",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    border: "none",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
    cursor: "pointer"
  },
  borderBlock: {
    borderRadius: 14,
    marginBottom: 30,
    boxShadow: "none",
    margin: 10
  },
  designLogoBlock: { padding: "10 15" },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    gap: 10,
    margin: "20px 0"
  },
  continueBtnBlockStyle: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 100
  },
  continueBtnStyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    padding : "5px 20px",
    textTransform: "capitalize" as "capitalize",
    '&:hover': {
      background: "#00A7A2",
    }
  },
  dashboardBtnStyle: {
    borderRadius: 23,
    backgroundColor: "#F2F2F2",
    color: "black",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    padding : "5px 20px",
    textTransform: "capitalize" as "capitalize",
    '&:hover': {
      background: "#F2F2F2",
    }
  },
  chipStyle: {
    background: "#F6F7F7",
    padding: "10px 30px",
    borderRadius: "30px",
    height: "40px",
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 500,
    "& span": {
      whiteSpace:' break-spaces'
    }
  },
  designContent: { columnGap: 50, display: "flex",flexWrap: 'wrap' as const, },
  designDetailsDescription: {
    margin: 0,
    color: "#8083A3",
    fontWight: 500,
    fontSize: 12,
    padding: 0,
    fontFamily: "Inter"
  },
  designDetailsHeading: {
    fontSize: 16,
    fontWeight: 700,
    margin: 0,
    fontFamily: "Inter",
    color: "#222"
  },
  titleStyle: {
    color: "#000",
    fontFamily: "Inter",
    fontSize: 18,
    fontWeight: 700
  },
  fixedPriceStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 500
  },
  descriptionStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
    wordBreak: 'break-word' as const,
  },
  activeTabStyle: {
    background: "#00A7A2",
    color: "#fff",
    borderRadius: 20,
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 700,
    marginRight: 10,
    textTransform: "capitalize" as "capitalize",
    padding: "7px 25px",
    height: "40px",
    '&:hover': {
      background: "#00A7A2",
    }
  },
  backBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  continueBtnSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    '&:hover': {
      backgroundColor: "#00A7A2",
    }
  },
  detailsContainer: {
    marginTop: 20
  },
  btnContainerStyle: {
    margin: "10 20",
    display: "flex",
    columnGap: 20
  },
  showmoreContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
  },
  projectfilterbutton: {
    marginLeft: 20,
    background: "#00A7A2",
    color: "#fff",
    padding: "15px 30px",
    borderRadius: 32,
    border: "none",
    fontSize: 16,
    fontWeight: 700,
    minWidth: 136,
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  projectchipsContainer: {
    display: 'flex',
    // flexWrap: 'wrap' as 'wrap',
    gap: 4,
    margin: 20,
  }
});
export const EditExistingDraftPageStyles = withStyles(webStyle)(
  EditExistingDraftPage
);
// Customizable Area End
