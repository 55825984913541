
import React ,{ChangeEvent, useState} from 'react'
import { freelancerIndiviualValidationSchema } from '../../../blocks/social-media-account-registration/src/validationSchema';
import { Formik, Field, Form, FieldArray } from "formik";
import Select from 'react-select'
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../blocks/social-media-account-registration//assets/css/Accountinfo.css";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {AddOutlined} from '@material-ui/icons'
import {ICategoryDataModel, IEducationalQualification, IFieldOfStudy, IUniversityCollege } from '../../../blocks/social-media-account-registration/src/types';
import FormSaver from './FormSaver.web';
import LocationContainer from './LocationContainer';
import { createTheme} from "@material-ui/core/styles";
import CustomSelect from '../utill/CustomSelect.web';
import {MenuItem,IconButton,Box, FormControl, Grid} from '@material-ui/core'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          fontSize: '14px', 
          fontWeight:700,
          color:"#8083A3"
        },
      },
    },
  },
});

interface Props{
    initialValues?:any;
    submitHandler:any;
    cancelHandler:any;
    uploaded_documents:any;
    handleDocumentChange:any;
    submitLoading:boolean;
    countryList:string[];
    countryLoading:boolean;
    cityList:string[];
    cityLoading:boolean;
    getCityList:any;
    skills:ICategoryDataModel[];
    categories:ICategoryDataModel[];
    getEducationLoading:boolean;
    educationList:IEducationalQualification[];
    fieldOfStudyList:IFieldOfStudy[];
    universityList:IUniversityCollege[];
    stateList:string[];
    getStateLoading:boolean;
    getStateList:any;
    freelancingExperienceList:string[];
    handleOpenUniversityModal:(open:boolean)=>void;
    newlyCreatedUniversity?: string;
}
const init={
    skill_ids: [],
    hobby_ids: [],
    uploaded_documents: null,
    certification: "",
    experience_in_years: "",
    education: "",
    college: "",
    field: "",
    jobTitle: "",
    employerName: "",
    country: "",
    city: "",
    jobDescription: "",
    currently_working_here: false,
    educational_qualifications: [
      { education: "", college: "", field: "" }
    ],
    career_experiences: [
      {
        jobTitle: "",
        employerName: "",
        country: "",
        city: "",
        jobDescription: ""
      }
    ]
  }



const getInitialValue=()=>{
  const individualString= localStorage.getItem("individualForm")
  let initialValue=init;
  try{
    if(individualString){
      const parsedValue=JSON.parse(individualString);
      initialValue=parsedValue;
    }
    return initialValue;
  }catch(err){
     return initialValue;
  }
}



const IndividualForm:React.FC<Props>=({
    initialValues=getInitialValue(),
    submitHandler,
    uploaded_documents,
    handleDocumentChange,
    cancelHandler,
    submitLoading,
    cityList,
    cityLoading,
    countryList,
    countryLoading,
    getCityList,
    categories,
    skills,
    educationList=[],
    universityList,
    fieldOfStudyList,
    getStateList,
    getStateLoading,
    stateList,
    handleOpenUniversityModal,
    newlyCreatedUniversity
})=>{
  const [updateInfo,setUpdateInfo]=useState(true);
  const individualFormikRef=React.useRef<any>(null);
  const [lastAddedUniversityIndex,setLastAddedUniversityIndex]=useState(0);
  const handleGoBack=()=>{
    setUpdateInfo(false)
    cancelHandler();
  }

  const updateEducationUniversity = () => {
    individualFormikRef.current.setFieldValue(`educational_qualifications.${lastAddedUniversityIndex}.college`, newlyCreatedUniversity);
  }

  React.useEffect(()=>{
    if(newlyCreatedUniversity){
      updateEducationUniversity();
    }
  },[newlyCreatedUniversity])

    return(
     <Formik
        innerRef={individualFormikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={freelancerIndiviualValidationSchema}
        validateOnBlur={true}
        validateOnChange={false}
        onSubmit={submitHandler}
      >
      {(props)=>(
        <FormSaver {...props} saveFieldName={"individualForm"} saveInfo={updateInfo} savePhoto={true}>
        {({ values, setFieldValue,errors, touched, handleBlur }:any) => {
          return(
            <Form translate={undefined}>
              <div className="step-1">
                <div className="form-group fakeclass form-linebreak multiselect">
                  <label htmlFor="skill">Skills*</label>
                  <Select test-id="skillId"
                    id="skill_ids"
                    className="multiselect-width"
                    isClearable={false}
                    name="skill_ids"
                    placeholder="Search and select your skills"
                    options={skills}
                    isMulti
                    value={values.skill_ids}
                    onChange={(selectedValues)=>{
                      setFieldValue("skill_ids",selectedValues?selectedValues:[])
                    }}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontWeight:700, 
                        color:"#8083A3",
                      }),
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "skill_ids" } });
                    }}
                  />
                  {errors?.skill_ids && touched?.skill_ids &&
                    (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                      {errors.skill_ids}
                    </p>
                    )}
                </div>
                <div className="form-group form-linebreak multiselect" data-test-id="fakeid">
                  <label htmlFor="hobby_ids">
                    Categories Interested*
                  </label>
                  <Select
                    className="multiselect-width"
                    test-id="categoriesId"
                    id="hobby_ids"
                    name="hobby_ids"
                    isClearable={false}
                    placeholder="Search and select your Categories"
                    options={categories}
                    isMulti
                    value={values.hobby_ids}
                    onChange={(selectedHobby)=>{
                      setFieldValue("hobby_ids",selectedHobby?selectedHobby:[])
                    }}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontWeight:700, 
                        color:"#8083A3",
                      }),
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "hobby_ids" } });
                    }}
                  />
                  {errors?.hobby_ids && touched?.hobby_ids && (
                    <p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                      {errors.hobby_ids}
                    </p>
                  )}
                </div>
                <div className="form-group form-linebreak" id="faketestid">
                  <label htmlFor="upload">Upload Resume</label>
                  {uploaded_documents ? uploaded_documents.name : <span>
                    Upload your resume (.DOC, .DOCX, .PDF. Max file
                    size 10MB)
                  </span>}
                  <div className="upload-btn">
                    <div className="upload-btn-wrapper">
                      <button
                        type="button"
                        className="button btn-orange"
                      >
                        Upload a file
                      </button>
                      <input test-id="uploadId"
                        type="file"
                        id="uploaded_documents"
                        name="uploaded_documents"
                        accept="image/*,.pdf, .doc, .docx"
                        placeholder="Upload your resume (.DOC, .DOCX, .PDF, .JPG, .JPEG, .PNG Max file size 10MB)"
                        onChange={(event: any) => {
                          setFieldValue(
                            "uploaded_documents",
                            event.currentTarget.files[0]
                          );
                          handleDocumentChange(event)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="certification" data-test-id="checkkerror">
                    Certifications
                  </label>
                  <Field test-id="certificate"
                    type="text"
                    id="certification"
                    name="certification"
                    placeholder="Enter your certifications"
                  />
                  <InputError
                    errors={errors}
                    name="certification"
                  />
                </div>
                  <div className="form-group">
                    <label htmlFor="freelanceexperience">Freelancing Experience*</label>
                    <input
                        type="number"
                        value={values?.experience_in_years}
                        onChange={(e:any)=>setFieldValue("experience_in_years",e.target.value)}
                        data-testid="experience"
                        name="experience_in_years"
                        id="experience_in_years"
                        tabIndex={3}
                        placeholder="Enter years of experience"
                        min="0"
                        onBlur={handleBlur}
                      />
                      {errors?.experience_in_years && touched.experience_in_years && <InputError
                      errors={errors}
                      name="experience_in_years"
                    />}
                  </div>
              </div>
              <div className="step-2">
                <div className="block-title">
                  <h6>Education Details</h6>
                </div>
                <div>
                <FieldArray name="educational_qualifications" test-id="educationDetails">
                  {({ push, remove }) => {
                    const educationErrors = errors?.educational_qualifications || [];
                    const educationTouched = touched?.educational_qualifications || [];
                    return (
                      <div>
                        {values.educational_qualifications.map((detail: unknown, index: number) => (
                          <div className="block-content" key={index}>
                            <div className="block-inner">
                              <p>Education Details {index + 1}</p>
                              {values.educational_qualifications.length > 1 && (
                                <span
                                  test-id="removeExperienceDetailsId"
                                  className="link"
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </span>
                              )}
                              {index === values.educational_qualifications.length - 1 && values.educational_qualifications.length < 5 && (
                                <span
                                  test-id="addEducationId"
                                  className="link"
                                  onClick={() =>
                                    push({
                                      education: "",
                                      college: "",
                                      field: "",
                                      current_study: false,
                                    })
                                  }
                                >
                                  + Add another education detail
                                </span>
                              )}
                            </div>

                            <div className="d-flex" test-id="educationFormId">
                              <Grid item sm={6} xs={12}>
                              <CustomSelect
                                value={values?.educational_qualifications[index]?.education}
                                onChange={(e: ChangeEvent<{value: string}>) =>
                                  setFieldValue(
                                    `educational_qualifications.${index}.education`,
                                    e.target.value
                                  )
                                }
                                label="Education*"
                                renderItem={(item, props) => (
                                  <MenuItem key={item?.id} value={item?.id} {...props}>
                                    {item?.attributes?.education}
                                  </MenuItem>
                                )}
                                name={`educational_qualifications.${index}.education`}
                                id={`educational_qualifications.${index}.education`}
                                data={educationList}
                                placeholder=" Select your degree/diploma"
                                labelStyle={{
                                  marginBottom: "14px",
                                }}
                                onBlur={handleBlur}
                              />
                                {educationErrors[index]?.education && educationTouched[index]?.education && (
                                  <p style={{color: "#f44336",marginBottom: "7px",fontSize: "11px",padding: "0px"}}>
                                    {educationErrors[index]?.education}
                                  </p>
                                )}
                              </Grid>
                              <Grid item sm={6} xs={12}>
                              <CustomSelect
                                label="University/College*"
                                value={values?.educational_qualifications[index]?.college}
                                onChange={(e: ChangeEvent<{value: string}>) =>{
                                  setFieldValue(
                                    `educational_qualifications.${index}.college`,
                                    e.target.value
                                  );
                                  setLastAddedUniversityIndex(index);
                                }
                                }
                                placeholder="Select your University/College"
                                name={`educational_qualifications[${index}].college`}
                                id={`educational_qualifications.${index}.college`}
                                data={universityList}
                                renderItem={(item, props) => (
                                  <MenuItem key={item?.id} value={item?.id} {...props}>
                                    {item?.attributes?.name}
                                  </MenuItem>
                                )}
                                labelStyle={{
                                  marginBottom: "14px",
                                }}
                                labelSuffix={
                                  <IconButton
                                    style={{ padding: 0, marginLeft: "12px" }}
                                    onClick={() => handleOpenUniversityModal(true)}
                                  >
                                    <AddOutlined />
                                  </IconButton>
                                }
                                onBlur={handleBlur}
                              />
                              {educationErrors[index]?.college && educationTouched[index]?.college && (
                                  <p style={{color: "#f44336",marginBottom: "7px",fontSize: "11px",padding: "0px"}}>
                                    {educationErrors[index]?.college}
                                  </p>
                                )}
                              </Grid>
                            </div>
                            <CustomSelect
                              label="Field Of Study*"
                              value={values?.educational_qualifications[index]?.field}
                              onChange={(e: ChangeEvent<{value: string}>) =>
                                setFieldValue(
                                  `educational_qualifications.${index}.field`,
                                  e.target.value
                                )
                              }
                              placeholder="Select your field of study"
                              name={`educational_qualifications.${index}.field`}
                              id={`educational_qualifications.${index}.field`}
                              data={fieldOfStudyList}
                              renderItem={(item, props) => (
                                <MenuItem key={item?.id} value={item?.id} {...props}>
                                  {item?.attributes?.field_of_study}
                                </MenuItem>
                              )}
                              labelStyle={{
                                marginBottom: "14px",
                              }}
                              onBlur={handleBlur}
                              />
                            {educationErrors[index]?.field && touched?.educational_qualifications?.map((item:any, idx:number)=>{
                              idx==index && item?.field &&
                                 <p style={{color: "#f44336",marginBottom: "7px",fontSize: "11px",padding: "0px"}}>
                                  {educationErrors[index]?.field}
                                </p>   
                              }) }
                            {/* Radio Button for "I currently study here" */}
                            <FormControl component="fieldset" style={{ display: "block" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      values.educational_qualifications[index]?.current_study || false
                                    }
                                    onChange={() => {
                                      const isCurrentlyChecked = values.educational_qualifications[index]?.current_study;

                                      values.educational_qualifications.forEach((_: unknown, idx: number) => {
                                        setFieldValue(
                                          `educational_qualifications.${idx}.current_study`,
                                          idx === index ? !isCurrentlyChecked : false
                                        );
                                      });
                                    }}
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<RadioButtonCheckedIcon />}
                                  />
                                }
                                label="I currently study here"
                              />
                            </FormControl>
                          </div>
                        ))}
                      </div>
                    );
                  }}
                </FieldArray>
                </div>
              </div>

              {
                      values?.experience_in_years > 0 &&
                      (

                        <div className="step-3">
                          <div className="block-title">
                            <h6>Experience Details</h6>
                          </div>
                          <div>
                          <FieldArray name="career_experiences">
                            {({ push, remove }) => {
                              const experienceErrors = errors?.career_experiences || [];
                              return (
                                <div>
                                  {values.career_experiences.map((detail: any, index: number) => (
                                    <div className="block-content" key={index}>
                                      <div className="block-inner">
                                        <p>Experience Details {index + 1}</p>
                                        {values.career_experiences.length > 1 && (
                                          <span
                                            test-id="removeExperienceDetailsId"
                                            className="link"
                                            onClick={() => remove(index)}
                                          >
                                            Remove
                                          </span>
                                        )}
                                      </div>
                                      <div className="d-flex solveclass" test-id="experienceFormId">
                                        <div className="form-group form-linebreak">
                                          <label
                                            className="labelclass"
                                            htmlFor={`career_experiences.${index}.jobTitle`}
                                          >
                                            Job Title*
                                          </label>
                                          <Field
                                            name={`career_experiences.${index}.jobTitle`}
                                            id={`career_experiences.${index}.jobTitle`}
                                            placeholder="Job Title"
                                            onBlur={handleBlur}
                                          />
                                          {experienceErrors[index]?.jobTitle && touched?.career_experiences?.map((item: any, idx: number) => {
                                            if(idx == index && item?.jobTitle)
                                              return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                                                {experienceErrors[index]?.jobTitle}
                                              </p>)
                                          })}
                                        </div>
                                        <div className="form-group checkingcl">
                                          <label htmlFor={`career_experiences.${index}.employerName`}>
                                            Employer Name*
                                          </label>
                                          <Field
                                            name={`career_experiences.${index}.employerName`}
                                            id={`career_experiences.${index}.employerName`}
                                            placeholder="Employer Name"
                                            onBlur={handleBlur}
                                          />
                                          {experienceErrors[index]?.employerName && touched?.career_experiences?.map((item: any, idx: number) => {
                                            if(idx == index && item?.employerName)
                                              return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                                                {experienceErrors[index]?.employerName}
                                              </p>)
                                              
                                          })}
                                        </div>
                                      </div>

                                      {/* LocationContainer */}
                                      <LocationContainer
                                        key={index}
                                        cityLoading={cityLoading}
                                        index={index}
                                        countryLoading={countryLoading}
                                        experienceErrors={experienceErrors}
                                        getCityList={getCityList}
                                        getStateList={getStateList}
                                        getStateLoading={getStateLoading}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        countryList={countryList}
                                        cityList={cityList}
                                        stateList={stateList}
                                        touched={touched.career_experiences || []}
                                        onBlur={handleBlur}
                                      />

                                      <div className="form-linebreak form-group">
                                        <label htmlFor={`career_experiences.${index}.jobDescription`}>
                                          Job Description*
                                        </label>
                                        <Field
                                          name={`career_experiences.${index}.jobDescription`}
                                          id={`career_experiences.${index}.jobDescription`}
                                          as="textarea"
                                          rows="5"
                                          placeholder="Enter job description"
                                        />
                                        {experienceErrors[index]?.jobDescription && touched?.career_experiences?.map((item: any, idx: number) => {
                                            if(idx == index && item?.jobDescription)
                                              return (<p style={{ color: "#f44336", marginBottom: "7px", fontSize: "11px", padding: "0px" }}>
                                                {experienceErrors[index]?.jobDescription}
                                              </p>)
                                              
                                          })}
                                      </div>

                                      {/* Radio Button for "I currently work here" */}
                                      <FormControl component="fieldset" style={{ display: "block" }}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                values.career_experiences[index]?.currently_working_here || false
                                              }
                                              onChange={() => {
                                                const isCurrentlyChecked = values.career_experiences[index]?.currently_working_here;

                                                values.career_experiences.forEach((_: unknown, idx: number) => {
                                                  setFieldValue(
                                                    `career_experiences.${idx}.currently_working_here`,
                                                    idx === index ? !isCurrentlyChecked : false
                                                  );
                                                });
                                              }}
                                              icon={<RadioButtonUncheckedIcon />}
                                              checkedIcon={<RadioButtonCheckedIcon />}
                                            />
                                          }
                                          label="I currently work here"
                                        />
                                      </FormControl>



                                      {/* Add Another Experience */}
                                      {index === values.career_experiences.length - 1 && values.educational_qualifications.length < 5 && (
                                        <span
                                          test-id="addExperienceDetailsId"
                                          className="link"
                                          onClick={() =>
                                            push({
                                              jobTitle: "",
                                              employerName: "",
                                              country: "",
                                              city: "",
                                              state: "",
                                              jobDescription: "",
                                              currently_working_here: false
                                            })
                                          }
                                        >
                                          + Add another experience detail
                                        </span>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              );
                            }}
                          </FieldArray>
                          </div>
                        </div>
                      )
                      }
              <Box className="button-block" display="flex" flexWrap="wrap">
                <button type="reset" className="button btn" onClick={handleGoBack}>
                  Cancel
                </button>
                <button disabled={submitLoading} type="submit" className="button btn-orange" test-id="submitButtonId">
                {
                  submitLoading?<CircularProgress size={16} />:"Complete"
                }
              </button>
              </Box>
            </Form>
            
          )
        }}
        </FormSaver>
      )}
      </Formik>
    )
}


export default IndividualForm;