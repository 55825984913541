import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo, searchicon } from "./assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import SponsorForm from "../../../components/src/profile-setup/SponsorForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";

export default class Sponser extends ProfilesetupController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { hobby_ids } = this.state
    return (
      // Customizable Area Start
      <div className="main-wrapper sponsor_freelancer">
        <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
        />
        <div className="site-wrapper sponsor_freelancer">
          <div className="setup-account-block sponsor_freelancer">
            <div className="inner-block sponsor_freelancer">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
            
          </div>

          <div className="choose-profile-block sponsor_freelancer">
            <div className="profile-heading sponsor_freelancer">
              <h6>Fill the details for profile(s) selected by you</h6>
            </div>
            <div className="profile-tab-wrapper">
              <div className="tab-panel-block">
                <div>
                  <div className="form-block">
                    <SponsorForm
                       data-testid="submitHandlerSponsor"
                       submitHandler={(values:any,{setErrors}:any)=>{
                          localStorage.setItem("sponsorForm",JSON.stringify(values))
                          this.checkSponsorProfile({values,setErrors})
                       }}
                       cancelHandler={this.handleGoBack}
                       submitLoading={this.state.submitLoading}
                       categories={this.state.categoriesList}
                       industryList={this.state.industryList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
