import React from "react";

// Customizable Area Start
import {
    messageicon,
    slider1,
    slider2,
    slider3,
    slider4,
    location,
    time,
    copy,
    dummyImage, ratingLogo
} from "./assets";
import "../assets/css/dashboard.css";
import Carousel from "react-multi-carousel";
import {
    Paper,
    Grid,
    Typography,
    Select,
    MenuItem,
    Box,
    CircularProgress,
    Tooltip,
    Button
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from '@material-ui/core/Chip';
import LinkIcon from "@material-ui/icons/Link";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom'
import {
    PieChart,
    Pie,
    Cell
} from "recharts";
import Avatar from "@material-ui/core/Avatar";
import SearchBar from "material-ui-search-bar";
import CustomBarChart from "../../../components/src/CustomBarChart.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config.js");
import { TernaryCheck } from "../../../components/src/Scale";
// Customizable Area End

import DashboardController, { Props } from "./DashboardController";
import Sponserheader from "./Sponserheader.web";
import Sponserfooter from "./Sponserfooter.web";
import ProjectDisplay from "../../../components/src/ProjectDisplay";
import ProjectLocalTime from "../../../components/src/ProjectLocalTime.web";
import moment from "moment";
import { info_circle } from "../../auctionbidding/src/assets";


export default class Sponserdashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        outerRadius,
        index
    }: {
        cx: number;
        cy: number;
        midAngle: number;
        outerRadius: number;
        index: number;
    }) => {
        const radius = outerRadius + 8;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        const value = this.state.piedata ? this.state.piedata[index]?.value : '10';

        if (this.state.piedata[index]?.value === 0) {
            return null;
        } else {
            return (
                <g>
                    <circle cx={x} cy={y} r={15} fill="#fff" stroke="none" style={{
                        boxShadow: "0 0 5px black",
                        filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.3))"
                    }} />
                    <text x={x} y={y} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                        {value}
                    </text>
                </g>
            );
        }
    };

    renderAmountsponsor(value: any) {
    const convertedCost=value.attributes?.currency_conversion;
    const freelancerConvertedCost=convertedCost?.freelancer;
    const sponsorConvertedCost=convertedCost?.sponsor;
   
    if (value.attributes.project.bid_status !== "non_bid_project") {
      return (
        <>
          {freelancerConvertedCost?.currency_symbol} {freelancerConvertedCost?.amount}  {value.attributes.project.budget_type === "hourly_price" && " /hr"} {"   "}
          {  sponsorConvertedCost?.currency != freelancerConvertedCost?.currency &&
            <>
              <span style={{ color: "#00A7A2" , fontWeight: 400, fontSize: "12px" }} >({sponsorConvertedCost?.currency_symbol}{sponsorConvertedCost?.amount} {value.attributes.project.budget_type === "hourly_price" && " /hr"})</span>
              <Tooltip 
              title={`Converted from (${freelancerConvertedCost?.currency}) into (${sponsorConvertedCost?.currency}) based on today's conversion rate`}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              >
                <img style={{ height: "13px", width: "13px",marginLeft: "5px"}} src={info_circle} alt="info" />
              </Tooltip></> }
         
        </>
      )}
      else {
          return <span>Non Quote</span>;
        }
    }
    biddata = (data: any) => {
        if (data.length > 0) {

            return (
                data.filter((correctData: {attributes: {project: {project_status: string}}})=> correctData.attributes.project.project_status === "active").map((value: any, index: any) => {
                    return (
                        <div data-test-id="go-to-project-detail" style={{cursor: "pointer"}} className="active-bids-block" id='block' key={index} onClick={()=>this.handleNavigationToProject(value.attributes.project_id)}>
                            <h6 >{value.attributes.project.project_name}</h6>
                            <div className="active-bids-block-wrapper d-flex"  >
                                <div className="active-bids-block-content d-flex resActiveBids" test-id="active-bids-content" style={{gap:"5px"}}>
                                    <img src={value.attributes.photo && value.attributes.photo[0]?.url ? value.attributes.photo[0].url : dummyImage} alt="person-image" style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }} />
                                    <div className="active-bids-content">
                                        <p>{value.attributes.profile_name}</p>
                                        {value?.attributes?.profile_skills?.length > 0 && <Typography style={{ fontSize: "10px", fontFamily: "inter" }}>
                                            {value.attributes.profile_skills[0]} {value.attributes.profile_skills[1]}
                                        </Typography>}
                                    </div>
                                </div>
                                <Box>
                            <Box
                              textAlign="center"
                              alignItems="center"
                              style={{
                                borderRadius: "18px",
                                background: "rgba(230,246,246,.698039)"
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "inter",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  color: "#00000",
                                  padding: "6.5px 10px 6.5px 10px"
                                }}
                              >
                                {this.renderAmountsponsor(value)}
                              </Typography>
                            </Box>
                            <Typography align="center">
                            {value.attributes.status == "accept" && <small className="bid" id="bid" style={{ color: '#16A700' }}>Quote Accepted</small>}                            </Typography>
                          </Box>
                            </div>
                        </div>
                    )
                })
            )
        }
        else {
            return (
                <div style={{ textAlign: "center", padding: "25px", fontSize: "1rem", fontWeight: 400, lineHeight: 1.5, letterSpacing: "0.00938em" }}>
                    No Active Quote</div>
            )
        }
    }

    renderSponsorCharts = () => {
        return <Paper style={useStyles.sponsorChartPaperStyle}>
            {this.state.sponsorProjectListError !== "" ? (
                <>
                    <Box style={useStyles.sponsorChartBoxStyle} className="resFlexDir">
                        <Typography
                            style={useStyles.chartHeaderStyle}
                            data-test-id="chartHeaderTitleForErrors"
                            align="left"
                        >
                            {configJSON.performanceChartLabel}
                        </Typography>
                    </Box>
                    <Typography align="center" style={useStyles.errorTextStyle}>
                        {this.state.sponsorProjectListError}
                    </Typography>
                </>
            ) : (
                <>
                    {this.state.sponsorProjectListData &&
                        this.state.sponsorProjectListData.length === 1 &&
                        this.state.sponsorProjectListData[0].project_name === configJSON.allProjectsLabel ? (
                        <>
                            <Box style={useStyles.sponsorChartBoxStyle} className="resFlexDir">
                                <Typography
                                    style={useStyles.chartHeaderStyle}
                                    data-test-id="chartHeaderTitle"
                                    align="left"
                                >
                                    {configJSON.performanceChartLabel}
                                </Typography>
                            </Box>
                            <Typography align="center" style={useStyles.noProjectsTxtStyle}>
                                {configJSON.NoChartLabel}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Box style={useStyles.sponsorChartBoxStyle} className="resFlexDir">
                                {!this.state.projectListLoading ? (
                                    <>
                                        <Typography
                                            style={useStyles.chartHeaderStyle}
                                            data-test-id="chartHeaderTitle"
                                            align="left"
                                        >
                                            {configJSON.performanceChartLabel}
                                        </Typography>

                                        <Select
                                            value={this.state.sponsorChartValues}
                                            onChange={this.handleSponsorChartChange}
                                            data-testid="sponsorChartList"
                                            name="sponsorChartValues"
                                            displayEmpty
                                            style={useStyles.selectStyle}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {this.state.sponsorProjectListData &&
                                                this.state.sponsorProjectListData.map((list) => {
                                                    return (
                                                        <MenuItem key={list.project_id} value={list.project_id}>
                                                            {this.truncateTextLength(list.project_name)}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </>
                                ) : (
                                    <Box style={useStyles.loadingStyle}>
                                        <CircularProgress size={24} style={useStyles.loaderColor} />
                                    </Box>
                                )}
                            </Box>

                            {!this.state.projectChartLoading ? (
                                <CustomBarChart data={this.state.sponsorProjectChartList} isAllProjects={this.state.sponsorChartValues === ""} />
                            ) : (
                                <Box style={{ height: "400px", ...useStyles.loadingStyle }}>
                                    <CircularProgress size={40} />
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Paper>;

    }

    generateUserProfileCountElement=(userprofilecountdata: number)=> {
        if (userprofilecountdata >= 100) {
            return (
                <h3>Completed</h3>
            );
        } else {
            return (
                <h3 style={{ marginLeft: "17px" }}>
                    {userprofilecountdata + "%"}
                </h3>
            );
        }
    }
    showMoreButtonRender = () => {
        if(this.state.getPaymentHistoryLoading) {
            return (
                <Box>
                  <CircularProgress size={20}/>
                </Box>
            )
        } else if(this.state.isPaymentNextPage ) {
            return this.state.projects.length > 0 && !this.state.searched && ( 
                <Button data-testid="loadMorePayments" onClick={this.handleHistoryLoadMore} style={useStyles.showMoreBtn}>{configJSON.showMoreBtn}</Button>
            )
        }
    }
    handlepaymentdata = (project:any) => {
      
        let imageSrc;
        if (project.attributes && project.attributes.lancer_avatar[0]?.url != null) {
            imageSrc = project.attributes.lancer_avatar[0].url;
        } else {
            imageSrc = dummyImage;
        }
        if(project.attributes?.payment_type==="refund"){
            return "-"
        }
        return (
            
            <div className="active-bids-block-content d-flex resActiveBids">
                {project.attributes && <>
                <img
                    src={imageSrc}
                    alt="person-image"
                    style={{ height: '40px', width: '40px', borderRadius: "50%", objectFit: 'cover', position: "relative" }}
                />
                <div className="active-bids-content">
                    <p className="payment-tablecell">
                        {project.attributes.freelancer_name}
                    </p>
                    <small title={project.attributes.designation?.map((value:any)=>value)} style={{ display: 'inline-block', marginRight: '10px' } }>
                        {project.attributes.designation && project.attributes.designation.length > 0 && project.attributes.designation.slice(0, 3).map((item: string, index: number, array: string[]) => {
                            return (
                                <span key={index}>
                                    {item}
                                    {index !== array.length - 1 && ' , '}
                                </span>
                            )
                        })}
                    </small>
                </div>
                </>}
            </div>
        );
    }

    renderPaymentReports=()=>{
        const projectsToDisplay = this.getPaymentReports();
        if(projectsToDisplay && projectsToDisplay.length > 0) return projectsToDisplay.map(
            (project: any) => {
                const convertedCost = project?.attributes?.currency_conversion;
                const freelancerConvertedCost = convertedCost?.freelancer;
                const sponsorConvertedCost = convertedCost?.sponsor;
                return (

                    <TableRow key={project.attributes.id} >
                        <TableCell className="payment-tablerowcell" align="left">
                            {project.attributes.id}
                        </TableCell>
                        <TableCell
                            className="payment-tablerowcell"
                            component="th"
                            scope="row"
                        >
                            {
                                project.attributes?.payment_type==="refund" ? (
                                        <div className="resDashboardTable">
                                            <span style={{fontWeight: "bold", }}>
                                                <span>{project.attributes?.milestone_name?.slice(0, 10)}</span>
                                                
                                            </span>
                                            <span  style={{fontWeight: "normal"}}>
                                            { project.attributes?.project_name && ` - ${project.attributes.project_name}`}
                                            </span>
                                            <br/>
                                            <span style={{fontWeight: "normal",}}> {project.attributes.title.slice(0, 20)}
                                            <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} 
                                                title={project.attributes.title}
                                                arrow
                                                placement="top"
                                                >
                                                <img src={info_circle} alt="info" width={12} height={12} />
                                            </Tooltip>
                                            </span>
                                        </div>
                                ):(
                                    <div className="resDashboardTable">
                                      <span style={{fontWeight: "bold"}}>
                                      {project.attributes.title?.slice(0, 20)}
                                           
                                        </span>
                                        <span  style={{fontWeight: "normal"}}>
                                           {" "} - {project.attributes.project_name}
                                        </span>
                                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} 
                                                title={
                                                    <>
                                                      {project.attributes.title}<br />
                                                      {project.attributes.project_name}
                                                    </>
                                                  }
                                                arrow
                                                placement="top"
                                                >
                                                <img src={info_circle} alt="info" width={12} height={12} />
                                            </Tooltip>
                                    </div>
                                )
                            }
                        </TableCell>
                        <TableCell className="payment-tablerowcell" align="left">
                            {this.handlepaymentdata(project)}
                        </TableCell>
                        <TableCell className="payment-tablerowcell" align="left" style={{whiteSpace:"nowrap"}}>
                            {moment(project.attributes.date).format("DD MMMM YYYY")}
                        </TableCell>
                        <TableCell className="payment-tablerowcell" align="left">
                            {this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost)}
                        </TableCell>
                        <TableCell className="payment-tablerowcell" align="left">
                        {project.attributes.currency_conversion.sponsor.currency_symbol} {project.attributes.tax}
                        </TableCell>
                        <TableCell className="payment-tablerowcell" align="left">
                            {project.attributes.currency_conversion.sponsor.currency_symbol} {project.attributes.total_amount}
                        </TableCell>
                    </TableRow>
                )
            }
        )
        return (
        <TableRow>
            <TableCell colSpan={6} align="center">
                No payment data available
            </TableCell>
        </TableRow>
        )
    }
    
    getProjectTotalCostElement = (freelancerCost: any, sponsorCost: any) => {
        return (
            <Box display="flex" alignItems="center">
                 {freelancerCost.currency_symbol} {freelancerCost.amount} {"   "}
                {sponsorCost.currency != freelancerCost.currency &&
                    <>
                        <span  id="listofall" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "15px" }} >({sponsorCost.currency_symbol}{sponsorCost.amount})</span>
                        <Tooltip id="abc" title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`} enterTouchDelay={0}
              leaveTouchDelay={3000}>
                            <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
                        </Tooltip> </>}
            </Box>
        )
    }
    
    // Customizable Area End
    render() {
        const { searched, loadingAll} = this.state;
        const { currentUser: loggedInUser } = this.state;
        const fullname = loggedInUser?.attributes?.full_name;
        const photo = loggedInUser?.attributes?.photo[0]?.url == null ? dummyImage : loggedInUser?.attributes?.photo[0].url;
        const city = loggedInUser?.attributes?.city
        const country = loggedInUser?.attributes?.country
        const sponsortimezone = loggedInUser?.attributes?.time_zone
        const activechip = this.state.chipNames.find((item) => item.isActive === true)
        const role = loggedInUser?.attributes?.role_name.toLowerCase().includes("both");
     

        return (
            // Customizable Area Start
            TernaryCheck(loadingAll, <Box position="relative" display="inline-flex" style={useStyles.loaderAll}>
                <CircularProgress size={80}/>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">Loading...</Typography>
                </Box>
            </Box>,
            <div className="main-wrapper">
                <Sponserheader navigation={this.props.navigation} id={""} route={"dashboard"} />
                {this.state.bannerImage?.map((data: any, index: any) => <>
                    {data.attributes.advertisement_for?.includes("sponsor_dashboard_page") &&

                        (<div key={index} className="bannerclass" style={{ backgroundImage: `url(${data.attributes.image[0]?.url})`, }}>
                            <div className="banner-containt">
                                <h1>Let's post a project (it's free)</h1>
                                <p>
                                    Tell us what you need. Provide as many details as possible, <br />
                                    but don't worry about getting it perfect
                                </p>
                            </div>
                            <Link to="/Projects/createNewProject"><button className="btn-green">Get Started Now <svg xmlns="http://www.w3.org/2000/svg"  height="1em" style={{marginLeft:"5px",fontWeight:800 , stroke:"white" , strokeWidth:"1.5px"}} viewBox="0 0 30 20"><path style={{fill:"white"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right"/></svg></button></Link>
                        </div>)
                    }
                </>)}

                <div >
                    <div className="talent-slider-block">
                        <h2>Create your own job here</h2>
                        <div className="talent-slider-wrapper">
                            {this.state?.talentdata && <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 4,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 20
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {this.state.talentdata.map((data: any, index: number) => {
                                    return (
                                        <div data-testid="category-item" style={{cursor:"pointer"}} onClick={this.getNavigation(data.attributes.name)} className="talent-block">
                                            <div className="talent-image">
                                                <img src={data.attributes.category_icon[0].url} alt="category_icon" />
                                            </div>
                                            <div className="talent-content">
                                                <h3 title={`sponsorDashboard`}>{data.attributes.name}</h3>
                                                <p>{data.attributes.project_count} Projects</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>}
                        </div>
                    </div>
                    <div style={useStyles.root as React.CSSProperties} className="container">
                        <Grid container spacing={3}>
                            <Grid item md={8} sm={12}>

                                {this.renderSponsorCharts()}
                                <div className="block-heading d-flex">
                                    <h2>My Projects</h2>
                                    <div className="input-container">
                                        <SearchBar
                                            data-id="search"
                                            value={searched}
                                            onChange={(e: string) => this.handlesearch(e)}
                                            onRequestSearch={() => this.handlesearch(this.state.search)}
                                            className="sponsor-header-searchbar"
                                            placeholder="Search Project, Skill, or Category"
                                            onCancelSearch={() => this.handlesearch("")}
                                        />
                                    </div>
                                </div>
                                
                                <ProjectDisplay
                                    data-testid="paginationTestID"
                                    loading={this.state.loading}
                                    projectList={this.state.projectdata}
                                    tabDataList={this.state.chipNames}
                                    loadMore={this.handlenavigation}
                                    onTabChange={this.handleChipClick}
                                    navigate={this.props.navigation?.navigate}
                                    type={`${activechip?.state}`}
                                    onEdit={this.getSingleProjectInfoData}
                                    isShowMore={true}
                                    onDraftDelete={this.deleteProject}
                                    onDraftReuse={this.getSingleProject}
                                    page="dashboard"
                                     />
                                
                            </Grid>
                            <Grid item md={4} sm={12}>
                                <Paper className="border-block bg-gray">
                                    <div className="usertime d-flex">
                                        <div>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={photo}
                                            />
                                            <p title={fullname}>{fullname?.length > 13 ? `${fullname.slice(0, 11)}...` : fullname}</p>
                                        </div>
                                        <div className="card" id="fake">
                                            <div className="percent" test-id="fake">
                                                <svg>
                                                    <circle cx={50} cy={50} r={40} />
                                                    <circle cx={50} cy={50} r={40} style={{ "--percent": role ? this.state?.bothUserProfilePercentage : this.state?.userprofilecount } as React.CSSProperties}></circle>
                                                </svg>
                                                <div className="number fakeclass">
                                                    {role ? this.generateUserProfileCountElement(this.state?.bothUserProfilePercentage): this.generateUserProfileCountElement(this.state?.userprofilecount)}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <Box display="flex" alignItems="center" pr={1}>
                                        <img style={useStyles.location_img} src={location} />
                                        <Typography style={useStyles.subtext}>
                                        {city}, {country}
                                        </Typography><span style={{ color: 'gray', margin: '0 5px', fontWeight: "normal" }}> • </span>
                                        <img style={useStyles.location_img} src={time} />
                                        <ProjectLocalTime timezoneData={sponsortimezone} />
                                    </Box>
                                    
                                    <button className="btn-green" data-test-id="continue" onClick={() => { this.props.navigation.navigate("SponsorCustomisableuserprofiles") }}>{this.state.userprofilecount >= 100 ? "Go To Your Profile" : "Complete Your Profile"}</button>
                                    
                                </Paper>
                                <Paper className="border-block">
                                    <div>
                                        <div>
                                            <h2>
                                                <b>Project Summary</b>
                                            </h2>
                                        </div>
                                        <div className="pie-block" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            {(this.state.piedata && this.state.piedata.some((entry: { value: number; }) => entry.value > 0)) ?
                                                <div>
                                                    <PieChart width={270} height={400} style={{ margin: 'auto' }}>
                                                    <Pie
                                                        data={this.state.piedata}
                                                        cx={120}
                                                        cy={200}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                        paddingAngle={0}
                                                        label={this.renderCustomizedLabel}
                                                        >
                                                            {this.state.piedata.map(
                                                                (entry: any, index: number) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={entry.value ? this.state.color[index % this.state.color.length] : undefined}
                                                                    />
                                                                )
                                                            )}
                                                        </Pie>
                                                        <text x={125} y={200} fontWeight="900" textAnchor="middle" dominantBaseline="middle" fontSize="20" fill="#000000">
                                                            {this.state.piedata.reduce((total: any, entry: any) => total + entry.value, 0)}
                                                        </text>
                                                        <text x={125} y={220} textAnchor="middle" dominantBaseline="middle" fontSize="10" fontWeight="bold" fill="gray">
                                                            Total Projects
                                                        </text>
                                                    </PieChart>
                                                </div> : <div style={{ width: "100%", textAlign: "center", padding: "40px 0", }}>No Projects</div>
                                            }
                                        </div>
                                        <ul style={{
                                            display: "flex", alignItems: "center", flexWrap: 'wrap',
                                            gap: "20px 30px"
                                        }}>
                                            {this.state.piedata?.map((entry: any, index: number) => (
                                                <li
                                                    key={`item-${index}`}
                                                    style={{
                                                        color: "#000000",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: "10px"
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            display: "inline-block",
                                                            width: "10px",
                                                            height: "10px",
                                                            borderRadius: "50%",
                                                            backgroundColor: this.state.color[
                                                                index % this.state.color.length
                                                            ],
                                                            marginRight: "5px"
                                                        }}
                                                    />
                                                    <span>{entry.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Paper>
                                <Paper className="border-block p-0">
                                    <div className="active-bids-heading d-flex">
                                        <h2>{configJSON.activebidtext}</h2>

                                    </div>
                                    {this.biddata(this.state.biddata)}

                                    {this.state.biddata.length > 0 && <div className="view-all">
                                        <span className="link" onClick={() => { this.handlenavigation2() }}>View All
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "#00A7A2" }} height="1em" viewBox="0 0 320 512">
                                                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                            </svg>
                                        </span>
                                    </div>}
                                </Paper>
                                <Paper className="p-0 border-block">
                                    <div className="rating-review-wrapper">
                                        <div className="rating-heading d-flex">
                                            <h2>Rating &amp; Reviews</h2>
                                        </div>
                                        {this.state.ratingData.length > 0 ?
                                            <>
                                                {this.state.ratingData.slice(0, 3).map((project: any) => {
                                                    const lancer = project.attributes.reviewer_profile
                                                    return (
                                                        <div className="rating-block">
                                                            <h6>{project.attributes.project_details.project_name}</h6>
                                                            <div className="review-block d-flex">
                                                                <div className="d-flex review-block-content">
                                                                    <img src={lancer.photo ? lancer.photo.url : ratingLogo}
                                                                        style={{ height: '40px', width: '40px', borderRadius: "50%", objectFit: 'cover', position: "relative" }}></img>
                                                                    <div className="review-content" style={{ display: "flex", alignItems: "center" }}>
                                                                        <p style={{ margin: "0" }}>{lancer.reviewer_name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="rating-star">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height="1em"
                                                                        viewBox="0 0 576 512"
                                                                    >
                                                                        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                                                    </svg>
                                                                    <span>{project.attributes.score}/5</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="view-all" id="all" onClick={() => this.props.navigation.navigate('Reviews')} data-testId="viewAll" >
                                                    <span className="link">View All
                                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: "#00A7A2" }} height="1em" viewBox="0 0 320 512">
                                                            <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                        </svg></span>
                                                </div>
                                            </>
                                            : <div className="rating-block"><Typography style={{ fontFamily: "Inter", textAlign: "center" }}>No Review Found</Typography></div>}

                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Box style={useStyles.paymentBox}>

                        <Paper className="border-block p-0">
                            <div className="block-heading table-heading d-flex">
                                <h2>Payments</h2>
                                <SearchBar
                                    value={this.state.searched}
                                    style={{ color: "#8083A3" }}
                                    className="common-search"
                                    placeholder="Search Payments"
                                    data-test-id="PaymentTableData"
                                    onChange={(searchVal) =>
                                        this.requestSearch(searchVal)
                                    }
                                    onCancelSearch={() =>
                                        this.cancelSearch()
                                    }
                                />
                            </div>
                            <TableContainer>
                                <Table
                                    aria-label="simple table"
                                    className="table-block"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="payment-tablecell" align="left">
                                                Transaction ID
                                            </TableCell>
                                            <TableCell className="payment-tablecell">Project</TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                                Freelancer
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                                Date
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                                Amount Paid
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                                Tax
                                            </TableCell>
                                            <TableCell className="payment-tablecell" align="left">
                                                Total Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.renderPaymentReports()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        
                        <Box style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center",gap:"10px",margin:"5px 0px"}}>
                        {this.showMoreButtonRender()}
                        </Box>
                        </Box>

                        
                    </div>
                    <Sponserfooter navigation={undefined}  />
                </div>
            </div>
            )
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = {
    root: {
        backgroundColor: "transparent",
        zIndex: 1
    },
    circularContainer: {
        margin: "auto",
        marginTop: "20px",
        width: "fit-content"
    },

    progressBar: {
        color: "green",
        width: "80px",
        height: "80px",
        transform: 'rotate(-90deg)'
    },
    text: {
        // color: 'green',
        // fontWeight: 'bold',
        position: "absolute",
        textAlign: "center",
        fontWeight: "700",
        fontSize: "14px",
        color: "#8083A3",
        // padding: '10px 0 0',
        top: "45px",
        right: "50px"
    },
    headersearchBar: {
        flexGrow: 1,
        borderRadius: '20px 0 0 20px',
        '& .MuiInputBase-root': {
            borderRadius: '20px 0 0 20px',
            '& .MuiSvgIcon-root': {
                display: 'none',
            },
        },
    },
    headerselectBar: {
        minWidth: '150px',
        borderRadius: '0 20px 20px 0',
        // backgroundColor: theme.palette.background.default,
    },
    chip2: {
        padding: '10px 30px',
        fontWeight: '700',
        borderRadius: '30px',
        minHeight: '40px !important',
        background: '#F6F7F7 !important',
    },
    circle: {
        '--percent': 50
    },
    sponsorChartPaperStyle: {
        border: "1px solid #E4E6E8",
        borderRadius: "14px",
        marginBottom: "30px",
        boxShadow: "none",

    },
    chartHeaderStyle: {
        color: "rgb(34, 34, 34)",
        fontFamily: "inter",
        fontSize: "28px",
        fontWeight: 700,
    },
    loadingStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    sponsorChartBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px'
    },
    selectStyle: {
        width: '110px',
        marginRight: '10px'
    },
    loaderColor: { color: 'grey' },
    noProjectsTxtStyle: {
        padding: '30px',
        fontSize: '20px',
        fontWeight: 700,
        color: '#222'
    },
    errorTextStyle: {
        padding: '30px',
        fontSize: '20px',
        fontWeight: 700,
        color: '#222'
    },
    location_img: {
        marginRight: "6px"
      },
      subtext: {
        fontFamily: "inter",
        fontSize: "14px",
        color:"#8083a3"
      },
    showMoreBtn:{
        backgroundColor: '#f2f2f2',
        fontSize:'12px',
        fontWeight: 600,
        width: '150px',
        height: '40px',
        color: '#000',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        borderRadius: '20px',
        textTransform:'none' as const,
        '&:hover': {
            backgroundColor: '#f2f2f2',
        },
    },
    showMoreBtnBox: {
        display:'flex',
        flexDirection:"column",
        alignItems: 'center',
        justifyContent:'center',
        margin:'5px 0px'
    },
    paymentBox: {
        minHeight: "100px",
        maxHeight:"1000px",
        overflow:"auto"
    },
    loaderAll: {
        marginTop:"15%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        gap:"10px"
    }
};
// Customizable Area End
