import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import {toast} from 'react-toastify'
import { toTitleCase } from "../../../components/src/Scale";

interface IPaymentDetails{
        project_id: number;
        freelancer_name: string;
        milestone_id?: number;
        bid_id?:number;
        title?:string;
        description?:string;
        type:string;
        invoice_url:{
          id:string;
          name:string;
          url:string;
        }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  plotNo: string,
  addressLine1: string,
  addressLine2: string,
  state: string,
  city: string,
  pincode: string,
  loader:boolean;
  paymentInfo:IPaymentDetails | null;
  bidId:string;
  approveMilestone:string;
  currentUser:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AuctionBiddingPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPaymentDetailsApiCallId:string="";
  approvedMilestoneApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ];
    
    // let auction_id_from_url = window?.location?.pathname.split("/").pop()

    this.state = {
        
      // Customizable Area Start
        plotNo: '',
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        pincode: '',
        loader:false,
        paymentInfo:null,
        bidId:"",
        approveMilestone:"",
        currentUser:null,
       // Customizable Area End
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (_message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = _message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.handleFetchCurrentUser(user);
    }
    if (_message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if(apiRequestCallId===this.getPaymentDetailsApiCallId){
            this.handlePaymentDetailsData(responseJson);
        }else if(apiRequestCallId===this.approvedMilestoneApiCallId){
          this.handleApproveMilestoneApiResponse(responseJson);
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleFetchCurrentUser=(user:any)=>{
    if(user?.id){
      this.setState({ currentUser: user});
    }
  }
  isSponsor = () => { return this.state?.currentUser?.currentState?.toLowerCase()?.includes("sponsor")}
 
  handleTextInput = (name: string, value: string) => {
    
    if(name === 'plot_no') {
      this.setState({ plotNo: value })
    } else if(name === 'address_1') { 
      this.setState({ addressLine1: value})
    } else if(name === 'address_2') {
      this.setState({ addressLine2 : value })
    } else if(name === 'state') {
      this.setState({ state: value })
    } else if(name === 'city') {
      this.setState({ city: value })
    } else if(name === 'pincode') {
      this.setState({ pincode: value})
    }
  }

  handlePayment = () => {
    const { plotNo, addressLine1, addressLine2, state, city, pincode } = this.state;
    const shipping_adress = {
      plot_no: plotNo,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      state: state,
      city: city,
      pincode: pincode
    }

    alert("Payment sucessfully done")
  }
  handleApproveMilestoneApiResponse=(responseJson:any)=>{
    this.setState({loader:false});
  }



  handlePaymentDetailsData=async(responseJson:any)=>{
      const approveMilestone=this.state.approveMilestone;
      if(responseJson?.data){
        const data=responseJson.data as IPaymentDetails;
          this.setState({paymentInfo:data,loader:false})
      }else{
          this.setState({loader:false})
          toast.success("Something wen't wrong !!")
      }
  }
  async componentDidMount() {
    this.sendLoginUserMessage();
      this.getPaymentDetails();
      this.getStorageInfo();
  }
  sendLoginUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }
  getPaymentSuccessTitle=()=>{
     const {approveMilestone,paymentInfo}=this.state;
     if(approveMilestone==="yes"){
      return "Milestone cost successfully paid."
     }
     if(paymentInfo?.title){
      return toTitleCase(paymentInfo?.title)
     }
     return `${paymentInfo?.freelancer_name} successfully hired for your project.`
  }
  getPaymentSuccessDescription=()=>{
    const {approveMilestone,paymentInfo}=this.state;
    if(approveMilestone==="yes"){
      return ""
     }
     if(paymentInfo?.description){
      return paymentInfo.description
     }
     return `<span class="">"${paymentInfo?.freelancer_name}"</span> will get notified. Also, sponsor and freelancer can start working on this project together.`
  }
  isShowActionButtons=()=>{
    const {paymentInfo}=this.state;
    if(!paymentInfo?.title && !paymentInfo?.description){
      return true;
    }else if(paymentInfo?.type==="payment_for_arbitration_processes"){
      return true;
    }

    return false;
  }
  isPaymentForCertificate=()=>{
    return this.state.paymentInfo?.type==="payment_for_certificate";
 }
 navigateToViewCertificate=()=>{
   this.props?.navigation?.navigate("FreelancerViewCertificate",{
     projectid:this.state?.paymentInfo?.project_id
   })
 }

  getStorageInfo=async()=>{
    const bidId=await getStorageData("bidId");
    const approveMilestone=await getStorageData("approveMilestone");
    this.setState({
      bidId:bidId,
      approveMilestone:approveMilestone
    })

    await removeStorageData("bidId");
    await removeStorageData("approveMilestone");
  }
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token
    };
    return header;
  }
  
  
  onTryAgain=async()=>{
    const approveMilestone=this.state.approveMilestone;
    const id=this.state.paymentInfo?.project_id;
    const bidId=this.state.bidId;
    if(approveMilestone==="yes" && id){
      this.props.navigation?.navigate("Projects", { type: "scheduling", status: "projectinprogress", id})
    }else if(approveMilestone!=="yes" && bidId){
        this.props.navigation?.navigate("PayAndHire",{
          navigationBarTitleText:bidId
        })
    }else{
      this.props.navigation?.navigate("Projects", { type: "activeprojects"})
    }


  }

  navigateToProjectPage=()=>{
    const id=this.state.paymentInfo?.project_id;
    if(id && this.state.paymentInfo?.type==="payment_for_arbitration_processes"){
      this.props.navigation?.navigate("Projects", { type: "scheduling", status: "disputeproject", id})
    }else if(id){
      this.props.navigation?.navigate("Projects", { type: "scheduling", status: "projectinprogress", id})
    }else{
      this.props.navigation?.navigate("Projects", { type: "projectinprogress"})
    }    
  }

  approveMilestone=async(milestoneId:string)=>{
     const header=await this.getHeader();

    let formdata = new FormData();
    formdata.append("milestone_id", milestoneId);
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.approveMilestoneApiEndPoint}`,
      body: formdata,
      method: "PUT",
    });
    this.approvedMilestoneApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getPaymentDetails=async()=>{
    const paymentId=this.props.navigation.getParam("paymentId");
    this.setState({
      loader: true,
    });
    const header=await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.paymentDetailsApiEndPoint}/${paymentId}`,
      body: null,
      method: "GET",
    });
    this.getPaymentDetailsApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  handleDownloadFile = async () => {
    const {paymentInfo}=this.state;
    if(!paymentInfo || !paymentInfo?.invoice_url?.url){
      toast.error("Invoice not found!")
      return;
    }
    const response = await fetch(paymentInfo.invoice_url.url);
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = paymentInfo?.invoice_url.name;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    window.open(paymentInfo.invoice_url?.url);
  };

  // Customizable Area End
}
