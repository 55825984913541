import React from 'react';
import {Dialog,DialogTitle,DialogContent,Box, IconButton,Divider,Button,Typography,
  Avatar,withStyles,TextField} from "@material-ui/core"
import { CloseOutlined } from '@material-ui/icons';
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';


export const StyledTextField = withStyles({
  root: {
    maxWidth: '100%',
    fontFamily: 'Inter',
    color:'#000',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    fontSize: '18',
    fontWeight:600,
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& input::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14',
      fontWeight: 400,
      color: '#8083a3'
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& textarea::placeholder": {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      color: '#8083a3',
    },
    "& label": {
      color: 'rgb(128, 131, 163)',
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#8083A"
  }
  }
})(TextField);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      freelancer_details_container:{
        background:"#f5f6f5",
        display:"flex",
        alignItems:"center",
        gap:"10px",
        padding:"20px",
        borderRadius:"15px",
        marginTop:"10px",
        marginBottom:"20px"
      },
      freelancer_image:{
        height:"50px",
        width:"50px"
      },
      freelancer_info:{
        display:"flex",
        flexDirection:"column",
      },
      page_heading:{
        fontWeight:"bolder",
        fontSize:"14px",
        marginLeft:"5px"
    },
    subtitle:{
        color:"#96939c",
        fontWeight:"bold",
        fontSize:"12px",
        display:"flex"
    },
    action_container:{
      display:"flex",
      alignItems:"center",
      justifyContent:"flex-end",
      gap:"20px",
      marginTop:"20px",
      marginBottom:"20px"
    },
    send_button:{
      background:"#00A7A2",
      borderRadius:"20px",
      padding:"10px 20px",
      color:"white",
      textTransform:"capitalize",
      "&:hover":{
          background:"#00A7A2"
        }
    },
    cancel_button:{
      background:"#f2f2f2",
      borderRadius:"20px",
      padding:"10px 20px",
      textTransform:"capitalize",
    },
  })
);
interface Props{
    open:boolean;
    setOpen:(open:boolean)=>void;
    loading:boolean;
    onSend:(message:string)=>void;
    freelancerInfo:{
      name:string;
      image:string;
      skills:string;
      location:string;
      id:string;
    }|null;
    clearInput:boolean;
}

const SendInvitationModal:React.FC<Props> = ({open,setOpen,loading,onSend,freelancerInfo,clearInput}) => {
  const classes=useStyles();
  const [message,setMessage]=React.useState("");
  const [errors,setErrors]=React.useState("");

  React.useEffect(()=>{
    if(clearInput){
      setMessage("")
    }
  },[clearInput]);
  const handleSend=()=>{
    if(!message.trim()){
      setErrors("Please enter invitation message!")
      return;
    }
    setErrors("");
    onSend(message);
  }
    return ( 
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { borderRadius: 16 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
       <DialogTitle
        id="max-width-dialog-title"
        style={{ paddingBottom: 0 }}
      >
        <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <strong>Invite To Project</strong>
          <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined/>
            </IconButton>
        </Box>
      </DialogTitle>
      <Divider/>
     
      <DialogContent>
      <Box className={classes.freelancer_details_container}>
         <Avatar className={classes.freelancer_image} src={freelancerInfo?.image}/>
            <Box className={classes.freelancer_info}>
               <Typography component={"h1"} variant="h6" className={classes.page_heading}>
                 {freelancerInfo?.name}
                </Typography>
                <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                  {freelancerInfo?.skills} <LocationOnOutlinedIcon style={{fontSize:"20px",height:"15px"}} className={classes.subtitle}/> {freelancerInfo?.location}
                </Typography>
            </Box>
          </Box>   
          <StyledTextField
              label="Message*"
              placeholder={"Message"}
              fullWidth
              inputProps={{
                style: { height: "120px",
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 600,
                color: '#000',    
              },
              }}
              multiline={true}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={message}
              onChange={(e)=>setMessage(e.target.value)}
              error={Boolean(errors)}
              helperText={errors}
            />   
            <Box className={classes.action_container}>
                <Button onClick={()=>setOpen(false)} className={classes.cancel_button}>
                    Cancel
                </Button>
                 <Button disabled={loading} onClick={handleSend} className={classes.send_button}>
                    {loading?<CircularProgress size={18}/> :"Send"}
                </Button>
            </Box>                                   
      </DialogContent>
      <br/>
      <br/>
    </Dialog>
     );
}
 
export default SendInvitationModal;