import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../../dashboard/src/types";
import { getStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  name: string;
  classes?: any;
  getSingleProject?:(id:string)=>void;
  // Customizable Area End
}

export interface SponsorBidDataInterface {
  "data": [
    {
      "id": string,
      "type": string,
      "attributes": {
        "profile_id": number,
        "project_id": number,
        "amount": number,
        "start_date": null,
        "end_date": null,
        "proposal": null,
        "status": string,
        "negotiable": null,
        "bidder_name": string,
        "selected": true,
        "sent_by": string,
        "intial_amount": null,
        "profile_name": string,
        "photo": [{
          "url": string
        }],
        "project_name": string,
        "location": string,
        "skills": [
          {
            "id": number,
            "name": string,
            "created_at": string,
            "updated_at": string,
            "expertise_in_yrs": null
          }
        ],
        "total_ammount_of_time": string,
        "total_experince": null,
        "project_milestone": null,
        "sponsor": {
          "id": number,
          "country": string,
          "address": string,
          "postal_code": string,
          "account_id": number,
          "created_at": string,
          "updated_at": string,
          "city": string,
          "first_name": string,
          "last_name": string,
          "gender": string,
          "date_of_birth": string,
          "mobile_number": string,
          "time_zone": string,
          "state": string,
          "certification": null,
          "experience_in_years": null,
          "address_line_2": null,
          "sub_role": null,
          "field_of_study_id": number,
          "rating": null,
          "work_load": null,
          "hobbies": [
            string
          ],
          "educational_qualification": any,
          "user_status": null,
          "completeness_percentage": number,
          "role_id": number,
          "full_name": string,
          "blocked_profile": boolean
        }
      }
    }
  ]
}

interface S {
  // Customizable Area Start
  renderDetailsView: string;
  activeSubRoute: string,
  chipNames: { name: string, state: any, isActive: boolean }[];
  url: string,
  authtoken: any,
  profileid: any
  projectdata: IProjectData[],
  modalflag: boolean,
  personName: any,
  loading: boolean,
  // Sponsor Auction Bidding
  selectedCategoryButton: number,
  addMilestoneButton: boolean;
  sponsorBidData: Array<SponsorBidDataInterface>;
  childData: string;
  count: any;
  isFavouriteProfileID: number;
  bidScope: any[];
  favData: any;
  selectCatagory_name: string;
  totalProjectsCount:number;
  currentPage:number;
  searchProjectText:string;
  queryProjectText:string;
  singleProjectInfo:IProjectData | null;
  userId:string;
  getBidLoading:boolean;
  isOpen:boolean;
  projectId:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class Sponsorlistingcontroller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInput: any;
  roleid: any;
  ProjectListID: string = "";
  sponsorBidsListCallID: any;
  getSingleProjectApiCallId:string="";
  favouriteBidCallID: any;
  BidScpoeCallID: any;
  getprofileid: string = "";
  deleteProjectId:string="";
  redirectToEditPage:boolean=false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LoginUserMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      sponsorBidData: [],
      renderDetailsView: "postProject",
      modalflag: false,
      activeSubRoute: "",
      profileid: "",
      authtoken: window.localStorage.getItem('loginSuccessToken'),
      projectdata: [],
      personName: [],
      chipNames: [
        { name: 'All', state: 0, isActive: true },
        { name: 'Open Quote Projects', state: 1, isActive: false },
        { name: 'Non-Quote Projects', state: 2, isActive: false },
      ],
     
      url: 'https://example.com',
      loading: false,

      // Sponsor Auction Bidding
      selectedCategoryButton: 0,
      childData: "",
      addMilestoneButton: false,
      count: 4,
      isFavouriteProfileID: NaN,
      favData: {},
      bidScope: [],
      selectCatagory_name: "",
      totalProjectsCount:0,
      currentPage:1,
      searchProjectText:"",
      queryProjectText:"",
      singleProjectInfo:null,
      userId:"",
      getBidLoading:false,
      isOpen:false,
      projectId:-1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  //Component Didmount
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.sendGetLoginUserMessage();
    this.getAllBids();
    this.getSingleProjectInfoData();
    // Customizable Area End
  }
  sendGetLoginUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  // async recive
  async receive(from: string, message: Message) {
    // Customizable Area Start  
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ profileid: user?.id,userId: user?.attributes?.account_detail?.data?.id},this.prepareFinalProjectFetchQuery);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response && !response.errors) {
      this.handleResponseWithoutErrors(apiRequestCallId, response);
    }
    // Customizable Area End
  }
  handleResponseWithoutErrors(apiRequestCallId: string, response: any) {
    switch (apiRequestCallId) {
      case this.getSingleProjectApiCallId:{
        if(response?.data?.id){
          this.setState({
            singleProjectInfo:response?.data
          })
          if(this.redirectToEditPage){
            this.navigateToEditProjectPage(response);
          }
        }
        break;
      }
      case this.deleteProjectId:
        this.setState({currentPage:1, isOpen:false},this.prepareFinalProjectFetchQuery); toast.success("Draft deleted successfully!")
        break;
      case this.ProjectListID:
        this.handleGetAllProjectListPayload(response)
        break;
      case this.favouriteBidCallID:
        this.setState({ favData: response })
        this.bidding_scope()
        break;
      case this.sponsorBidsListCallID:
    
        this.setState({
            sponsorBidData: response && response.sponsor_bids?.data,
            getBidLoading: false,
        });
        break;
      case this.BidScpoeCallID:
        this.setState({ bidScope: response?.bids?.data || [],getBidLoading:false })
        break;
        
    }
  }
  navigateToEditProjectPage=(response:any)=>{
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Projecttemplates"
    );
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {type:"edt_project",data:response});
    navigationMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(navigationMessage);
  }
  handleGetAllProjectListPayload = (responseJson: any) => {
    if (responseJson && responseJson?.projects?.data) {
      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.projects?.data;
        } else {
          newProjectList = [...prev.projectdata, ...responseJson.projects.data];
        }
        return {
          projectdata: newProjectList,
          loading: false,
          totalProjectsCount: responseJson?.pagination?.total_data || 0,
        };
      });
    } else {
      this.setState(prev=>(
        {
          loading: false,
          projectdata:prev.currentPage===1?[]:prev.projectdata,
          totalProjectsCount:prev.currentPage===1?0:prev.totalProjectsCount,
        }
      ));
    }
  };

  //project manage methods
  searchProjectHandler = (searchText: string) => {
    this.setState({ currentPage: 1, searchProjectText: searchText,projectdata:[] }, () => {
      this.prepareFinalProjectFetchQuery();
    });
  };
  filterProjectHandler = (query: string, selectedOptions: any) => {
    this.setState({ currentPage: 1, queryProjectText: query,projectdata:[] }, () => {
      this.prepareFinalProjectFetchQuery();
    });
  };
  cancelFilterProjectHandler = () => {
    this.setState({ currentPage: 1, queryProjectText: "",searchProjectText:"",projectdata:[]}, () => {
      this.prepareFinalProjectFetchQuery();
    });
  };
 
  getTabProjectQueryValue = () => {
    const { chipNames } = this.state;
    const activeTabItem=chipNames.find((item)=>item?.isActive);
    const activeTab=activeTabItem?activeTabItem?.state:0;
    if (activeTab === 0) {
      return "";
    } else if (activeTab === 1) {
      return `bid_status=open_bid_project`;
    } else if (activeTab === 2) {
      return `bid_status=non_bid_project`;
    }
  };

  onFilterHandler = (selectedQuery:any) => {
    const {
      selectedCategories,
      selectedSpecialitie,
      selectedLanguage,
      selectedSkill,
      selectedExperience,
      selectedLocation,
    } = selectedQuery;
    let projectQueryParamsBid = [];

    if (selectedCategories.length > 0) {
      projectQueryParamsBid.push(
        `project_category[]=${selectedCategories.join("&project_category[]=")}`
      );
    }
    if (selectedSpecialitie.length > 0) {
      projectQueryParamsBid.push(
        `speciality[]=${selectedSpecialitie.join("&speciality[]=")}`
      );
    }
    if (selectedSkill?.length > 0) {
      projectQueryParamsBid.push(`skills=${selectedSkill.join("&skills=")}`);
    }
    if (selectedLanguage.length > 0) {
      projectQueryParamsBid.push(
        `language_of_converse[]=${selectedLanguage.join(
          "&language_of_converse[]="
        )}`
      );
    }
    if (selectedLocation.length > 0) {
      projectQueryParamsBid.push(`country[]=${selectedLocation.join("&country[]=")}`);
    }
    if (selectedExperience) {
      projectQueryParamsBid.push(`experience=${selectedExperience}`);
    }
    const queryStringBid = projectQueryParamsBid.join("&");
    return queryStringBid;
  };
  onSendMessage=async(freelancerId:string,projectId:string)=>{
    const inviteChatMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    inviteChatMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Chat"
    );
    inviteChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      createConversation:true,
      userId:freelancerId,
      projectId:projectId,
      userType:"freelancer"
    });
    inviteChatMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(inviteChatMessage);
  }

  getSingleProjectInfoData=async(redirect?:boolean,id?:string)=>{
    if(redirect){
       this.redirectToEditPage=true;
    }
    const projectId=id || this.props.navigation?.getParam("id");
    if(!projectId){
      return;
    }
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleProjectInfoApiUrl}/${projectId}`,
      body: null,
      method: "GET",
    });

    this.getSingleProjectApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }


  handleProjectLoadMore = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        () => {
          this.prepareFinalProjectFetchQuery();
        }
      );
    }
  };
  appendURLSearchParams=(queryArr:string[])=>{
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('c');
    if(category){
      queryArr.push(
        `project_category[]=${category}`
      );
    }


  }
  prepareFinalProjectFetchQuery = () => {
    const PROJECT_PER_PAGE=5;
    let finalProjectQuery: string[] = [];
    const { searchProjectText, queryProjectText, currentPage,profileid } = this.state;
    if(!profileid){
      return;
    }
    const isProposalPage=window.location.pathname.includes("ListOfAllPraposals")
    if(!isProposalPage){
      finalProjectQuery.push(`profile_id=${this.state.profileid}`)
      finalProjectQuery.push(`current_page=${currentPage}`);
      finalProjectQuery.push(`per_page=${PROJECT_PER_PAGE}`);
      const tabQuery = this.getTabProjectQueryValue();
      if (tabQuery) {
        finalProjectQuery.push(tabQuery);
      }
      const pageQuery = `project_status=${this.props.name}`;
      if (pageQuery) {
        finalProjectQuery.push(pageQuery);
      }
    }

    if (searchProjectText) {
      finalProjectQuery.push(`search=${searchProjectText}`);
    }
    if (queryProjectText) {
      finalProjectQuery.push(queryProjectText);
    }

    if(!queryProjectText.includes("project_category")){
       this.appendURLSearchParams(finalProjectQuery);
    }
  
    const newQueryText = finalProjectQuery.join("&");
    if(isProposalPage){
      this.getAllBids(newQueryText);
    }else{
      this.getMyProjects(newQueryText);
    }
  };
  getMyProjects = async(query: string) => {
    this.setState({
      loading: true,
    });
    const token = await getStorageData("loginSuccessToken")
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/projects/get_my_project?${query}`,
      body: null,
      method: "GET",
    });
    this.ProjectListID = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  handleChipClick = (index: number) => {
    this.setState((prev)=>({
      chipNames:prev.chipNames.map((item,itemIndex)=>({...item,isActive:index===itemIndex})),
      projectdata:[],
      currentPage:1
    }),()=>{
      this.prepareFinalProjectFetchQuery();
    })
  };

  //project manage methods

  getAllBids = (query?:string) => {
    this.setState({getBidLoading:true})
    let id = this.props.navigation?.getParam("id")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authtoken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.sponsorBidsListCallID = requestMessage.messageId;
    let apiUrl=`${configJSON.getAllBidsEndPoint}?bid[project_id]=${ Number(id)}&`;
    if(query){
      apiUrl+=query;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  favourite = (isFavouriteProfileID:number,projectId:number) => {

    let body = {
      favourite: {
        lancer_profile_id: isFavouriteProfileID,
        project_id:projectId
      },
      project_id:projectId
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authtoken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteBidCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteBidEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }; 

  deleteConfirmationDialog = (id:number) => {
    this.setState({isOpen:true, projectId:id})
  }

  handleCloseDialog = () => {
    this.setState({isOpen:false})
  }

  deleteProject = async () => {
    const profileId = this.state.profileid;

    const header = {
      token: this.state.authtoken,
    };

    const formdata2 = new FormData();
    formdata2.append("profile_id", profileId);

    const deleteProjectRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteProjectId = deleteProjectRequest.messageId;

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteProjectApiEndPoint}/${this.state.projectId}`
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata2
    );

    deleteProjectRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(deleteProjectRequest.id, deleteProjectRequest);
  };

  bidding_scope = () => {
    let id = this.props.navigation?.getParam("id")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authtoken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.BidScpoeCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.biddingScope}?scope=${this.state.selectCatagory_name?.toLowerCase()}&project_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Adarsh
  hadleListAllProject = () => {
    this.props.navigation.navigate("ListOfAllPraposals");
  };

  // Customizable Area Start

  // Customizable Area End


  // Customizable Area Start

  // PopUp Notification
  handlePraposalDetailNavigation = (id: any) => {
    this.props.navigation.navigate("PraposalDetails", { id: id });
  }
  // Customizable Area End

  // Customizable Area Start

  showMore = () => {
    this.setState({ count: this.state.count + 4 })
  }

  
  handleLike = (item: any, index: any) => {
  
    this.setState({ isFavouriteProfileID: item?.profile_id }, () => {
      this.favourite(this.state.isFavouriteProfileID , item?.project_id)
    })

    let isLike = this.state?.sponsorBidData;
    let currentData: any = isLike[index];
    let indexData = currentData?.attributes?.is_favourite_freelancer;
    currentData["attributes"]['is_favourite_freelancer'] = !indexData
  }
  handleGoBack = () => window.history.back();

  handleSelectCatagory = () => {
    this.setState({ selectedCategoryButton: 0 }, () => {
      this.getAllBids();
    })
  }

  handleSelectCatagoryButton = (item: any, index: any) => {
    this.setState({ selectedCategoryButton: index + 1, selectCatagory_name: item }, () => {
        this.bidding_scope()
    })
  }
  handleShareProject =(projectId:string)=>{
    const projectURL = `${configJSON.projectURL}/${projectId}`
    window.open(`${configJSON.facebookURL}${projectURL}`, "_blank")

  }

  handleCopyClick = async (projectId:string) => {
    try {
      await navigator.clipboard.writeText(`${configJSON.projectURL}/${projectId}`);
      toast.success("Link Copied")
    } catch (error) { toast.error('Failed to copy text');}
  };

  redirectToChat = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Chat"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }
  // Customizable Area End
}
