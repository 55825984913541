import React, { useState } from 'react'
import ContactusForm from '../ContactusForm';
import { webStyle } from '../ViewProfile.web';
import { Dialog,DialogContent } from "@material-ui/core";
interface Props{
  email:string;
  onLogout:()=>void;
}
const ProfileSetupHeader:React.FC<Props>=({email})=>{
  const sortCode=email?.length>2 ?email.substring(0,2):email;
  const [openDialog, setHandleOpenDialog] = useState(false)
  const handleCloseDialog = () =>{
    setHandleOpenDialog(false)
  }
    return(
      <>
        <style>
          {`
            .MuiDialog-paperWidthSm {
              width:100%;
              max-width:400px;
              border-radius:15px;
            }
          `}
        </style>
        <header className="header-right">
        <ul className="main-list">
          <a
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              color: "#8083A3",
              textDecoration: "none",
              fontWeight: 700,
              cursor: 'pointer'
            }}
            onClick={() => setHandleOpenDialog(true)}
          >
            Help and Support
          </a>
          <li>
            <div className="mui-dropdown">
              <button
                className="mui-btn dropdown-btn mui-btn--raised"
                data-mui-toggle="dropdown"
              >
                <span className="id-text">{sortCode}</span>{email}
                <span className="mui-caret" />
              </button>
            </div>
          </li>
        </ul>
      </header>
      <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="sm"
          style={webStyle.mainmodal}
        >
          <DialogContent style={webStyle.dialogContent}>
            <ContactusForm handleCloseDialog={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </>
    )
}


export default ProfileSetupHeader;