import React from 'react'

import { graterthen, location } from "../../../blocks/auctionbidding/src/assets";
import Rating from "@material-ui/lab/Rating";
import { Box, Grid } from '@material-ui/core'
import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { OnlineBadge, OfflineBadge } from '../../../blocks/auctionbidding/src/ListOfAllPraposals.web';
import { profileIcon } from '../../../blocks/favourites/src/assets';

interface Props {
    name: any;
    city?: any;
    country?: any;
    totalRatingCount?: number | any;
    rating?: number;
    id: any;
    profileImage:{url:string}[] | null | undefined;
    sponsorData?: any;
}

const AboutSponsor: React.FC<Props> = ({
    name = "",
    country = "India",
    city = "Banglore",
    totalRatingCount = 0,
    rating = 0,
    id,
    profileImage,
    sponsorData,
}) => {
    const img=profileImage ? profileImage[0]?.url : profileIcon;
    const availability = sponsorData?.attributes?.profile?.availability
    const BadgeComponent = availability? OnlineBadge :OfflineBadge
    return (
        <Box className="projects_filter_wrapper">
            <h3 style={{ color: "black" }} 
            className="freelancer_resume">About the Sponsor</h3>
            <Grid container spacing={1} className="aboutTheSponsor_Box">
                <Grid item xs="auto" className="aboutTheSponsor_inner_Box">
                    <Box className="imageInnerBox">
                                <BadgeComponent
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    variant="dot"
                                    className="userStatus"
                                >
                                    <img src={img} />
                                </BadgeComponent>
                    </Box>
                    <Box className='resSponserGap' style={{ marginLeft: "20px" }}>
                        <Box className="expert_name_container commanClass">
                            <span className='truncate_name' style={{ fontWeight: "bold", color: "black" }}>{name}</span>
                            <img style={{ marginLeft: "10px" }} src={graterthen} alt="image" width={15} />
                        </Box>
                        <Box className="location_container commanClass">
                            <img style={{ marginRight: "10px" }} src={location} alt="location" width={15} /> <span className='truncate_name'>{city}, {country}</span>
                        </Box>
                        <Box>
                            <Link to={`/sponsorprofile/${id}`} className="amount_to_paid"
                                data-testid="aboutus"
                                style={{ cursor: "pointer" }}
                            >
                                View Profile
                            </Link>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs="auto">
                    <Rating
                        name="simple-controlled"
                        value={rating}
                    />
                    <Box className="projects_ratings">
                        <span style={{ color: "black", paddingRight:'5px' }} className="fontstyle_wrapper">
                            {rating.toFixed(2)} </span><span className="location_container">({totalRatingCount} Reviews)</span>
                       
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AboutSponsor;