import React from "react";
// Customizable Area Start
import {
    Box, Typography, Button
} from "@material-ui/core";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import GenericTab from "../../../components/src/GenericTab";
import ProjectCard from "../../../components/src/ProjectCard.web";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import CircularProgress from "@material-ui/core/CircularProgress";
import { notFoundImg } from "../../auctionbidding/src/assets";
// Customizable Area End
// Customizable Area Start

const tabs = [
    "All",
    "Quote Open Projects",
    "Non-Quote Projects",
];
// Customizable Area End

import FavouriteLancerProjectController,
{ Props, configJSON }
    from "./FavouriteLancerProjectController.web";


export default class FavouriteLancerProject extends FavouriteLancerProjectController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        // Customizable Area Start
        const { activeTab, favProjectList, favProjectLoading ,totalProjects} = this.state;
        const isProjectShowMoreEnableBtn = totalProjects > favProjectList?.length;

        // Customizable Area End
        return (

            // Customizable Area Start
            <>
                 <style>
                    {`
                        @media(max-width: 767px) {
                            .resMainDiv {
                                max-width: 90% !important;
                            }
                        }
                    `}
                </style>
                <FreelancerHeader navigation={this.props.navigation} id={""} route={"favourites"} />
                <div className="resMainDiv" style={webStyle.mainDiv}>
                    <Box style={webStyle.favBox}>
                        <Typography style={webStyle.favText}>
                            {configJSON.headerFavouriteText}
                        </Typography>
                    </Box>
                    <span style={webStyle.popUpMsg}>{configJSON.popUpTextMessagelancer}</span>

                    <Box style={{ marginTop: "10px" }}>
                        <ProjectFilterWeb
                            onSearch={this.favSearchHandlers}
                            onFilter={this.favFilterHandlers}
                            onCancelFilter={this.favCancelFilterHandlers}
                        />
                    </Box>
                    <Box style={webStyle.projectDetailsTab}>
                        <GenericTab
                            className={"alltabs"}
                            activeTabColor={"#EC952D"}
                            tabLabels={tabs}
                            activeTab={activeTab}
                            handleTabChange={this.favHandleTabChange}
                        />
                    </Box>
                    {!favProjectLoading && favProjectList?.length === 0 && (
                        <Box style={webStyle.not_found_container}>
                            <img style={webStyle.not_found_image} src={notFoundImg} alt="not found image" />
                            <Typography style={{ color: "rgb(128, 131, 163)" }}>No Favourites Found</Typography>
                        </Box>
                    )}

                    {favProjectList?.map((card: any, index: number) => (
                        <ProjectCard
                            key={card?.id}
                            card={card}
                            onSubmitBidClick={this.favNavigateToSubmitBidPage}
                            onBodyClick={this.favNavigateToBidProjectDetails}
                            onShowSimilar={this.onShowSimilar}
                            fav={this.addAndRemoveFavouritesProject}
                            
                        />

                    ))}
                    <Box style={webStyle.loadMoreBtns}>
                        {favProjectLoading && (
                            <Box>
                                <CircularProgress />
                            </Box>
                        )}

                        {isProjectShowMoreEnableBtn && !favProjectLoading && (
                            <Button
                                style={{ width: '137px', borderRadius: '30px', backgroundColor: '#F2F2F2', textTransform: 'capitalize' }}
                                variant="contained"
                                endIcon={<ChevronRightSharpIcon />}
                                onClick={this.handleProjectShowMore}
                                hidden={favProjectLoading}
                            >
                                Show More
                            </Button>
                        )
                        }
                    </Box>
                </div>
                <div style={{width:"100%", marginTop:"6%"}}>
                    <DashboardFooter navigation={undefined} history={undefined} location={undefined} />
                </div>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const webStyle = {
    projectDetailsTab: {
        margin: "24px 0px",
    },
    abc: {
        minWidth: 120,
    } as const,
    popUpMsg: {
        marginTop: "10px",
        marginBottom: "30px",
        fontSize: "14px"
    } as const,
    favText: {
        marginTop: "5px",
        fontWeight: "bold",
        fontSize: "28px",
        color: "#222"
    } as const,
    favBox: {
        textAlign: "left",
    } as const,
    mainDiv: {
        maxWidth: "80%",
        margin: "0 auto",
    },
    loadMoreBtns: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: "300px",
        justifyContent: "center",
        margin: "auto",
    } as const,
    not_found_container:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        border:"1px solid rgba(0,0,0,.1)",
        borderRadius:"20px",
        padding:"20px",
        gap:"25px"
    } as const,
    not_found_image:{
        height:"120px",
        width:"120px",
        objectFit:"cover",
    } as const,
};
// Customizable Area End
