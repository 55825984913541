import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import { checkMobileNumberLength } from "../../../components/src/Scale";
import { removeStorageData } from "framework/src/Utilities";


export interface IsnackBarProps {
  isOpen: boolean;
  message: string;
  snackBarStyle: string;
  onCloseState: () => void;
}
interface IPasswordVisibilityState {
  currentPassword: boolean;
  newPassword: boolean;
  confirmPasswordVisible: boolean;
}


export const phoneValidationSchema = Yup.object({
  countryCode:Yup.string(),
  dialCode:Yup.string(),
  mobile_number: Yup.string().required("Please enter mobile number")
  .test('mobile_number', 'Mobile number must be between 6 to 12 digits', function(value) {
    return checkMobileNumberLength(value, this.parent.dialCode.length)
  })
  

})


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;

  // Customizable Area End
}

interface S {
  profileid:string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isEmailVerifyBtnClicked: boolean;
  authToken: any;
  newEmailId: string;
  verificationCode: string;
  password: string;
  confirmPassword: string;
  renderView: string;
  currentUserEmailId: string;
  currentUserPassword: string;
  currentUserMobileNumber: {
    number:string;
    country:string;
    dialCode:string;
    phone_error:string;
  };
  renderEmailView: string;
  newPassword: string;
  currentPassword: string;
  forgotPasswordEmail: string;
  forgotPasswordEmailVerificationCode: string;
  passwordChangedWithCurrentPassword: boolean;
  mobileChangedWithCurrentMobile: boolean;
  otpVerificationFlag: boolean;
  PasswordVisibilityState: IPasswordVisibilityState;
  forgotError: boolean;
  passwordChangedWithOtpStatus: boolean;
  mobileNumber: string;
  newMobile:string;
  verificationCodeErrorMsg: string;
  forgotPsswordEmailError: string;
  passwordMismatchError: string;
  renderMobileview: string;
  countryCode:string;
  mobileapidata:any;
  loader:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrentUserDetailsId: string = "";
  emailVerificationCodeId: string = "";
  verificationCodeId: string = "";
  getEditEmailVerificationId: string = "";
  changePasswordWithCurrentPasswordId: string = "";
  changePasswordWithOtpId: string = "";
  getprofileid:string="";
  updateAccountdetailId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      loader:false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileid:"",
      isEmailVerifyBtnClicked: false,
      renderView: "passwordView",
      renderEmailView: "emailView",
      authToken: window.localStorage.getItem('loginSuccessToken'),
      newEmailId: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      currentUserEmailId: "",
      currentUserPassword: "",
      currentUserMobileNumber: {
        country:"",
        number:"",
        dialCode:"",
        phone_error:""
      },
      mobileapidata: {
        country:"",
        number:"",
      },
      newPassword: "",
      currentPassword: "",
      forgotPasswordEmailVerificationCode: "",
      forgotPasswordEmail: "",
      passwordChangedWithCurrentPassword: false,
      mobileChangedWithCurrentMobile: false,
      otpVerificationFlag: false,
      PasswordVisibilityState: {
        currentPassword: false,
        newPassword: false,
        confirmPasswordVisible: false,
      },
      forgotError: true,
      passwordChangedWithOtpStatus: false,
      verificationCodeErrorMsg: "",
      forgotPsswordEmailError: "",
      passwordMismatchError: "",
      renderMobileview: 'mobileView',
      newMobile:"",
      mobileNumber: "",
      countryCode:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleResponseWithoutErrors(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(errorReponse);
        if (apiRequestCallId === this.changePasswordWithCurrentPasswordId) {
          toast.error(responseJson.errors[0].profile);
        } else if (apiRequestCallId === this.changePasswordWithOtpId) {
          toast.error(responseJson.errors[0].password);
        } else if (apiRequestCallId === this.verificationCodeId) {
          this.setState({
            verificationCodeErrorMsg: responseJson.errors[0].otp,
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getProfileId()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.profileid !== this.state.profileid) {
      this.getCurrentUserDetails();
} 
  }

  async handleResponseWithoutErrors(apiRequestCallId: any, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getCurrentUserDetailsId:
        this.setState({
          currentUserEmailId: responseJson.email,
        });
        if(responseJson.phone_number){
          this.setState({
            mobileNumber: responseJson.phone_number.slice(7, 10),
          });
        }
        break;
        case this.getprofileid:
          this.handleProfileResponsePayload(responseJson);
          break;
      case this.emailVerificationCodeId:
        this.setState({ renderEmailView: "verificationCodeView" });
        break;
      case this.verificationCodeId:
        if (responseJson.messages[0].otp === "OTP validation success") {
          this.setState({
            renderEmailView: "emailView",
          });
          toast.success("Your Email is verified successfully")
        }
        break;
      case this.getEditEmailVerificationId:
        this.setState({loader:false})
        toast.success(configJSON.linkSendMsg);
        this.setState({renderView:"passwordView" });
        break;
      case this.changePasswordWithCurrentPasswordId:
        if(responseJson.data.id !== "") {
          toast.success("Your Password changed successfully")
          await removeStorageData('loginSuccessToken');
          setTimeout(() => {
              window.location.href = '/';
          }, 2000);
        }
        break;
      case this.changePasswordWithOtpId:
        this.setState({
           renderView:"passwordView" });
           toast.success("Your Password is changed successfully")
        break;
      case this.updateAccountdetailId:
        if(responseJson.data.id !== ""){
          this.setState({
            renderMobileview: "mobileView",
          });
          toast.success("Your Mobile Number is changed successfully")

          this.getCurrentUserDetails();
        }
        break;
      default:
        break;
    }
  }


  handleProfileResponsePayload=(responseJson:any)=>{
    this.setState({ 
      profileid: responseJson.data.id, 
      currentUserMobileNumber:{ 
          country:responseJson.data.attributes.country_code,
          number:responseJson.data.attributes.mobile_number,
          dialCode:"",
          phone_error:""
      }, 
      mobileapidata :{
        country:responseJson.data.attributes.country_code,
        number:responseJson.data.attributes.mobile_number,
      }
    }
  )
  }
  handlePhoneInputChange=(phone:string,data:{dialCode:string,countryCode:string})=>{
    this.setState((prevState) => ({
        currentUserMobileNumber: {
          ...prevState.currentUserMobileNumber,
          number: phone,
          dialCode:data?.dialCode,
          country:data?.countryCode?.toUpperCase()
        },
      }
    ),this.isValidPhoneNumber)
  }

  isValidPhoneNumber=()=>{
    const {currentUserMobileNumber}=this.state;
    try{
      phoneValidationSchema.validateSync({
            countryCode:currentUserMobileNumber.country,
            dialCode:currentUserMobileNumber.dialCode,
            mobile_number:currentUserMobileNumber.number
        }, { abortEarly: false })
        this.setState((prev)=>({
          currentUserMobileNumber: {
              ...prev.currentUserMobileNumber,
              phone_error:""
          },
        }))
        return true;
    }catch(err){
      const errors=err as any;
      this.setState((prev)=>({
        currentUserMobileNumber: {
            ...prev.currentUserMobileNumber,
            phone_error:errors?.errors[0]
        },
      }))
      return false;
    }
  }


  handleEmailVerify = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };
    const httpBody = {
      data: {
        attributes: {
          ...(this.state.newEmailId
            ? {
                email: this.state.newEmailId,
                current_user_email: this.state.currentUserEmailId,
              }
            : {
                email: this.state.currentUserEmailId,
              }),
        },
      },
    };

    const emailVerificationCodeRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailVerificationCodeId = emailVerificationCodeRequest.messageId;

    emailVerificationCodeRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordOtp
    );

    emailVerificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    emailVerificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    emailVerificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(
      emailVerificationCodeRequest.id,
      emailVerificationCodeRequest
    );
  };

  getProfileId = () => {
    const header = {
      token: this.state.authToken,
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getprofileid = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileIdEndpoint
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  };

  handleCountryCodeChange = (event: { target: { value: any; }; }) => {
    this.setState((prevState) => ({
      currentUserMobileNumber: {
        ...prevState.currentUserMobileNumber,
        country: event.target.value,
      },
    }));
  };

  resetForm = () => {
    this.setState((prevState) => ({
      currentUserMobileNumber: {
        ...prevState.currentUserMobileNumber,
        country: this.state.mobileapidata.country,
        number: this.state.mobileapidata.number,
      },
    }));
    this.setState({forgotPasswordEmail : "", renderView: "passwordView"})
  };

  maskPhoneNumber(phoneNumber:any) {
    if (phoneNumber.length >= 6) {
      const firstThree = phoneNumber.substring(0, 3);
      const lastThree = phoneNumber.substring(phoneNumber.length - 3);
      const masked = `+${firstThree} ***** ${lastThree}`;
      return masked;
    }
    return phoneNumber;
  }


  resetpasswordform = () =>{
    this.setState({
      currentPassword:"",
      newPassword:"",
      confirmPassword:""
    })
  }

  handleUpdateBasicDetails = async (values: any) => {

    const formData = new FormData();

    formData.append("[profile][mobile_number]",this.state.currentUserMobileNumber.number);

    const header = {
      token: this.state.authToken,
    };

    const updateDetailRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAccountdetailId = updateDetailRequest.messageId;

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateBasicDetailsEndPoint}/${this.state.profileid}`
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    updateDetailRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(updateDetailRequest.id, updateDetailRequest);
  };

  handleRenderView = (view: string) => {
    this.setState({ renderView: view });
  };

  getCurrentUserDetails = async () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };
    const httpBody = {
      profile_id: this.state.profileid,
    };

    const getCurrentUserDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrentUserDetailsId = getCurrentUserDetailsRequest.messageId;

    getCurrentUserDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserInfo
    );

    getCurrentUserDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getCurrentUserDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getCurrentUserDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(
      getCurrentUserDetailsRequest.id,
      getCurrentUserDetailsRequest
    );
  };

  cancelVerificarionCode = () => {
    this.setState({ renderEmailView: "emailView" });
  };

  handleNewEmailVerificationCode = async () => {
    this.setState({loader:true})
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const editEmailVerificationRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditEmailVerificationId = editEmailVerificationRequest.messageId;

    editEmailVerificationRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logins/forgot?email=${this.state.currentUserEmailId}`
    );

    editEmailVerificationRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    editEmailVerificationRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(
      editEmailVerificationRequest.id,
      editEmailVerificationRequest
    );
  };

  handleEmailView = (view: string ) => {
    if (view !== null) {
      this.setState({ renderEmailView: view });
      switch (view) {
        case "newEmailView":
          this.handleNewEmailVerificationCode();
          break;
        case "verificationCodeView":
          this.handleEmailVerify();
          break;
        default:
          break;
      }
    }
  };

  handleMobileView = (view: string) => {
    this.setState({ renderMobileview: view })
  }

  verifyVerificationCode = async () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const httpBody = {
      data: {
        attributes: {
          email: this.state.currentUserEmailId,
          otp: this.state.verificationCode,
        },
      },
    };

    const verificationCodeRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verificationCodeId = verificationCodeRequest.messageId;

    verificationCodeRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpEndPoint
    );

    verificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    verificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    verificationCodeRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(verificationCodeRequest.id, verificationCodeRequest);
  };
  

  handleChangePasswordWithCurrentPassword = async () => {
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({ passwordMismatchError: configJSON.paswrdErrorMsg });
    } else {
      const header = {
        token: this.state.authToken,
        "Content-Type": "application/json",
      };

      const httpBody = {
        data: {
          current_password: this.state.currentPassword,
          new_password: this.state.newPassword,
        },
      };
  
      const profileId = await getStorageData("profileId");

      const changePasswordWithCurrentPasswordRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePasswordWithCurrentPasswordId =
        changePasswordWithCurrentPasswordRequest.messageId;

      changePasswordWithCurrentPasswordRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.ChangePassswordWithCurrentPasswordEndpoint}${profileId}`
      );

      changePasswordWithCurrentPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );

      changePasswordWithCurrentPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      changePasswordWithCurrentPasswordRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );

      runEngine.sendMessage(
        changePasswordWithCurrentPasswordRequest.id,
        changePasswordWithCurrentPasswordRequest
      );
    }
  };

  togglePasswordVisibility = (field: keyof IPasswordVisibilityState) => {
    this.setState((prevState) => ({
      PasswordVisibilityState: {
        ...prevState.PasswordVisibilityState,
        [field]: !prevState.PasswordVisibilityState[field],
      },
    }));
  };

  handleChangePasswordOtp = async () => {

    if (this.state.confirmPassword !== this.state.newPassword){
      this.setState({ passwordMismatchError: configJSON.paswrdErrorMsg});
    } else {
      const header = {
        token: this.state.authToken,
        "Content-Type": "application/json",
      };

      const httpBody = {
        data: {
          new_password: this.state.newPassword,
          password_confirmation: this.state.confirmPassword,
        },
      };

      const changePasswordWithOtpRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePasswordWithOtpId = changePasswordWithOtpRequest.messageId;

      changePasswordWithOtpRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.changePassswordWithOtpEndpoint
      );

      changePasswordWithOtpRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );

      changePasswordWithOtpRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      changePasswordWithOtpRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(
        changePasswordWithOtpRequest.id,
        changePasswordWithOtpRequest
      );
    }

  };

  handleOnChangeInputValues = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "newpassword":
        this.setState({ newPassword: value });
        break;
      case "confirmpassword":
        this.setState({ confirmPassword: value });
        break;
      case "newEmail":
        this.setState({ newEmailId: value });
        break;
      case "verificationCode":
        this.setState({ verificationCode: value });
        break;
      case "currentPassword":
        this.setState({ currentPassword: value });
        break;
      case "forgotPasswordEmail":
        this.setState({ forgotPasswordEmail: value });
        break;
      case "emailverify":
        this.setState({ forgotPasswordEmailVerificationCode: value });
        break;
      default:
        break;
    }
  };

  handleCustomSnackBar = (name:string) => {
    switch (name) {
      case "passwordChangedWithCurrentPassword":
        this.setState({ passwordChangedWithCurrentPassword: false });
        break;
      case "otpVerificationFlag":
        this.setState({ otpVerificationFlag: false });
        break;
        case "passwordChangedWithOtpStatus":
        this.setState({ passwordChangedWithOtpStatus: false });
        break;
      case "mobileChangedWithCurrentMobile":
        this.setState({mobileChangedWithCurrentMobile: false})
        break;
       default:
        break;
    }
  } 

  // Customizable Area End
}
