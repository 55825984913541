import * as Yup from "yup";

const creerExperienceSchema=Yup.object().shape(
    {
      employerName: Yup.string().required("Please enter employer name"),
      jobTitle: Yup.string().required( "Please enter job title"),
      country: Yup.string().required("Please select country"),
      city: Yup.string().required("Please select city"),
      state: Yup.string().required("Please select state"),
      jobDescription: Yup.string().required("Please enter job description"),
      currently_working_here: Yup.boolean().notRequired(),
    }
  )

export const studentFreelancerValidationSchema = Yup.object({
    birthDate: Yup.date().notRequired(),
    skill_ids: Yup.array().min(1, "Please select atleast one skill"),
    hobby_ids: Yup.array().min(1, "Please select atleast one category"),
    certification: Yup.string().notRequired(),
    experience_in_years: Yup.string()
    .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
      if (Number(value) <= 60) {
        return true
      }
      return false
    }),
    educational_qualifications: Yup.array(
      Yup.object({
        birthDate: Yup.date().notRequired(),
        education: Yup.string().required("Please select education"),
        college: Yup.string().required("Please select university/college"),
        field: Yup.string().required("Please select field of study"),
        start_year: Yup.date().required("Please enter start date")
                               .min(Yup.ref('birthDate'),"Start date should be greater than birthdate")
                               .max(Yup.ref('end_year'), 'Start date should be lower than end date'),
        end_year: Yup.date().required("Please enter end date").min(Yup.ref('start_year'), 'End date should be greater than start date'),
        isCollegeIdRequired: Yup.boolean().notRequired(),
        current_study: Yup.boolean().notRequired()
      })
    ),
    college_id: Yup.string().when('educational_qualifications', {
      is: (educational_qualifications) =>
        educational_qualifications?.some((qual: {isCollegeIdRequired: boolean}) => qual.isCollegeIdRequired),
      then: Yup.string().required("Please enter college ID"),
      otherwise: Yup.string().notRequired(),
    }),
    student_id: Yup.mixed().default(null).when('educational_qualifications', {
      is: (educational_qualifications) =>
        educational_qualifications?.some((qual: {isCollegeIdRequired: boolean}) => qual.isCollegeIdRequired),
      then: Yup.mixed()
        .test(
          "required",
          "Please upload a file",
          (value) => {
            if (!value?.name) return false;
            return true;
          }
        )
        .test(
          "fileSize",
          "File size is too large. Max 10MB.",
          (value) => {
            if (!value?.size) return true;
            return value.size <= 10 * 1024 * 1024;
          }
        )
        .test(
          "fileType",
          "Unsupported file format. Only .DOC, .DOCX, .PDF, JPEG, PNG, WEBP allowed.",
          (value) => {
            if (!value?.type) return true;
            const allowedTypes = [
              'application/msword', 
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
              'application/pdf', 
              'image/jpeg', 
              'image/png', 
              'image/webp'
            ];
            return allowedTypes.includes(value.type);
          }
        ),
      otherwise: Yup.mixed().notRequired(),
    }),
    career_experiences: Yup.array()
      .default([])
      .when('experience_in_years', {
        is: (experience_in_years) => experience_in_years && Number(experience_in_years) > 0,
        then: Yup.array(creerExperienceSchema),
        otherwise: Yup.array().notRequired(),
      })
  }); 