import React from "react";
import "./MyBid.css";
import { Box, Fade, Modal, Popover, Snackbar, Typography,InputBase,InputAdornment,Button , Grid ,Divider,Tooltip} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PersonalDetailsController, { configJSON } from "./PersonalDetailsController.web";
import Backdrop from "@material-ui/core/Backdrop";
import { location, leftArrow, heart_blank, heart_black, message, accept_bid, image_close, images, info_circle } from "./assets";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from "moment";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import ProjectLocalTime from "../../../components/src/ProjectLocalTime.web";
import Rating from "@material-ui/lab/Rating";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TernaryCheck } from "../../../components/src/Scale";
import { LoaderWithLoadingText } from "../../../components/src/utill/TimeAndDate.web";

const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
      MuiInputBase: {
        input: {
            '&::placeholder': {
                color: '#8083a3',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
            },
            '&:focus::placeholder': {
                color: '#8083a3',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
            },
        },
    },
    }
});



export default class PraposalDetails extends PersonalDetailsController {

     getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
        return (
            <>
            {freelancerCost?.currency_symbol} {freelancerCost?.amount} {"   "}
            {  sponsorCost?.currency != freelancerCost?.currency &&
            <>
            <span id="proposaldetail" style={{color:"#00a7a2" , fontWeight:400, fontSize:"15px"}} >({sponsorCost.currency_symbol}{sponsorCost.amount})</span>
          <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
            <img style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
          </Tooltip> </>}
            {hourlyRated && " /hr"}
          </>
        )
      } 
    
       getProposedCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
        return (
          <>
            {freelancerCost?.currency_symbol} {freelancerCost?.amount} {"   "}
            {  sponsorCost?.currency != freelancerCost?.currency &&
            <>
            <span style={{color:"#00a7a2" , fontWeight:400, fontSize:"15px"}} >({sponsorCost.currency_symbol}{sponsorCost.amount})</span>
          <Tooltip enterTouchDelay={0} leaveTouchDelay={3000}  title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
            <img style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
          </Tooltip> </>}
            {hourlyRated && " /hr"}
          </>
        )
      } 
    renderBackFunction = () => {
        return (
            <Box
                data-testid="Backbtntestid"
                className="Negotiate_Bid newBid_container"
                onClick={this.handleGoBackButton}
            >
                <img src={leftArrow} alt="image" style={{ paddingRight: "10px" }} />
                <span>Back</span>
            </Box>
        );
    };

    renderLancerDetails = () => {
        const{AuctionBiddingData}=this.state;
        const acceptedNegotiation = this.state.getNegotiationData?.find((item:any)=> item.status === "accept")
        if (!this.state.AuctionBiddingData) {
            return;
        }
        return (
            <Box className="personal-details-wrapper My-cv-latter frelancer_details">

<Grid container style={webStyle.freelancerDetailsStyle}>
               <Grid item xs={12} md={7} style={webStyle.subDetailsStyle}>
                        
                       <Grid container style={{width:"70%"}}>
                            <Grid item md={3} sm={12}>
                                
                                <Box className="imageInnerBox">
                                    <span className="userStatus">
                                        <img src={AuctionBiddingData.attributes?.photo && AuctionBiddingData.attributes.photo.length > 0 ? AuctionBiddingData.attributes.photo[0]?.url : images} />
                                        {TernaryCheck(AuctionBiddingData.attributes?.freelancer_availability,
                                            <svg viewBox="0 0 16 16">
                                                <circle fill="#16A700" stroke="white" stroke-width="2" cx="8" cy="8" r="7" />
                                            </svg>,
                                            <svg viewBox="0 0 16 16">
                                                <circle fill="#FFA500" stroke="white" stroke-width="2" cx="8" cy="8" r="7" />
                                            </svg>)
                                        }
                                    </span>
                                </Box>
                                        
                                
                            </Grid>
                            <Grid item md={9} sm={12}>
                                <Box>
                                    <Box className="heading1">{this.state.AuctionBiddingData.attributes.profile_name} 
                                    </Box>
                                    
                                    <Box style={webStyle.skillStyle} className="location_container margin-container">
                
                                            {this.state.AuctionBiddingData.attributes.profile_skills.map((data: any, index: number) => (
                                                                    <React.Fragment key={index}>
                                                                        {data}
                                                                        {index < this.state.AuctionBiddingData.attributes.profile_skills.length - 1 && " | "}
                                                                    </React.Fragment>
                                            ))}
                                        </Box>
                                        <Box style={webStyle.proposalLocation} className="bid_negititation price_negotiation margin-container">
                                            <Box display="flex" alignItems="center" style={{gap: "5px"}}>
                                                <img src={location} alt="location" width={15} />
                                                <span>{this.state.AuctionBiddingData.attributes.location}{" . "}</span>
                                            </Box>
                                            <Box display="flex" alignItems="center" style={{gap: "5px"}}>
                                                <AccessTimeIcon className="watchIcon" />
                                                <ProjectLocalTime
                                                timezoneData={this.state.AuctionBiddingData.attributes.freelancer_time_zone }
                                                /> <span>{configJSON.localTimeLabel}</span> 
                                            </Box>
                                        </Box>
                                        <span className="amount_to_paid" data-test-id="amount_to_paid_navigatebutton" style={webStyle.viewProfileStyle}
                                    onClick={this.handlenavigation}>
                                        {configJSON.viewProfileLabel}
                                        </span>
                                </Box>
                            </Grid>
                       </Grid>
                </Grid>
                <style>
                    {`
                       @media(max-width: 767px) {
                        .flex-start {
                            justify-content: flex-start !important;
                            flex-wrap: wrap;
                        }
                       }
                    `}
                </style>
                <Grid item xs={12} md={5} style={webStyle.freelancerDetailsGridStyle} className="flex-start">
                    <Box style={webStyle.likeWrapper}

                    onClick={this.handleFavourites}>
                        {this.state.isFavourite ? (
                            <img src={heart_black} alt="like button" width={20} height={21} />
                        ) : (
                            <img src={heart_blank} alt="like button" width={20} height={20} />
                        )}
                    </Box>
                    <Box 

                    style={webStyle.likeWrapper}
                    >
                        <img onClick={this.sendMessage} src={message} alt="like button" width={20} height={20} />
                    </Box>
                    {
                        this.state.AuctionBiddingData.attributes.project.bid_status==="open_bid_project" && 
                         this.state.AuctionBiddingData.attributes.project.negotiable &&  (

                            <Button variant='contained' 
                            disableElevation
                            style={webStyle.negotiationStyle} 
                            onClick={this.handleOpenPopup}
                            className={acceptedNegotiation ? 'disabled-button' : ''}
                            >
                                {configJSON.btnNegotiation}
                            </Button>
                         )
                    }
             
                    <Button 
                    variant='contained' 
                    disableElevation
                    style={webStyle.hireStyle} 
                    data-testid="HireFreelancer" 
                    className="Hire_Freelancer" 
                    onClick={this.handleNavigateToSendAnOffer}>
                    {configJSON.btnHireFreelancer}
                    </Button>
                </Grid>
</Grid>
                <Popover
                    id="1"
                    open={this.state.isOpenNewBidPopup}
                    anchorPosition={{ top: 200, left: 680 }}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClosePopup}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    className="popover_div"
                    style={webStyle.popoverStyle}
                >
                        <Box style={webStyle.inputBaseBoxStyle}>
                        <Box style={webStyle.inputBaseSubStyle}>
                            <InputBase
                                placeholder="Enter Quote Amount"
                                value={this.state.newBidAmount}
                                onChange={this.handleNewBidAmount}
                                style={webStyle.popoverInputStyle}
                                startAdornment={(
                                    <InputAdornment position="start">{this.state?.AuctionBiddingData?.attributes?.currency_conversion?.sponsor?.currency_symbol}</InputAdornment>
                                  )}

                            />
                            <button
                            style={webStyle.sendStyle}
                                className="btn-common-color2"
                                type="submit"
                                onClick={this.handleSentAmount}
                                disabled={this.state.bidLoader}
                            >
                               {this.state?.bidLoader ? <CircularProgress size={18} color="primary"/> : configJSON.btnSendLabel}
                            </button>
                        </Box>
                    </Box>
                </Popover>
                {this.state.NewBidSendBySponsorData?.message == "Sponsor rejected your quote now your not eligible for biding" ?
                    <Snackbar
                        open={this.state.sentNewBidToFreeLancer}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSponsorPopup}
                        className="alert_box_wrapper"
                    >
                        <Alert
                            onClose={this.handleCloseSponsorPopup}
                            severity="error"
                            className="alert_message_Box"
                        >
                            Sponsor rejected your quote now your not eligible for quoting
                        </Alert>
                    </Snackbar>
                    :
                    <Snackbar
                        open={this.state.sentNewBidToFreeLancer}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSponsorPopup}
                        className="alert_box_wrapper"
                    >
                        <Alert
                            onClose={this.handleCloseSponsorPopup}
                            severity="success"
                            className="alert_message_Box"
                        >
                            New Quote Sent Successfully!
                        </Alert>
                    </Snackbar>

                }
            </Box>
        );
    };
    renderRejected = () => {
        return (
            <button className="rejected_by_freelancer">
                <img
                    src={image_close}
                    alt="image"
                    width={20}
                    height={20}
                    className="readonly_ratings"
                />
                Rejected
            </button>
        );
    };
    renderAccepted = () => {
        return (
            <button className="rejected_by_freelancer Accepted-btn">
                <img
                    src={accept_bid}
                    alt="accept"
                    className="readonly_ratings"
                    width={20}
                    height={20}
                />
                Accepted
            </button>
        );
    };
    renderSentBidToClient = () => {
        return (
            <>  
                {
                    this.state.getNegotiationData?.length>0 && (
                        <Box className="personal_details_container">
                             <h1>Negotiated Quotes</h1>
                         </Box>
                    )
                }
                
                <Box className={this.state.getNegotiationData?.length > 0 ? "mouse_event" : ""}>

                    {this.state.getNegotiationData?.map((item: any, index: any) => {
                         const isFromClientEnd = this.isNegotiationsFromSponsorSite(item?.sender);
                         const isRejected = item?.status === "rejected";
                         const isAccepted = item?.status === "accept";
                         const isSentToSponsor = item?.status === "sent_to_sponsor";
                         const convertedCost=item?.currency_conversion;
                         const freelancerConvertedCost=convertedCost?.freelancer;
                         const sponsorConvertedCost=convertedCost?.sponsor;
                        return (
                            <>
                                <Box className="aboutTheSponsor_Box project_common_container">
                                    <Box className="bid_negititation">
                                        <Box className="price_negotiation_outer_wrapper ">
                                            <Box className="price_negotiation">
                                                {isFromClientEnd ? "New Quote Sent By You" : "New Quote Received From Freelancer"}
                                            </Box>
                                            <Box className="price_cantainer">{this.getProjectCostElement(freelancerConvertedCost, sponsorConvertedCost, this.state?.AuctionBiddingData?.attributes?.project?.budget_type !== "fixed_price")}
                                            </Box>
                                        </Box>

                                        <Box className="price_negotiation_outer_wrapper">
                                            <Box className="price_negotiation">{isFromClientEnd ? "Sent On" : "Received On"}</Box>
                                            <Box className="price_cantainer">{moment(item.sent_on).format("DD-MMM-YYYY")}</Box>
                                        </Box>
                                    </Box>

                                    <Box>
                                        {
                                            isAccepted && this.renderAccepted()
                                        }
                                        {
                                            isRejected && this.renderRejected()
                                        }
                                        {
                                          !isFromClientEnd && isSentToSponsor && (
                                                                <>
                                                                    <button
                                                                        data-testid={`rejectbtnTestId${index + 1}`}
                                                                        className="Negotiate_Bid"
                                                                        value="rejected"
                                                                        onClick={(e: any) => this.handleOpenRejectPopupModal(e, item.negotiation)}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                    <button
                                                                        data-testid="acceptbtnTestId"
                                                                        className="Hire_Freelancer"
                                                                        value="accept"
                                                                        onClick={(e: any) => this.handleOpenAcceptPopupModal(e, item.negotiation)}
                                                                    >
                                                                        Accept
                                                                    </button>
                                                                </>
                                                            )
                                        }
                                    </Box>
                                </Box>

                                {this.state.getNegotiationData.length - 1 !== index && <Box className="project_container1"></Box>}
                            </>
                        )
                    })}

                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        className="modal_wrapper"
                        open={this.state.isOpenAcceptModal}
                        onClose={this.handleCloseAcceptPopupModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.isOpenAcceptModal}>
                            <div className="modal_paper">
                                <img src={accept_bid} alt="accept" className="accept_bid" />
                                <h3>Are you sure you want to accept this quote</h3>
                                <Box>
                                    <button
                                        className="Negotiate_Bid"
                                        onClick={this.handleCloseAcceptPopupModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="Hire_Freelancer"
                                        onClick={this.handleAcceptedNewBidFromFreelancer}
                                    >
                                        Accept
                                    </button>
                                </Box>
                            </div>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        className="modal_wrapper"
                        open={this.state.isOpenRejectModal}
                        onClose={this.handleCloseRejectPopupModal}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={this.state.isOpenRejectModal}>
                            <div className="modal_paper">
                                <img src={image_close} alt="accept" className="accept_bid1" />
                                <h3>Are you sure you want to reject this quote</h3>
                                <Box>
                                    <button
                                        className="Negotiate_Bid"
                                        onClick={this.handleCloseRejectPopupModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="Hire_Freelancer"
                                        onClick={this.handleRejectNewBidBySponsor}
                                    >
                                        Reject
                                    </button>
                                </Box>
                            </div>
                        </Fade>
                    </Modal>
                </Box>
            </>
        );
    };
    renderPersonalDetails = () => {
        const convertedCost=this.state?.AuctionBiddingData?.attributes?.currency_conversion;
        const freelancerConvertedCost=convertedCost?.freelancer;
        const sponsorConvertedCost=convertedCost?.sponsor;
        return (
            <Box className="personal_details_container">
                <h1>{configJSON.proposedDetailsHeader}</h1>
                {
                      this.state?.AuctionBiddingData?.attributes?.project?.bid_status==="open_bid_project" && (
         
                        <Box style={webStyle.proposalDetailsBox} className="resPraposalDetails">
                            <Box style={webStyle.proposalDetailsAmount}>
                                {this.getProposedCostElement(freelancerConvertedCost,sponsorConvertedCost,this.state?.AuctionBiddingData?.attributes?.project?.budget_type!=="fixed_price")}
                            </Box>
                            <Box style={webStyle.proposalDetailsTxt}>{configJSON.proposedBidTxt}</Box>
                        </Box>
                      )
                }               
            </Box>
        );
    };
   
    renderLatter = () => {
        return (
            <Box className="personal-details-wrapper">
                <Box className="My-cv-latter">
                 <h2 className="freelancer_resume">{configJSON.coverLetterLabel}</h2>
                    <Typography className="text_content" style={{whiteSpace: 'pre-wrap',wordBreak: 'break-word'}}>
                        {this.state.AuctionBiddingData?.attributes?.proposal}
                    </Typography>
                </Box>
            </Box>
        );
    };

    renderProjectDetails = () => {
        return (
            
            <Box className="personal-details-wrapper" style={{
                maxHeight:"350px" , overflowY:"auto"
            }}>
            <Box className="My-cv-latter">
                <Typography style={webStyle.projectAndReviewsText}>
                    {configJSON.projectDetailsHeader}  ({this.state.AuctionBiddingData?.attributes?.lancer_completed_projects?.data?.length > 0 ? this.state.AuctionBiddingData?.attributes?.lancer_completed_projects?.data?.length : 0})
                </Typography>
                {this.state.AuctionBiddingData?.attributes?.lancer_completed_projects?.data?.map((data: any, index: number) => {
                    return (
                        <>
                            {data.attributes?.project_details && (
                                <Box pt={2.5}>
                                    <Typography style={webStyle.projectNameText}>
                                        {data.attributes.project_details.project_name}
                                    </Typography>
                                </Box>
                            )}
                           {data.attributes?.score && <Box style={webStyle.ratingMainBox}>
                                <Rating
                                    style={webStyle.ratingStarStyle}
                                    disabled
                                    name="simple-controlled"
                                    value={data.attributes.score}
                                />
                                 <Typography style={webStyle.subtitle} component={"p"} variant="subtitle1">
                                <span style={{ color: "black" }}>{data.attributes.score} / 5</span>
                            </Typography>
                            </Box>}
                          {data.attributes?.description &&  <Box>
                                <Typography style={webStyle.pastProjectDescription}>
                                    {data.attributes.description}
                                </Typography>
                            </Box>}
                          { data.attributes?.reviewer_profile?.reviewer_name && <Box>
                                <Typography style={webStyle.pastProjectownerName}>
                                    - {data.attributes.reviewer_profile.reviewer_name} ,  {moment(data.attributes.created_at).format("DD-MMM-YYYY")}
                                </Typography>
                            </Box>}
                            <Box>

                              {this.state.AuctionBiddingData?.attributes?.lancer_completed_projects?.data?.length < index && <Divider />}  
                            </Box>
                        </>
                    )
                })}
                {!this.state.AuctionBiddingData?.attributes?.lancer_completed_projects?.data?.length && 
                <Box paddingTop={2.5} paddingBottom={1} >
                <Typography style={webStyle.reviewTxtStyle}>
                {configJSON.NoData}
                </Typography>
                </Box>
                }
            </Box>
        </Box>
        );
    };

    render() {
        return (
            TernaryCheck(this.state.loader, <LoaderWithLoadingText/>, 
            <ThemeProvider theme={theme}>
                <style>
                    {`
                        .disabled-button{
                            pointer-events: none; 
                            opacity: 0.6;       
                            cursor: not-allowed;  
                        }
                        @media(max-width: 767px) {
                            .resMainWrapper {
                                max-width: 90% !important;
                            }
                        }
                    `}
                </style>
                <Sponserheader navigation={this.props.navigation} id={""} route={""} />
                <Box style={webStyle.mainWrapper} className="resMainWrapper">
                    {this.renderBackFunction()}
                    {this.renderLancerDetails()}
                    {this.renderSentBidToClient()}
                    {this.renderPersonalDetails()}
                    {this.renderLatter()}
                    {this.renderProjectDetails()}
                </Box>
                <Sponserfooter navigation={this.props.navigation}/>
            </ThemeProvider>
            )
        );
    }
}
export const webStyle = {
    skillStyle: {
    fontSize:'14px',
    fontWeight:500
    },
    subtitle: {
        color: "#96939c",
        fontSize: "10px"
    } as const,
    negotiationStyle: {
        // width: '165px',
        height: '46px',
        textTransform:'none' as const,
        borderRadius: '23px',
        background: '#f2f2f2',
        fontSize:'14px',
        fontWeight:600,
        color: '#222222',
        marginLeft:'5px'
    },
    hireStyle: {
        // width: '165px',
        height: '46px',
        borderRadius: '23px',
        background: '#00a7a2',
        color:'#fff',
        fontSize:'14px',
        fontWeight:600,
        textTransform:'none' as const,
        marginLeft:'5px'
    },
    mainWrapper:{
        maxWidth: "80%",
        width: "100%",
        margin: "0 auto"
    },
    popoverInputStyle: {
        border: 'none' as const,
       paddingLeft: '10px',
       marginTop:'8px',
        height: '30px',
        width:'200px'
      },
    freelancerDetailsStyle:{
           display:'flex',
            justifyContent:'space-between',
             width:'100%'
      },
    subDetailsStyle:{
        display:'flex'
    },
    viewProfileStyle :{
        cursor:"pointer", 
        textDecoration:"underline"
    },
    inputBaseBoxStyle:{
        padding:"5px",
    },
    proposalDetailsBox:{
        width: '150px',
        height: '70px',
        borderRadius: '10px',
        background: '#f2f2f2',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column' as const,
        padding:'0 8px'
    },
    proposalDetailsAmount:{
        color: '#222222',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 800,
  },
  proposalDetailsTxt:{
    color: '#8083a3',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
},
popoverStyle:{
    margin:'10px'
},
inputBaseSubStyle:{
    border:'1px solid #e4e6e8',
     borderRadius:'41px'
    },
    proposalLocation:{
        color: '#8083a3',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        display:'flex',
        alignItems:'center'
    },   
freelancerDetailsGridStyle:{ 
    display: "flex" ,
    paddingTop:"10px",
    justifyContent: 'flex-end',
    alignItems: "flex-start",
    gap: "10px"
    },
sendStyle:{
    color:'#fff',
    height:'43px'
},
likeWrapper:{
    borderRadius: '50%',
    backgroundColor: '#f2f2f2',
    minHeight: '40px',
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    cursor: 'pointer',
},

// Project card css
projectDescriptionBox: {
    borderRadius: "15px",
    marginBottom: "24px",
    border: "1px solid rgb(228, 230, 232)",
    cursor: "pointer",
    marginRight: "200px",
    marginLeft: "200px",
},
projectAndReviewsText: {
    fontSize: "18px",
    fontWeight: "bold",
} as const,
projectNameText: {
    color: "#222",
    fontSize: "15px",
    fontWeight: 600,
    marginTop:"10px"
} as const,
ratingMainBox: {
    display: "flex",
    gap: "10px",
    marginTop:"5px",
    alignItems : "flex-start"
},
ratingStarStyle:{
    fontSize:'14px'
},
pastProjectDescription: {
    color: "#222",
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 400,
    marginBottom:"5px",
} as const,
pastProjectownerName: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#8083A3"
} as const,
reviewTxtStyle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#8083A3",
    fontFamily:'inter',
    paddingTop:'10px'
},
  };