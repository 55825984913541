import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { avatarImg } from "./assets";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "../../../framework/src/Utilities";
import actionCable from 'actioncable';
import { toast } from "react-toastify";
import { checkIsBold, checkIsItalic, stripHtmlTags } from "../../../components/src/chat/ChatInput.web";
const urlConfig = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

export interface IConversation {
  id: string;
  type: string;
  new_message_count?:number;
  isEdited?:boolean;
  attributes: {
      messages: {
        updated_at: string
      };
      ticket_status:"active"|"resolve"|"inprogress";
      id: number;
      name: string;
      project_id: number;
      project_name: string;
      participants: {
          user: number;
          owner: boolean;
          userType: string;
          full_name: string;
          profile_image: {
              url: string | null;
          } | null;
          availability:boolean;
          ticket_description: string | null;
      }[];
  }
}
export interface IRepliedMessage{
  data: {
    id: number;
    type: string;
    attributes: {
      message: string;
      sender: string;
      attachments?: {
        id: number;
        url: string;
    }[] | null;
    };
  };
}
interface IPaginationData {
  current_page: number;
  next_page: number | null;
  previous_page: number | null;
  total_data: number;
}

interface ICreateConversationPayload{
  createConversation:boolean;
  projectId:number|string;
  userId:string;
  userType:string;
}
export interface IMessage {
  id: string;
  updated?:boolean;
  type: string;
  sending?:boolean;
  localId?:string;
  attributes: {
      edited:boolean;
      id: number;
      message: string;
      deleted: boolean;
      chat_id: number;
      created_at: string; 
      updated_at: string; 
      repliedMessage: boolean;
      attachments?: {
          id: number;
          url: string;
      }[] | null;
      sender: string;
      replied_message: IRepliedMessage| null; 
  };
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  //chat states
  mobileOpen: boolean;
  conversationList: IConversation[];
  messageList: IMessage[];
  selectedConversation: IConversation | null;
  repliedMessage: IMessage | null;
  deleteMessage: IMessage | null;
  deleteMessageModal: boolean;
  currentUser:IProfile|null;
  getConversationLoading:boolean;
  getMessageLoading:boolean;
  messagePagination:IPaginationData;
  autoScroll:boolean;
  searchText:string;
  chatOptionEl:any;
  newMessageSent:boolean;
  editMessage:IMessage|null;
  openEdit:boolean;
  editMessageLoading:boolean;
  alreadyCalledCreateChat:boolean;
  sendingError:boolean;
  isBold:boolean;
  isItalic:boolean,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getConversationListApiCallId:string="";
  sendNewMessageApiCallId:string="";
  getMessageApiCallId:string="";
  deleteMessageApiCallId:string="";
  needToSelectChatId:string="";
  editMessageApiCallId:string="";
  createConversationApiCallId:string="";
  createConversationPayload:any={};
  cableConsumer:any=null;
  alreadyCalledCreateConversation:boolean=false;
  newConversationData:IConversation | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LoginUserMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      mobileOpen: false,
      conversationList:[],
      messageList: [],
      selectedConversation:null,
      repliedMessage: null,
      deleteMessage: null,
      deleteMessageModal: false,
      currentUser:null,
      getConversationLoading:false,
      getMessageLoading:false,
      messagePagination:{
        current_page:1,
        next_page:0,
        previous_page:null,
        total_data:0,
      },
      autoScroll:true,
      searchText:"",
      chatOptionEl:null,
      newMessageSent:false,
      editMessage:null,
      openEdit:false,
      editMessageLoading:false,
      alreadyCalledCreateChat:false,
      sendingError:false,
      isBold: false,
      isItalic: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const loginMessage = getName(MessageEnum.LoginUserMessage);
    const payloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.id === loginMessage) {
        const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
        if (user) {
            this.handleCurrentUserResponse(user);
        }
    }

    if (message.id === payloadMessage) {
        const itemData: any = message.getData(getName(MessageEnum.SessionResponseData));
        if (!this.alreadyCalledCreateConversation) {
            this.alreadyCalledCreateConversation = true;
            this.handleNavigationPayload(itemData);
        }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleApiRequest(apiRequestCallId, responseJson);
    }

    if(apiRequestCallId === this.sendNewMessageApiCallId){
      if (responseJson && responseJson.error){
        toast.error(`Message Not Sent. ${responseJson.error}`)
      }
      this.setState({sendingError:true})
    }
  }

  handleNavigationPayload = (itemData: any) => {
    if (itemData?.response?.data?.id) {
        this.needToSelectChatId = itemData.response.data.id;
        this.newConversationData = itemData.response.data;
    } else if (itemData?.responseJson?.data?.id) {
        this.needToSelectChatId = itemData.responseJson.data.id;
        this.newConversationData = itemData.responseJson.data;
    }

    if (itemData?.createConversation) {
        this.createConversationPayload = itemData;
        this.createNewConversation(this.createConversationPayload);
    }
  };

  handleCurrentUserResponse=(user:any)=>{
    if(user?.id){
      this.setState({ currentUser: user },()=>{
       this.createConsumer();
       this.getConversationList();
      });
    }
  }

  handleApiRequest=(apiRequestCallId:string,responseJson:any)=>{
    if (apiRequestCallId === this.getConversationListApiCallId) {
      this.handleGetConversationListPayload(responseJson);
    } 
    if (apiRequestCallId === this.getMessageApiCallId) {
      this.handleGetMessagePayload(responseJson);
    }
    if (apiRequestCallId === this.editMessageApiCallId) {
      this.handleEditMessagePayload(responseJson);
    }
     if (apiRequestCallId === this.createConversationApiCallId) {
      this.handleCreateConversationPayload(responseJson);
    }
  }
  handleCreateConversationPayload = (responseJson: { data: IConversation }) => {
    if (responseJson?.data) {
        this.needToSelectChatId = responseJson.data.id;
        this.newConversationData = responseJson.data;
    }
  };

  handleEditMessagePayload = (responseJson: any) => {
    if (responseJson?.data?.id) {
        this.setState((prev) => ({
            editMessage: null,
            autoScroll: false,
            openEdit: false,
            messageList: prev.messageList.map((item) => {
                return item.id === responseJson.data.id
                    ? { ...responseJson.data, updated: true }
                    : item;
            }),
        }));
    }
  };

  getMergedArray=(data:IMessage[]=[])=>{
     const {newMessageSent,messageList}=this.state;
     if(newMessageSent){
        const prevMessageIds=messageList.map((item)=>item.id);
        const newMessages=data.filter((item)=>!prevMessageIds.includes(item.id));
        return [...newMessages,...messageList]
     }else{
      return  [...data,...messageList]
     }
  }

  handleGetMessagePayload = (responseJson: any) => {
    const { messagePagination, messageList } = this.state;
    let newMessageList: IMessage[] = [];
    
    if (messagePagination?.current_page > 1 && messageList.length > 0) {
        newMessageList = this.getMergedArray(responseJson?.messages?.data || []);
    } else {
        newMessageList = responseJson?.messages?.data || [];
    }

    this.setState({
        messageList: newMessageList,
        getMessageLoading: false,
        messagePagination: responseJson?.pagination || {},
        autoScroll: messagePagination.current_page > 1 ? false : true,
        newMessageSent: false,
    });
  };

  handleGetConversationListPayload = (responseJson: any) => {
    const { currentUser } = this.state;
    const userId = currentUser?.attributes?.account_detail?.data?.id;
    const conversationList = responseJson?.data?.length > 0 ? responseJson.data : [] as IConversation[];

    let updatedConversationList: IConversation[] = [];

    conversationList.forEach((item: IConversation) => {
        const participants = item.attributes?.participants || [];
        const oppositeParticipants = participants.filter((p) => p.user?.toString() !== userId)
            .map((p) => (p.userType === "Admin" ? { ...p, full_name: "Admin" } : p));
        updatedConversationList.push({
            ...item,
            attributes: {
                ...item.attributes,
                participants: oppositeParticipants.length > 0 ? oppositeParticipants : participants,
            },
        });
    });

    if (this.newConversationData) {
        const isExisting = updatedConversationList.some((data) => data.id === this.newConversationData!.id);
        if (!isExisting) updatedConversationList.unshift(this.newConversationData);
    }

    updatedConversationList.sort((a, b) => {
        if (a.id === this.newConversationData?.id) return -1;
        if (b.id === this.newConversationData?.id) return 1;
        return (b.attributes?.messages?.updated_at || '').localeCompare(a.attributes?.messages?.updated_at || '');
    });

    this.setState(
        {
            conversationList: updatedConversationList,
            getConversationLoading: false,
        },
        this.processConversationList
    );
  };

  processConversationList = () => {
    const { conversationList } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const paramsChatId = urlParams.get('chat_id') || "";

    if (!this.needToSelectChatId && paramsChatId) {
        this.needToSelectChatId = paramsChatId;
    }

    if (conversationList.length > 0 && this.needToSelectChatId) {
        const conversation = conversationList.find((item) => item.id === this.needToSelectChatId) || conversationList[0];
        this.handleSelectConversation(conversation, false);
        this.needToSelectChatId = "";
    } else if (conversationList.length > 0) {
        this.handleSelectConversation(conversationList[0], false);
    }
    
    this.subscribeToAllChannel();
  };

  subscribeToAllChannel=()=>{
      const {conversationList}=this.state;
      conversationList.forEach((item)=>this.subscribeToConversation(item.id))
  }
  
  async componentDidMount() {
    this.sendGetCurrentUserMessage();
  }

   async componentWillUnmount(){
    this.cableConsumer.disconnect();
    runEngine.debugLog("Connection closed")
  }

  createConsumer=async()=>{
    const token=await getStorageData("loginSuccessToken");
    const connectionBaseUrl = urlConfig.baseURL.replace('https://','');
    const protocol:string=window.location.protocol === 'https:' ? 'wss' : 'ws';
    const cableUrl=`${protocol}://${connectionBaseUrl}/cable?token=${token}`;
    const cable=actionCable.createConsumer(cableUrl)
    this.cableConsumer=cable;
  }

 subscribeToConversation=async(conversationId:string)=>{
  if(this.isSubscribed(conversationId)){
    runEngine.debugLog("Already Connected")
    return;
  }
  this.cableConsumer.subscriptions.create({
    channel:"ChatChannel",
    id:conversationId
  },
    {
        received: this.onReceiveBroadcust,
        connected:this.onConnected,
        disconnected:this.onDisConnected,
        rejected:this.onRejected
    })
  
 }
 onConnected=()=>{

  runEngine.debugLog("Connected")
 }

 isSubscribed=(conversationId:string)=>{

  const subscriptions=this.cableConsumer.subscriptions.subscriptions;
  let isAlreadySubscribed:boolean=false
  subscriptions.forEach((item:any)=>{
    const identifier=item.identifier;
    let id="";
    if(identifier){
     id= JSON.parse(identifier)?.id;
     if(id===conversationId){
        isAlreadySubscribed=true;
     }
    }
  })
  return isAlreadySubscribed;
 }


 onReceiveBroadcust=(updatedRoom:any) => {
  runEngine.debugLog("Chat Broadcast",updatedRoom)
  const {selectedConversation}=this.state;
  if(updatedRoom?.message?.id){
    const updatedMessage=updatedRoom.message as IMessage;
    const updatedMessageConversationId=updatedMessage?.attributes?.chat_id?.toString();
     const conversationInActive=selectedConversation?.id as string===updatedMessageConversationId;
     if(conversationInActive){  
        this.handleReceiveActivateConversationMessage(updatedMessage)
     }else{
       this.handleReceiveInActivateConversationMessage(updatedMessageConversationId,updatedMessage.attributes.edited);
     }
  }
}

handleReceiveActivateConversationMessage=(updatedMessage:IMessage)=>{
  const {messageList}=this.state;
  let messageExists=false;
  const updatedMessageList:IMessage[]=messageList.map((item)=>{
    if(item.id===updatedMessage.id?.toString()){
      messageExists=true;
      return {
        ...updatedMessage,
        attributes:{
         ...updatedMessage.attributes,
         sender:item.attributes.sender
        }
      };
    }
    return item;
  })
  if(messageExists){
    this.setState({messageList:updatedMessageList})
  }else{
    this.setState((prev)=>({messageList:[...prev.messageList.filter((item)=>!item?.localId),{...updatedMessage}],newMessageSent:true}))
  }
}

handleReceiveInActivateConversationMessage=(updatedMessageConversationId:string,isEdited:boolean=false)=>{
  this.setState((prev)=>({
    conversationList:prev.conversationList.map((item)=>{
      if(item.id===updatedMessageConversationId){
        let messageCount=item?.new_message_count || 0;
        if(!isEdited){
          messageCount+=1;
        }
        return{
          ...item,
          new_message_count:messageCount,
          isEdited:isEdited
        }
      }
      return item;
    })
  }))
}

 onDisConnected=()=>{
  runEngine.debugLog("Disconnected...")
 }
 onRejected=()=>{
  runEngine.debugLog("Rejected...")
 }
 createNewConversation=async(payload:ICreateConversationPayload)=>{
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token,
    "Content-Type": "application/json",
  };
  const {currentUser}=this.state;
  const isLoggedinUserSponsor=currentUser?.currentState?.toLowerCase()?.includes("sponsor");
  const body={
    chat:{
      account_id:payload.userId,
      user_id:currentUser?.attributes?.account_detail?.data?.id,
      other_user_type:isLoggedinUserSponsor?"sponsor":"freelancer",
      user_type:payload.userType,
      project_id:payload.projectId
    }
  }
  const apiRequestMessage = CreateRestApiMessage({
    header,
    apiUrl: `bx_block_chat/chats`,
    body: JSON.stringify(body),
    method: "POST",
  });
  this.createConversationApiCallId= apiRequestMessage.messageId;
  this.createConversationPayload={};
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  
 }

  handleSearchText=(value:string)=>{
    this.setState({searchText:value})
  }
  getFilteredConversationList=()=>{
     const {conversationList,searchText}=this.state;
     if(!searchText?.trim()){
      return conversationList;
     }
     return conversationList.filter((item)=>{
       const projectName=  item?.attributes?.project_name?.toLowerCase() || "";
       const userName=item?.attributes?.participants[0]?.full_name?.toLowerCase() || "";
       return projectName.includes(searchText.toLowerCase()) || userName.includes(searchText.toLowerCase())
     })
  }


  onRefresh=()=>{
    this.setState({ 
       messageList:[],
       chatOptionEl:null,
       messagePagination:{
        current_page:1,
        next_page:0,
        previous_page:null,
        total_data:0,
      }
       },this.getMessageList);
  }

  onLoadMore=()=>{
    const {messagePagination}=this.state;
    if(messagePagination?.next_page){
      this.setState(prev=>({
         messagePagination:{
          ...prev.messagePagination,
          current_page:prev.messagePagination.next_page || 1,
         }
      }),this.getMessageList)
    }
  }
  getMessageList=async()=>{
    this.setState({getMessageLoading:true})
    const {selectedConversation,messagePagination}=this.state;
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiUrl=`${configJSON.getMessageApiURl}?chat_id=${selectedConversation?.id}&current_page=${messagePagination?.current_page}&per_page=${15}`;
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: apiUrl,
      body: null,
      method: "GET",
    });

    this.getMessageApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }


  getConversationList=async()=>{
    const {currentUser}=this.state;
    this.setState({getConversationLoading:true})

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const userType=currentUser?.currentState?.toLowerCase().includes("sponsor")?"sponsor":"freelancer";
    const userId=currentUser?.attributes?.account_detail?.data?.id;
    
    const apiUrl=`fetch_by_user?account_id=${userId}&user_type=${userType}`;
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: apiUrl,
      body: null,
      method: "GET",
    });

    this.getConversationListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }

handleOpenEditMessage=(message:IMessage)=>{
  const text = message.attributes.message;

  const isBold = checkIsBold(text);
  const isItalic = checkIsItalic(text);

  this.setState({
    editMessage: {
      ...message,
      attributes: {
        ...message.attributes,
        message: stripHtmlTags(text), // Remove tags but keep formatting in state
      },
    },
    isBold: isBold,
    isItalic: isItalic,
    openEdit: true,
  });
};

handleCloseEditMessage=(open:boolean)=>{
  this.setState({
    openEdit:open,
    editMessage:null
  })
}

handleEditMessageFileDelete=(deleteAttachmentId:number)=>{
    const {editMessage}=this.state;
    if(!editMessage){
      return;
    }
    const updatedAttachments=editMessage.attributes.attachments?.map((item)=>{
       if(item?.id===deleteAttachmentId){
         return{
          ...item,
          deleted:true
         }
       }
       return item;
    })

    this.setState({
       editMessage:{
         ...editMessage,
         attributes:{
          ...editMessage.attributes,
          attachments:updatedAttachments
         }
       }
    })

}



handleChatOptionClick = (event: any) => {
   this.setState({
     chatOptionEl:event.currentTarget
  })
};


handleChatOptionClose = () => {
  this.setState({
    chatOptionEl:null
 })
};
  sendGetCurrentUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(msg);
  }

  handleDrawerToogle = () => {
    this.setState((prev) => ({
      mobileOpen: !prev.mobileOpen,
    }));
  };

  handleSelectConversation = (conversation: IConversation,connect:boolean=true) => {
    this.setState({ 
       selectedConversation: conversation,
       conversationList:this.state.conversationList.map((item)=>{
          if(item.id===conversation.id){
            return{
              ...item,
              new_message_count:0,
              isEdited:false,
            }
          }
          return item;
       }),
       mobileOpen: false,
       messageList:[],
       messagePagination:{
        current_page:1,
        next_page:0,
        previous_page:null,
        total_data:0,
      }
       },()=>{
        this.getMessageList()
         connect && this.subscribeToConversation(this.state.selectedConversation?.id as string)
       });
  };
  handleSendMessage = async(message: string, documents: any[]) => {
    const { selectedConversation,currentUser,repliedMessage } = this.state;
    if (!selectedConversation) {
      return;
    }


    const userId=currentUser?.attributes?.account_detail?.data?.id;
    const formData=new FormData();

    formData.append("message[message]",message);
    formData.append("message[chat_id]",selectedConversation?.id);
    formData.append("message[sender_id]",userId?.toString()||"");
    formData.append("message[sender_id]",userId?.toString()||"");
    if(repliedMessage){
      formData.append("message[repliedMessage]","true");
      formData.append("message[message_id]",repliedMessage?.id);
    }
    if(documents){
      documents.forEach((doc)=>{
        formData.append("message[attachments][]",doc);
      })
    }

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.sendMessageApiUrl,
      body: formData,
      method: "POST",
    });

    this.sendNewMessageApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

    const newLocalMessage=this.getLocalMesage(message,documents,userId || "",apiRequestMessage.messageId);
    this.setState((prev)=>({
       messageList:[
        ...prev.messageList,
        newLocalMessage
       ],
       repliedMessage:null,
       newMessageSent:true
    }))
  }; 
  
  handleEditMessage = async(message: string, documents: any[]) => {
    const { editMessage } = this.state;
    if(!editMessage){
      return;
    }
    const deletedAttachmentIds=editMessage?.attributes?.attachments?.filter((item:any)=>item?.deleted).map((item:any)=>item.id);
    const formData=new FormData();

    formData.append("message[message]",message);
    if(deletedAttachmentIds){
      deletedAttachmentIds.forEach((id)=>{
        formData.append("message[blob_ids][]",id)
      })
    }
    if(documents){
      documents.forEach((doc)=>{
        formData.append("message[attachments][]",doc);
      })
    }

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.sendMessageApiUrl}/${editMessage?.id}`,
      body: formData,
      method: "PUT",
    });

    this.editMessageApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    this.setState({
      editMessageLoading:true
    })
  };

  getLocalMesage=(message:string,documents:any[],userId:string,localMessageId:string)=>{
    const {selectedConversation,repliedMessage}=this.state;
    const messageId=new Date().getTime().toString();
    let repMessage:IRepliedMessage|null=null;
    if(repliedMessage){
      repMessage={
        data:{
          id:Number(repliedMessage?.id),
          type:"chat",
          attributes:{
            attachments:repliedMessage?.attributes?.attachments,
          
            message:repliedMessage?.attributes?.message || "",
            sender:repliedMessage?.attributes?.sender || ""
          }
        }
       
      }
    }
   const localMessage:IMessage={
     id:messageId,
     attributes:{
      chat_id:Number(selectedConversation?.id),
      created_at:new Date().toString(),
      deleted:false,
      id:Number(messageId),
      message:message,
      replied_message:repMessage,
      repliedMessage:repliedMessage?true:false,
      sender:userId || "",
      updated_at:new Date().toString(),
      attachments:documents,
      edited:false,
     },
     type:"chat_message",
     localId:localMessageId,
     sending:true,
     
   }

   return localMessage;
  }

  handleSetRepliedMessage = (msg: IMessage | null) => {
    this.setState({
      repliedMessage: msg,
    });
  };
  handleChangeDeleteMessageModal = (
    open: boolean,
    message: IMessage | null
  ) => {
    this.setState({
      deleteMessage: message,
      deleteMessageModal: open,
    });
  };
 handleDeleteMessageModalOpen=(open:boolean)=>{
  this.handleChangeDeleteMessageModal(open, null)
 }
  handleDeleteMessage =async () => {
    const {deleteMessage}=this.state;
    this.setState(
      (prev) => ({
        messageList: prev.messageList.map((msgItem) => {
          if (msgItem.id === prev?.deleteMessage?.id) {
            return {
              ...msgItem,
              attributes:{
                ...msgItem.attributes,
                deleted:true,
                message:"",
                attachments:null
              }
            };
          }
          return msgItem;
        }),
        deleteMessage: null,
        deleteMessageModal: false,
      }),
    );

    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.deleteMessageMessageApiUrl}/${deleteMessage?.id}`,
      body: null,
      method: "DELETE",
    });
    this.deleteMessageApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

  };
 


  //not required methods
  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatView");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  isStringNullOrBlank=(chatName:string)=>{
    return !chatName?.trim()
  }
  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  handleSendingError = () => {
    this.setState({sendingError:false});
  };
  
  toggleBold = (value:any) => {
    this.setState({isBold:value})
  }

  toggleItalic = (value:any) => {
    this.setState({isItalic:value})
  }

  // Customizable Area End
}
