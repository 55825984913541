import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Breadcrumbs,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ProjectTitleDetails from "./ProjectTitleDetails.web";
import ProjectSkillsDetailsPage from "./ProjectSkillsDetailsPage.web";
import ProjectScopeDetailsPage from "./ProjectScopeDetailsPage.web";
import { ProjectBudgetDetailsPageStyles } from "./ProjectBudgetDetailsPage.web";
// Customizable Area End

import ProjecttemplatesController, {
  Props,
  configJSON,
} from "./ProjecttemplatesController";
import CreateNewMileStone from "./CreateNewMilestone.web";

export default class CreateNewProjectPage extends ProjecttemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsViewComponent() {
    const componentMapping: Record<string, JSX.Element> = {
      title: (
        <ProjectTitleDetails
          onComponentChange={this.handleCreateProjectView}
          onChange={this.handleOnChange}
          projectTitle={this.state.projectTitle}
          projectCategory={this.state.projectCategory}
          allProjectCategories={this.state.allProjectCategories}
          specialty={this.state.specialty}
          allProjectSpecialities={this.state.allProjectSpecialities}
          typeOfBids={this.state.typeOfBids}
          number_of_bidders={this.state.number_of_bidders}
          uploadedFiles={this.state.uploadedFiles}
          handleFileDrop={this.handleFileDrop}
          descritption={this.state.descritption}
          formErrors={this.state.formErrors}
          deleteUploadedFile={this.deleteUploadedFile}
          touched={this.state.isTouched}
          handleTouched={this.handleTouched}
        />
      ),
      skills: (
        <ProjectSkillsDetailsPage
        onComponentChange={this.handleCreateProjectView}
        onComponentBackChange={this.handleBackProjectView}
          projectSkills={this.state.projectSkills}
          filterProjectData={this.state.filterProjectData}
          handleSelectSkills={this.handleSelectSkills}
          selectedSkills={this.state.selectedSkills}
          onChange={this.handleOnChange}
          languageItems={this.state.languageItems}
          language={this.state.language}
          languageLevel={this.state.languageLevel}
          formErrors={this.state.formErrors}
          deleteSelectedSkill={this.deleteSelectedSkill}
          addOtherSkills = {this.addOtherSkills}
          otherSkills = {this.state.otherSkills}
          touched={this.state.isTouched}
          handleTouched={this.handleTouched}
        />
      ),
      scope: (
        <ProjectScopeDetailsPage
        onComponentChange={this.handleCreateProjectView}
        onComponentBackChange={this.handleBackProjectView}
          projectSize={this.state.projectSize}
          selectDuration={this.state.projectDuration}
          experienceLevel={this.state.experienceLevel}
          onChange={this.handleOnChange}
          formErrors={this.state.formErrors}
          experienceList={this.state.freelancingExperienceList}
          touched={this.state.isTouched}
          handleTouched={this.handleTouched}
        />
      ),
      budget: (
        <ProjectBudgetDetailsPageStyles
        onComponentChange={this.handleCreateProjectView}
        onComponentBackChange={this.handleBackProjectView}
          lancerPaymentMode={this.state.lancerPaymentMode}
          fixedPrice={this.state.fixedPrice}
          negotiableValue={this.state.negotiableValue}
          projectId={this.state.projectId}
          classes={{}}
          handlePriceChange={this.handlePriceChange}
          navigateReviewPage={this.navigateReviewPage}
          handlePostProject={
            this.handlePostProjectFromBudgetPage
          }
          projectView={this.state.projectStatus}
          updateDraftProject={
            this.updateDraftProject2
          }
          formErrors={this.state.formErrors}
          rateperHour = {this.state.ratePerHour}
          isSaveDraftProjectLoading = {this.state.isSaveDraftProjectLoading}
          currencySymbol={this.state.currentUser?.attributes?.currency_symbol as string}
          touched={this.state.isTouched}
          handleTouched={this.handleTouched}
        />
      ),
      milestone:(
        <CreateNewMileStone
            changeValue={this.changeMileStoneValue}
            createNewMileStone={this.createNewMileStone}
            deleteMileStone={this.deleteMilestone}
            mileStoneList={this.state.milestoneList}
            projectId={this.state.projectId}
            projectView={this.state.projectStatus}
            updateDraftProject={
              this.updateDraftProject2
            }
          isSaveDraftProjectLoading = {this.state.isSaveDraftProjectLoading}
          onComponentChange={this.handleCreateProjectView}
          onComponentBackChange={this.handleBackProjectView}
          handlePostProject={
            this.handlePostProjectFromBudgetPage
          }
          navigateReviewPage={this.navigateReviewPage}
          isFixedPrice={this.state.lancerPaymentMode === "fixed_price"}
          hourlyRate={Number(this.state.ratePerHour)}
          fixedRate={Number(this.state.fixedPrice)}
          editingProject={this.state.editingExistingProject}
          currencySymbol={this.state.currentUser?.attributes?.currency_symbol as string}
          touched={this.state.milestoneTouched}
          handleTouched={this.handleTouchedMilestone}
        />
      )
    };
    const { createNewProjectView } = this.state;

    return componentMapping[createNewProjectView] || null;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.mainContainer}>
        <Box style={webStyle.breadcumbbox}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon fontSize="medium" style={webStyle.iconStyle} />
            }
          >
            <Typography
              style={this.state.createNewProjectView==='title' ? webStyle.breadcumb: webStyle.breadcumbdisable }
              onClick={() => this.handleCreateProjectView("title")}
              data-testid="title"
            >
              <span style={webStyle.circlenum}>{configJSON.numberOne}</span>
              {configJSON.titleLabelTxt}
            </Typography>
            <Typography
              style={this.state.createNewProjectView==='skills' ? webStyle.breadcumb: webStyle.breadcumbdisable }
              onClick={() => this.handleCreateProjectView("skills")}
              data-testid="skills"
            >
              <div style={webStyle.circlenum}>{configJSON.numberTwo}</div>
              {configJSON.skillsLabelTxt}
            </Typography>
            <Typography
              style={this.state.createNewProjectView==='scope' ? webStyle.breadcumb: webStyle.breadcumbdisable }
              onClick={() => this.handleCreateProjectView("scope")}
              data-testid="scope"
            >
              <div style={webStyle.circlenum}>{configJSON.numberThree}</div>
              {configJSON.scopeLabelTxt}
            </Typography>
            <Typography
              style={this.state.createNewProjectView==='budget' ? webStyle.breadcumb: webStyle.breadcumbdisable }
              onClick={() => this.handleCreateProjectView("budget")}
              data-testid="budget"
            >
              <div style={webStyle.circlenum}>{configJSON.numberFour}</div>
              {configJSON.budgetLabelTxt}
            </Typography>
            <Typography
              style={this.state.createNewProjectView==='milestone' ?webStyle.breadcumb: webStyle.breadcumbdisable }
              onClick={() => this.handleCreateProjectView("milestone")}
              data-testid="milestone"
            >
              <div style={webStyle.circlenum}>5</div>
              Milestone
            </Typography>
          </Breadcrumbs>
        </Box>
        {this.renderDetailsViewComponent()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  breadcumbbox: {
    padding: "35px 15px",
    background: "#00a6a2",
    textAlign: "center" as "center",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    display: "flex",
    justifyContent: "center",
  },
  breadcumb: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontFamily: "Inter",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 700,
    cursor: "pointer",
  },
  breadcumbdisable: {
    display: "flex",
    alignItems: "center",
    color: "#99dcda",
    cursor: "pointer",
  },
  circlenum: {
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    padding: "6px",
    background: "#fff",
    border: "1px solid #00a6a2",
    color: "#000",
    marginRight: "10px",
    font: " 14px Arial, sans-serif",
  },
  circlenumdisable: {
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    padding: "6px",
    background: "#99dcda",
    border: "1px solid #00a6a2",
    color: "#000",
    marginRight: "10px",
    font: " 14px Arial, sans-serif",
  },
  mainContainer: {
    border: "1px solid #8083A3",
    borderRadius: "14px",
  },
  iconStyle: {
    color: "#fff",
  },
};
// Customizable Area End
