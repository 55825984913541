import React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from "@material-ui/core";

interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: any;
  message?: string;
  yesText?: string;
  cancelText?: string;
}

const CustomConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  message = "Are you sure?",
  yesText = "Yes",
  cancelText = "Cancel",
}) => {
  return (
    <Box >
        <style>
          {`
            .MuiDialog-paperWidthSm {
              width:100%;
              max-width:380px;
              border-radius:14px;
            }
          `}
        </style>
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent style={webStyle.formContainerTop}>
        <Typography style={webStyle.message}>{message}</Typography>
      </DialogContent>
      <DialogActions style={webStyle.buttonContainer}>
        <Button style={webStyle.saveButton} onClick={onClose}>{cancelText}</Button>
        <Button style={webStyle.saveButton} onClick={onConfirm} variant="contained">{yesText}</Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
};

const webStyle:any= {
    formContainerTop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "25px",
    },
    message:{
        color:"black"
    },
    buttonContainer:{
        padding:"20px",
        justifyContent:"center"
    },
    saveButton: {
        minWidth: '140px',
        borderRadius: '24px',
        padding: '9px 30px',
        color: '#FFFFFF',
        fontSize: '16px',
        margin: '0 15px',
        textTransform: 'none' as const,
        outline: 'none',
        backgroundColor: '#00A7A2'
    },
}

export default CustomConfirmDialog;