import React from "react";

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  InputAdornment
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import {
  Add as AddCircleOutlineIcon,
} from "@material-ui/icons";
import FormError from "../../../components/src/utill/FormError.web";
export const configJSON = require("./config");

const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})(TimelineItem);
// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { ICustomMilestone } from "./ProjecttemplatesController";
interface Props{
  classes?:any;
  createNewMileStone:()=>void;
  mileStoneList:ICustomMilestone[];
  deleteMileStone:(id:string)=>void;
  changeValue:(id:string,key:string,value:string)=>void;
  onComponentChange: (view: string) => void;
  onComponentBackChange: (view:string) => void;
  navigateReviewPage: () => void;
  handlePostProject: () => void;
  projectView: string;
  updateDraftProject: (id: number) => void;
  projectId: number;
  isSaveDraftProjectLoading:boolean;
  isFixedPrice:boolean;
  hourlyRate:number;
  fixedRate:number;
  editingProject:boolean;
  currencySymbol:string;
  touched:any;
  handleTouched:any;
}
interface SS{
  
}
interface S{}
 export class CreateNewMileStone extends BlockComponent<Props,SS,S> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleOnDraftButtonClick=()=>{
      if(this.props.projectView == "3"){
        this.props.updateDraftProject(this.props.projectId)
      }else{
        this.props.handlePostProject();
      }
    }
    getMilestoneCost=(requiredTime:number)=>{
        if(this.props.isFixedPrice){
           return (this.props.fixedRate * requiredTime) / 100;
        }else{
          return this.props.hourlyRate*requiredTime;
        }
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes,createNewMileStone,changeValue,deleteMileStone,mileStoneList,editingProject } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start

      <Box className={`resTopContainer ${classes.cn_topContainer}`}>
        <style>
          {`
          @media(max-width: 680px) {
            .resBtnContainer {
              flex-wrap: wrap;
            }
            .resBtnContainer button {
              width: 100%
            }
          }
            @media(max-width: 480px) {
              .resTopContainer {
                padding: 0;
              }
            }
          `}
        </style>
        <Timeline>
          {
            mileStoneList?.filter((item)=>!item?.deleted)?.map((item,index)=>(
              <StyledTimelineItem key={item?.id}>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  className={classes.cn_addNewMilestoneBtn}
                >
                  {index+1}
                </TimelineDot>
                  <TimelineConnector className={classes.cn_timelineConectorStyles} />
              </TimelineSeparator>
              <TimelineContent>
                <Box className={classes.cn_addNewMilestoneFieldsContainer}>
                  
                      <FormControl fullWidth className={classes.cn_formControlStyle}>
                        <FormHelperText
                          className={classes.cn_labelStyle}
                          component="p"
                        >
                          {configJSON.milestoneTitleLabelTxt}
                        </FormHelperText>
                        <TextField
                          name="addNewMilestoneTitle"
                          onChange={(event) => changeValue(item?.id,"title",event?.target?.value)}
                          value={item?.title}
                          placeholder={configJSON.titlePlaceholder}
                          InputProps={{
                            classes: {
                              input: classes.cn_placeholderStyle,
                              root: classes.cn_inputValueStyle,
                            },
                          }}
                          data-testid="addNewMilestoneTitle"
                          className={classes.cn_valueStyle}
                          onBlur={()=>{this.props.handleTouched('title',true)}}
                          onFocus={()=>{this.props.handleTouched('title',false)}} 
                        />
                        <FormError errors={item.errors} touched={this.props.touched} fieldName="title" />
                      </FormControl>
                      <FormControl fullWidth className={classes.cn_formControlStyle}>
                        <FormHelperText
                          className={classes.cn_labelStyle}
                          component="p"
                        >
                          {configJSON.milestoneDescriptionLabelTxt}
                        </FormHelperText>
                        <TextField
                          minRows={3}
                          multiline
                          name="addNewMilestoneDescription"
                          onChange={(event) => changeValue(item?.id,"description",event?.target?.value)}
                          value={item?.description}
                          placeholder={configJSON.descriptionPlaceholder}
                          InputProps={{
                            classes: {
                              input: classes.cn_placeholderStyle,
                              root: classes.cn_inputValueStyle,
                            },
                          }}
                          data-testid="addNewMilestoneDescription"
                          className={classes.cn_valueStyle}
                          onBlur={()=>{this.props.handleTouched('description',true)}}
                          onFocus={()=>{this.props.handleTouched('description',false)}} 
                        />
                        <FormError errors={item.errors} touched={this.props.touched} fieldName="description" />
                      </FormControl>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            className={classes.cn_formControlStyle}
                          >
                            <FormHelperText
                              className={classes.cn_labelStyle}
                              component="p"
                            >
                              {this.props.isFixedPrice?configJSON.milestoneDuedateLabelTxt:"Required Hours"}
                            </FormHelperText>
                            <TextField
                              type="number"
                              name="addNewMilestoneDueDate"
                              onChange={(event) => changeValue(item?.id,"requiredDay",event?.target?.value)}
                              value={item?.requiredDay}
                              InputProps={{
                                classes: {
                                  input: classes.cn_placeholderStyle,
                                  root: classes.cn_inputValueStyle,
                                },
                              }}
                              data-testid="addNewMilestoneDueDate"
                              placeholder={this.props.isFixedPrice?configJSON.requiredDaysPlaceholder:"Enter Required Hours"}
                              className={classes.cn_valueStyle}
                              onBlur={()=>{this.props.handleTouched('requiredDay',true)}}
                              onFocus={()=>{this.props.handleTouched('requiredDay',false)}}
                            />
                            <FormError errors={item.errors} touched={this.props.touched} fieldName="requiredDay" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {
                            this.props.isFixedPrice?
                            <FormControl
                                className={classes.cn_formControlStyle}
                                fullWidth
                              >
                                <FormHelperText
                                  component="p"
                                  className={classes.cn_labelStyle}
                                >
                                  {configJSON.milestoneDepositAmountLabelTxt}
                                </FormHelperText>
                                <TextField
                                  type="number"
                                  onChange={(event) => changeValue(item?.id,"amount",event?.target?.value)}
                                  value={item?.amount}
                                  name="addNewMilestoneAmount"
                                  className={classes.cn_valueStyle}
                                  InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                    classes: {
                                      input: classes.cn_placeholderStyle,
                                      root: classes.cn_inputValueStyle,
                                    },
                                   endAdornment: <InputAdornment position="end">%</InputAdornment>
                                  }}

                                  placeholder={configJSON.despostiAMountPlaceholder}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  data-testid="addNewMilestoneAmount"
                                  onBlur={()=>{this.props.handleTouched('amount',true)}}
                                  onFocus={()=>{this.props.handleTouched('amount',false)}}
                                />
                                <FormError errors={item.errors} touched={this.props.touched} fieldName="requiredDay" />
                              </FormControl>:(
                                 <FormControl
                                    fullWidth
                                    className={classes.cn_formControlStyle}
                                  >
                                 <FormHelperText
                                   className={classes.cn_labelStyle}
                                   component="p"
                                 >
                                   Milestone Sequence
                                 </FormHelperText>
                                 <TextField
                                   type="number"
                                   name="addSequence"
                                   onChange={(event) => changeValue(item?.id,"sequence",event?.target?.value)}
                                   value={item?.sequence}
                                   placeholder={"Enter sequence"}
                                   className={classes.cn_valueStyle}
                                   InputProps={{
                                     classes: {
                                       input: classes.cn_placeholderStyle,
                                       root: classes.cn_inputValueStyle,
                                     },
                                     inputProps: {
                                       min: 0,
                                   },
                                   }}
                                   InputLabelProps={{
                                     shrink: true,
                                   }}
                                   data-testid="addSequence"
                                  onBlur={()=>{this.props.handleTouched('sequence',true)}}
                                  onFocus={()=>{this.props.handleTouched('sequence',false)}}
                                 />
                                  <FormError errors={item.errors} touched={this.props.touched} fieldName="sequence" />
                                </FormControl>
                              )
                          }
                          
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                      {
                            this.props.isFixedPrice &&
                            (
                               <Grid item xs={12} sm={6}>
                                 <FormControl
                                    className={classes.cn_formControlStyle}
                                    fullWidth
                                  >
                                 <FormHelperText
                                    component="p"
                                   className={classes.cn_labelStyle}
                                 >
                                   Milestone Sequence
                                 </FormHelperText>
                                 <TextField
                                   type="number"
                                   onChange={(event) => changeValue(item?.id,"sequence",event?.target?.value)}
                                   value={item?.sequence}
                                   name="addSequence"
                                   InputProps={{
                                    inputProps: {
                                      min: 0,
                                    },
                                     classes: {
                                       input: classes.cn_placeholderStyle,
                                       root: classes.cn_inputValueStyle,
                                     },
                                    
                                   }}
                                   placeholder={"Enter sequence"}
                                   InputLabelProps={{
                                     shrink: true,
                                   }}
                                   data-testid="addSequence"
                                   className={classes.cn_valueStyle}
                                   onBlur={()=>{this.props.handleTouched('sequence',true)}}
                                   onFocus={()=>{this.props.handleTouched('sequence',false)}}
                                 />
                                  <FormError errors={item.errors} touched={this.props.touched} fieldName="sequence" />
                                </FormControl>
                               </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                           <FormControl
                                className={classes.cn_formControlStyle}
                                fullWidth
                              >
                                <FormHelperText
                                  component="p"
                                  className={classes.cn_labelStyle}
                                >
                                  Milestone Cost
                                </FormHelperText>
                                <TextField
                                  type="number"
                                  disabled
                                  value={this.getMilestoneCost(this.props.isFixedPrice?Number(item.amount):Number(item.requiredDay))}
                                  name="milestonecost"
                                  InputProps={{
                                    inputProps: {
                                        min: 0,
                                    },
                                    classes: {
                                      input: classes.cn_placeholderStyle,
                                      root: classes.cn_inputValueStyle,
                                    },
                                   endAdornment: <InputAdornment position="end">{this.props.currencySymbol}</InputAdornment>
                                  }}

                                  placeholder={"Milestone Cost"}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                          </Grid>
                      </Grid>

                      <Box
                      className={`${classes.cn_sendContainerStyle} ${classes.cn_btnContainerStyle}`}
                    >
                      <Button data-testid="deleteMilestone" onClick={()=>deleteMileStone(item?.id)} className={classes.cn_closeBtnSyles}>
                        Delete
                      </Button>
                    </Box>
                </Box>
              </TimelineContent>
            </StyledTimelineItem>
            ))
          }
           <Box data-testid="addNewMilestone" onClick={createNewMileStone} style={{display:"flex",alignItems:"center",gap:"10px",cursor:"pointer"}}>
              <TimelineDot
                  variant="outlined"
                  className={classes.cn_addNewMilestoneBtn}
                >
                  <AddCircleOutlineIcon />
              </TimelineDot>  
              <Box  className={classes.cn_addNewMilestoneFieldsContainer}>
                   <Typography
                      className={`${classes.cn_addNewmilestoneStyle} ${classes.cn_timelineContentHeaderStyle}`}
                    >
                      {configJSON.addNewMilestoneBtntxt}
                  </Typography>
                </Box>
          </Box>
           
         <Box className={`resBtnContainer ${classes.cn_bottomBtnContainerStyle}`}>
          <Button className={classes.cn_backButtonStyle} 
          data-testid="gobackonbuget"
          onClick={this.props.onComponentBackChange.bind(this,"budget")}>
            {configJSON.backBtnTxt}
          </Button>
          <Box className={classes.backSubmitBtn}>
            {
              !editingProject && (
                <Button
                  data-testid="goBack"
                  className={classes.cn_backButtonStyle}
                  onClick={this.handleOnDraftButtonClick}
                >
                  {this.props.isSaveDraftProjectLoading ? <CircularProgress size={24} /> : configJSON.saveasDraftBtnTxt}
                </Button>
              )
            }
          
            <Button
              disabled={this.props.isSaveDraftProjectLoading}
              data-testid="continue"
              className={classes.cn_continueButtonSyle}
              onClick={this.props.navigateReviewPage}
            >
              {editingProject?configJSON.reviewAndUpdateProjectTxt:configJSON.ReviewandPostProjectTxt}
            </Button>
          </Box>
        </Box>
        </Timeline>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  cn_backButtonStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
  },
  cn_continueButtonSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      background: "#00A7A2",
    },
  },
  cn_bottomBtnContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 40,
    gap: 10,
    marginBottom: 20,
  },
  backSubmitBtn: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    flexWrap: 'wrap' as const,
    width: '100%',
    gap: 10
  } , 
  cn_topContainer: {
    borderRadius: 14,
    border: "1px solid #E4E6E8",
    padding: 25,
  },
  cn_timelineConectorStyles: {
    border: "1px dashed #00A7A2",
    width: "0",
    backgroundColor: "#fff",
  },
  cn_timelineContentHeaderStyle: {
    color: "#222222",
    fontSize: "16px",
    fontWeight: 700,
    marginRight: "0.7vw",
    fontFamily: "Inter",
    marginBottom: 80,
  },
  cn_addNewMilestoneBtn: {
    color: "#00A7A2",
    border: "none",
    backgroundColor: "#E5F6F6",
    width:"30px",
    height:"30px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    fontSize:"14px"
  },
  cn_addNewMilestoneFieldsContainer: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  cn_formControlStyle: {
    margin: "15px 0px",
  },
  cn_labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    margin:"-7px 0 0 0"
  },
  cn_valueStyle:{
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    "& .MuiInputBase-input" :{
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400, 
    }
  },
  cn_placeholderStyle: {
    "&::placeholder": {
      color: "#8083A3",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 400,
    },
  },
  cn_inputValueStyle: {
    color: "#222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  cn_sendContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cn_btnContainerStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    columnGap: 10,
  },
  cn_closeBtnSyles: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      backgroundColor: "#00A7A2",
    },
  },
  cn_addNewmilestoneStyle: {
    cursor: "pointer",
    marginTop: 15,
  },
};

const CreateNewMileStoneStyles = withStyles(webStyles)(
  CreateNewMileStone
);

export default CreateNewMileStoneStyles;
// Customizable Area End
