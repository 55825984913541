import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button,
  Select,
  FormHelperText,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
    CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CheckIcon from "@material-ui/icons/Check";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import FormError from "../../../components/src/utill/FormError.web";


interface S {}

interface SS {
  id: any;
}
interface IProps {
  onComponentChange: (view: string) => void;
  onComponentBackChange: (view:string) => void;
  classes: { [key: string]: string };
  handlePriceChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  lancerPaymentMode: string;
  fixedPrice: string;
  negotiableValue: string;
  navigateReviewPage: () => void;
  handlePostProject: (type: string) => void;
  projectView: string;
  updateDraftProject: (id: number) => void;
  formErrors: IFormErrors;
  projectId: number;
  rateperHour:string;
  isSaveDraftProjectLoading:boolean;
  currencySymbol:string;
  touched:any;
  handleTouched:any;
}
// Customizable Area End

import ProjecttemplatesController, {
  IFormErrors,
  Props,
  configJSON,
} from "./ProjecttemplatesController";

export default class ProjectBudgetDetailsPage extends BlockComponent<
  IProps,
  SS,
  S
> {
  constructor(props: IProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleOnDraftButtonClick=()=>{
    if(this.props.projectView == "3"){
      this.props.updateDraftProject.bind(this,this.props.projectId)
    }else{
      this.props.handlePostProject.bind(this,"3")
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const selectedBtnStyle = `${classes.selectedButton} ${classes.mainBtnStyle}`;
    const radioBtnStyle = `${classes.radioBtnStyle} ${classes.mainBtnStyle}`;

    const btnStyle = this.props.lancerPaymentMode === "fixed_price" ? selectedBtnStyle : radioBtnStyle;

    const className = `resBtnStyle ${btnStyle}`;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container maxWidth={"lg"} className={classes.mainContainer}>
        <Box>
          <style>
            {`
              @media(max-width:480px) {
                .resBtnStyle {
                  left: 0;
                  margin-left: 0;
                  margin-right: 0;
                }
                .resBtnStyle p{
                  font-size: 12px;
                }
                .resBtnStyle svg {
                  width: 18px;
                  height: 18px;
                }
              }

            `}
          </style>
          <RadioGroup
            name="lancerpaymentmode"
            value={this.props.lancerPaymentMode}
            onChange={this.props.handlePriceChange}
            row
            data-testid="lancerpaymentmode"
            className={classes.priceSelectionTabs}
          >
            <FormControlLabel
              value="fixed_price"
              control={
                <Radio
                  checkedIcon={
                    <CheckIcon className={classes.selectedIconStyle} />
                  }
                />
              }
              label={
                <Box className={classes.radioLabelContainerStyle}>
                  <MonetizationOnIcon fontSize="small" />
                  <Typography className={classes.radioBtnLabelStyle}>
                    {configJSON.fixedPriceBtnTxt}
                  </Typography>
                </Box>
              }
              className={className}
            />
            <FormControlLabel
              value="hourly_price"
              control={
                <Radio
                  checkedIcon={
                    <CheckIcon className={classes.selectedIconStyle} />
                  }
                />
              }
              label={
                <Box className={classes.radioLabelContainerStyle}>
                  <QueryBuilderIcon fontSize="small" />
                  <Typography className={classes.radioBtnLabelStyle}>
                    {configJSON.hourlyRateBtnTxt}
                  </Typography>
                </Box>
              }
              className={`resBtnStyle 
              ${this.props.lancerPaymentMode === "hourly_price" 
              ? classes.selectedButton 
              : classes.radioBtnStyle}`}
            />
          </RadioGroup>
          {this.props.lancerPaymentMode === "fixed_price" && (
            <Box id="fixed_price">
              <FormControl fullWidth className={classes.formControlStyle}>
                <FormHelperText className={classes.labelStyle} component="p">
                  {configJSON.amountLabelTxt}
                </FormHelperText>
                <TextField
                  value={this.props.fixedPrice}
                  id={`project_input_fixedPrice`}
                  type="number"
                  data-test-id="test1"
                  placeholder={configJSON.fixedPricePlaceholder}
                  className={classes.fixedPriceStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.props.currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => this.props.handlePriceChange(event)}
                  name="fixedprice"
                  onBlur={()=>{this.props.handleTouched('fixedPrice',true)}}
                  onFocus={()=>{this.props.handleTouched('fixedPrice',false)}}
                />
                <FormError errors={this.props.formErrors} touched={this.props.touched} fieldName="fixedPrice" />
              </FormControl>
            </Box>
          )}
          {this.props.lancerPaymentMode === "hourly_price" && (
            <Box>
              <FormControl fullWidth className={classes.formControlStyle}>
                <FormHelperText className={classes.labelStyle} component="p">
                  {configJSON.rateperHourLabelTxt}
                </FormHelperText>
                <TextField
                  id={`project_input_rateperHour`}
                  type="number"
                  value={this.props.rateperHour}
                  placeholder={configJSON.fixedPricePlaceholder}
                  className={classes.fixedPriceStyle}
                  onChange={(event) => this.props.handlePriceChange(event)}
                  name="rateperHour"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.props.currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  onBlur={()=>{this.props.handleTouched('rateperHour',true)}}
                  onFocus={()=>{this.props.handleTouched('rateperHour',false)}} 
                />
                <FormError errors={this.props.formErrors} touched={this.props.touched} fieldName="rateperHour" />
              </FormControl>
            </Box>
          )}
        </Box>
        <Box className={classes.bottomBtnContainerStyle}>
          <Button className={classes.backButtonStyle} onClick={this.props.onComponentBackChange.bind(this,"scope")}>
            {configJSON.backBtnTxt}
          </Button>
          <Box>
            <Button
              className={classes.continueButtonSyle}
              onClick={this.props.onComponentChange.bind(this,"milestone")}
            >
              {configJSON.continueBtnTxt}
            </Button>
          </Box>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = (theme: any) => ({
  mainContainer: {
    padding: 20,
  },
  selectedButton: {
    zIndex: 2,
    background: "#00A7A2",
    color: "#FFF",
    borderRadius: "30px",
    width: "25rem",
    padding: 5,
  },
  formControlStyle: {
    margin: "15px 0px",
  },
  labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  placeholderStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  fixedPriceStyle: {
    "& .MuiInputBase-input": {
      color: "#8083A3",
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#8083A3",
      fontFamily: "Inter",
      fontSize: 14,
      fontWeight: 400,
    },
  },
  endAdornmentStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
  },
  radioBtnStyle: {
    background: "#F2F2F2",
    borderRadius: "30px",
    color: "#222",
    width: "25rem",
    padding: 5,
  },
  radioBtnLabelStyle: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Inter",
  },
  radioLabelContainerStyle: {
   // marginLeft: "7.5rem",
    display: "flex",
    columnGap: 10,
  },
  selectedIconStyle: {
    color: "#00A7A2",
    background: "#fff",
    borderRadius: "50%",
  },
  bottomBtnContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 40,
    columnGap: 10,
    marginBottom: 20,
  },
  backButtonStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
  },
  continueButtonSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      background: "#00A7A2",
    },
  },
  mainBtnStyle: {
    position: "relative" as "relative",
    left: 40,
  },
  errorStyle: {
    paddingTop: 10,
    color: "#eb020a",
    fontSize:"14px"
  },
  selectItemValuestyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  priceSelectionTabs: {
    flexWrap: "initial"
  } as const
});

export const ProjectBudgetDetailsPageStyles = withStyles(webStyle)(
  ProjectBudgetDetailsPage
);
// Customizable Area End
