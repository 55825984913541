import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
import { getStorageData } from "../../../framework/src/Utilities";
import {toast} from "react-toastify"


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;

}
export interface IFile {
  lastModified: number;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}


export interface Props extends WithStyles{
  navigation:any;
  id:string;
  title:string,
  route:string,
}
export interface Milestone{

    id:string | number,
    type:string,
    attributes:{
      id:string | number,
      milestone_title:string,
      due_date:string | Date,
      deposite_amount: string | number,
      milestone_status: string,
      project_id: string | number,
      milestone_description: string,
      progress_percentage: number,
      created_at: string | Date,
      updated_at: string | Date,
      documents: any,
     }

}
export type RevisedDetails={
  attributes:{
    milestone_status:string,
    milestone_title:string,
    milestone_description:string,
    total_cost:number,
    due_date:string,
    period_status:string,
    cooling_period_status:string,
    validation_date:string,
    cooling_end_date:string,
    
      currency_conversion: {
        sponsor: {
            currency: string,
            currency_symbol:  string,
            amount: number
        },
        freelancer: {
            currency:  string,
            currency_symbol:  string,
            amount: number,
        }
     }
  }
}

export interface S {
  // Customizable Area Start
  tabvalue:number;
  isCompletedProject:boolean;
  isDeletedProject:boolean;
  isDisputedProject:boolean;
  isHoldProject:boolean;
  uploadedFiles: File[];
  msgToSponsor:string;
  milestonesData:any[];
  milestonesPending:Milestone[];
  milestonesComplete:any[];
  milestoneStatus:string[];
  milestoneLoader:boolean;
  documentsError:string;
  sponsorData:any;
  sponsorLoader:boolean;
  btnLoader:boolean;
  milestonesRefunded:Milestone[];
  validationStatus:boolean;
  isActionPresent: boolean;
  RefundactionChange: boolean;
  DisputeActionChange: boolean;
  subRoleName:any;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class PrjSummaryController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
    milestoneDetailsApiCallId: string="";
    milestonesApiCallId: string = "";
    submitMilestoneApiCallId:string = "";
    prjID:string = "";
    sponsorDetailsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

        this.state = {
            // Customizable Area Start
            tabvalue:0,
            isCompletedProject:false,
            isDeletedProject:false,
            isDisputedProject:false,
            isHoldProject:false,
            uploadedFiles: [],
            msgToSponsor:"",
            milestonesData:[],
            milestonesPending:[],
            milestonesComplete:[],
            milestoneStatus:['pending', 'file_submitted','delayed','requested_for_revision','completed','canceled','refund','started', 'on_hold','refund_rejected'],
            milestoneLoader:false,
            documentsError:"",
            sponsorData:[],
            sponsorLoader:false,
            btnLoader:false,
            milestonesRefunded:[],
            validationStatus:false,
            isActionPresent: false,
            RefundactionChange: false,
  DisputeActionChange: false,
            subRoleName: localStorage.getItem("subRoleName")
          // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.getMilestoneDetails();
    this.getSponsorDetails()
    this.prjID= this.props.navigation.getParam("projectid")
    this.getMilestones()
    window.scrollTo(0,0)
    this.setState({
      isCompletedProject:window.location.pathname.split("/")[1] === "CompletedPrjSummary",
      isDeletedProject:window.location.pathname.split("/")[1] === "ClosedPrjSummary",
      isDisputedProject:window.location.pathname.split("/")[1] === "DisputePrjSummary",
      isHoldProject:window.location.pathname.split("/")[1] === "OnHoldPrjSummary"

    })
    
     // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
         // ---------------------  Sponsor response start -----------------------
         if (this.sponsorDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
            if (apiResponse) {
              this.setState({sponsorLoader:false, sponsorData:apiResponse.data})
             
            }
     }
     if (this.milestoneDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setMilestoneDetails(apiResponse)
    }
  
    
        
        // ---------------------  Sponsor response ends -----------------------
     // ---------------------  Milestone response start -----------------------
     if (this.milestonesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
        if (apiResponse) {
          this.handleSuccessMilestone(apiResponse.data)
        }
 }
    
    // ---------------------  milestone response ends -----------------------
     // ---------------------  deliver Milestone response start -----------------------
         if (this.submitMilestoneApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          if (apiResponse?.message) {
            this.handleDeliverMilestoneSuccess(apiResponse.message)
          }
          else if (apiResponse?.error) {
            this.setState({btnLoader:false})
            this.onCancelDetails()
             toast.error(apiResponse?.error)
          }
        }
    // ---------------------  deliver milestone response ends -----------------------
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.tabvalue !== this.state.tabvalue) {
      this.getMilestones();
    }
  }

  handlePendingMilestone=(milestone:Milestone)=>{
    this.setState((prevState) => ({
      milestonesPending: [...prevState.milestonesPending, milestone],
    }));
  }

  handleCompletedMilestone=(milestone:Milestone)=>{
    this.setState((prevState) => ({
      milestonesComplete: [...prevState.milestonesComplete, milestone],
    }));
  }

  handleSuccessMilestone = (resp: any) => {
    const {milestoneStatus } = this.state;
    this.setState({
      milestonesPending: [],
      milestonesComplete: [],
      milestoneLoader: false,
      milestonesData: resp,
    });
  
    const pendingStatus = [milestoneStatus[0],milestoneStatus[7]];
    const completedStatuses = [milestoneStatus[1], milestoneStatus[3], milestoneStatus[4],milestoneStatus[5],milestoneStatus[8],milestoneStatus[6],milestoneStatus[9]];

    resp?.forEach((milestone:any) => {
      if (pendingStatus.includes(milestone.attributes.milestone_status)) {
        this.handlePendingMilestone(milestone);
      } else if (completedStatuses.includes(milestone.attributes.milestone_status)) {
        this.handleCompletedMilestone(milestone);
      }
    });
  };
  
  
  handleDeliverMilestoneSuccess=(message:any)=>{
    this.setState({btnLoader:false})
     this.onCancelDetails()
      this.getMilestones();
      window.scrollTo(0,0)
      toast.success(message)
  }

  
  // ----------------------- get sponsor detail start --------------------------------
  getSponsorDetails = async() => {
    this.setState({sponsorLoader:true})
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sponsorDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sponsorDetailsApiCallUrl + `${this.prjID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
// ----------------------- get sponsor detail end --------------------------------
  getMilestones = async() => {
    this.setState({milestoneLoader:true})
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.contentTypeApi,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.milestonesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.milestonesApiCallUrl + `${this.prjID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

formValidate = () => {
    let isFormValid = true;

    if (this.state.uploadedFiles.length === 0) {
      this.setState({ documentsError: "Please upload a file."});
      isFormValid = false;
    }
    
    return isFormValid
  }

   
// ----------------------- deliver milestone start --------------------------------
submitMilestone = async(id:string) => {

    if (this.formValidate()) 
  {
    this.setState({ documentsError: "", btnLoader:true});
    const token =   await getStorageData('loginSuccessToken', false)
      const header = {
        token: token,
      };
  
    let formdata = new FormData();
  
     this.state.uploadedFiles.forEach((file:any) => {
      formdata.append(`project_milestone[documents][]`, file);
    });
    formdata.append("project_milestone[message_for_sponsor]", this.state.msgToSponsor);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitMilestoneApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.milestonesDeliverApiCallUrl + `${id}/deliver_milestone`
      
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPatchMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    }
    
  }
// ----------------------- deliver milestone ends --------------------------------
  
handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  this.setState({tabvalue:newValue});
};

handleScrollToStatusBtn = () => {
  const targetElement = document.getElementById("statusBtn");
  if (targetElement) {
    const headerOffset = 100;
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

handleChangeStatus = (event: React.ChangeEvent<{}>) => {
  this.setState({tabvalue:1})
  setTimeout(this.handleScrollToStatusBtn, 1000);
  
};

setMilestoneDetails = (apiResponse:any) => {
  let actionChange= false
  for (const date in apiResponse){
  apiResponse[date].forEach((entry:any)=>{
    if(entry?.flag_title==="Dispute has been Raised" ){
      if(entry.description.dispute_request === configJSON.disputeRequestFlag){
        this.setState({DisputeActionChange: true})
                  
  
             
     }
   }else if(entry?.flag_title==="Refund Request Send"){
    if(entry.refund_status === configJSON.refundFlagTxt && !entry.cooling_period_expired){
      this.setState({RefundactionChange:true}) 
  
   }
  }
})}
this.setState({isActionPresent: actionChange})
}

getMilestoneDetails = async() => {
  // this.setState({detailsLoader:true,hideBtn:false})
  const token =   await getStorageData('loginSuccessToken', false)
  const header = {
    token: token,
    "Content-Type": configJSON.contentTypeApi,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.milestoneDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.milestonesDetailsApiCallUrl + `${this.prjID}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiGetMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleFilesDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
 this.setState({documentsError:""})
  if (files) {
    this.setState({uploadedFiles: [...this.state.uploadedFiles, ...Array.from(files)]});
  }
};

deleteUploadedFiles = (fileId: number) => {
  let updatedFiles = this.state.uploadedFiles.filter(
    (file,index) => index !== fileId
  );
  this.setState({ uploadedFiles: updatedFiles });
};

handleMsgChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  this.setState({ msgToSponsor: (event.target as HTMLTextAreaElement).value });
};

onConvertFileSize=(bytes:number)=>{
     if (bytes >= 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + configJSON.labelMB;
    } else {
      return (bytes / 1024).toFixed(2) + configJSON.labelKB;
    }
 }

handleDownloadFile = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(fileUrl);
};

onCancelDetails = ()=>{
  this.setState({msgToSponsor:"", uploadedFiles:[]})
}
goToViewCertificate=async()=>{
  this.props.navigation.navigate("FreelancerViewCertificate",{ projectid:this.state.sponsorData.id});
}

onConvertFileName=(filename:string)=>{
  if (filename.length > 12) {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex !== -1) {
      const firstEight = filename.substring(0, 8);
      const hiddenPart = '*'.repeat(4);
      const extension = filename.substring(dotIndex);
      return `${firstEight}${hiddenPart}${extension}`;
    } else {
      return filename;
    }
  } else {
    return filename;
  }
}
 // Customizable Area End
}

