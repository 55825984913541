import React from 'react';
import Typography from '@material-ui/core/Typography';

const FormError = ({ errors, touched, fieldName }:any) => {
  if (errors?.[fieldName] && touched?.[fieldName]) {
    return (
      <Typography style={{paddingTop: 10, color: "#eb020a",fontSize:"14px"}}>
        {errors[fieldName]}
      </Typography>
    );
  }
  return null;
};

export default FormError;