import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ChatMessageAction from "./ChatMessageAction.web";
import { IMessage } from "../../../blocks/chat/src/ChatController";
import ChatFileViewer from "./ChatFileViewer.web";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat_message_item: {
      display: "flex",
      maxWidth: "100%",
      alignItems: "flex-start",
      gap: "10px",
      position: "relative",
    },
    other_message: {
      alignSelf: "start",
      "& .chat_message_content": {
        textAlign: "left",
        background: "#F6F8F7",
        marginRight: "15px",
        padding: "20px",
        position: "relative",
        borderRadius: "0 10px 10px 10px",
        marginLeft: "12px",
      },
    },
    other_message_container: {
      alignSelf: "start",
      "& .chat_message_content": {
        gap: 0,
        textAlign: "left",
        background: "#F6F8F7",
        marginRight: "15px",
        padding: "10px 20px 17px 20px",
        position: "relative",
        borderRadius: "0 10px 10px 10px",
        marginLeft: "12px",
        "&::before": {
          content: '""',
          position: "relative",
          top: "-10px",
          left: "-29px",
          width: "0",
          height: "0",
          border: "5px solid",
          borderColor: '#F6F8F7',
          borderLeftColor: "transparent !important",
          borderBottomColor: "transparent !important",
        },
      },
    },
    sponsor_message:{
      background:"#EFFEFB !important",
      "&::before": {
        borderColor: '#EFFEFB !important'
      }
    }, 
    freelancer_message:{
      background:"#F6F8F7 !important",
      "&::before": {
        borderColor: '#F6F8F7 !important'
      }
    },
    my_message: {
      alignSelf: "end",
      flexDirection: "row-reverse",
      width: "70%",
      "& .chat_message_content": {
        textAlign: "right",
        background: "#FDFAF3",
        marginRight: "15px",
        padding: "10px 20px",
        position: "relative",
        borderRadius: "10px 0 10px 10px",
        border: "1px solid transparent",
        "& .ripple": {
          position: "absolute",
          right: "-10px",
          top: "0px",
          height: "22px",
          width: "20px",
          //   background: "#FDFAF3",
          background: "red",
          borderBottomRightRadius: "200px",
        },
        "@media(max-width: 480px)": {
          padding: "10px !important"
        },
        "&::before": {
        content: '""',
        position: "absolute",
        top: "-1px",
        right: "-10px",
        width: "0",
        height: "0",
        border: "5px solid",
        borderColor: "#FDFAF3",
        borderRightColor: "transparent !important",
        borderBottomColor: "transparent !important",
      },
      },
    },
    my_reply_message: {
      alignSelf: "end",
      padding: "10px",
      borderRaidus: "10px",
      maxWidth: "70%",
      background: "#f9f9fa",
      marginRight: "20px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      cursor: "pointer",
    },
    other_reply_message: {
      alignSelf: "start",
      padding: "10px",
      borderRaidus: "10px",
      maxWidth: "70%",
      background: "#f9f9fa",
      marginLeft: "20px",
      borderTopLeftRadius: "15px",
      borderTopRightRadius: "15px",
      cursor: "pointer",
    },
    chat_message_option: {},
    chat_content_area: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    chat_docs_container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    chat_doc_image_container: {
      height: "400px",
      maxWidth: "400px",
      borderRadius: "10px",
      padding: "10px",
      position: "relative",
      background: "white",
    },
    chat_doc_image_download: {
      position: "absolute",
      right: "10px",
      top: "10px",
      zIndex: 100,
    },
    chat_doc_img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "10px",
    },
    chat_doc_file: {
      width: "100%",
      height: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      borderRadius: "10px",
    },
    chat_doc_file_left: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    chat_doc_file_right: {},
  })
);

interface Props {
  message: IMessage;
  handleSetRepliedMessage: (msg: IMessage | null) => void;
  myChat: boolean;
  onDelete: (msg: IMessage | null) => void;
  handleEditMessageOpen:(value:IMessage)=>void;
  sponsorUser?:boolean;
  sendingError?:boolean;
}
const MessageItem: React.FC<Props> = ({
  message,
  handleSetRepliedMessage,
  myChat,
  onDelete,
  handleEditMessageOpen,
  sponsorUser,
  sendingError
}) => {
  const classes = useStyles();

  const handleScrollToMessage = (messageId: number) => {
    const msgItem = document.getElementById(`message_item_${messageId}`);

    if (msgItem) {
      msgItem.scrollIntoView({ behavior: "auto" });
    }
  };

  return (
    <div
      id={`message_item_${message.id}`}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "30px",
      }}
    >
      {message?.attributes?.repliedMessage && message?.attributes?.replied_message && (
        <div
          onClick={() => handleScrollToMessage(message?.attributes?.replied_message?.data?.id || 0)}
          className={`${
            myChat ? classes.my_reply_message : classes.other_reply_message
          }`}
        > 
         {message?.attributes?.replied_message?.data?.attributes?.message==="null" &&
         (!message?.attributes?.replied_message?.data?.attributes?.attachments ||
          message?.attributes?.replied_message?.data?.attributes?.attachments?.length===0 )
           && (
            <span style={{ color: "red" }}>Message Deleted</span>
          )}
          {!message?.attributes?.replied_message?.data?.attributes?.message &&
          (message?.attributes?.replied_message?.data?.attributes?.attachments &&
            message?.attributes?.replied_message?.data?.attributes?.attachments?.length>0 )
            && (
              <span>{message?.attributes?.replied_message?.data?.attributes?.attachments?.length} Files</span>
            )}
          {
            message?.attributes?.replied_message?.data?.attributes?.message && 
            message?.attributes?.replied_message?.data?.attributes?.message !="null"
             && (
                <Typography
                    style={{
                      wordBreak: "break-all",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: message?.attributes?.replied_message?.data?.attributes?.message || "",
                    }}
                  ></Typography>
            )
          }
          
        </div>
      )}

      <div
        // ref={messageList?.length - 1 === index ? lastMessageRef : undefined}
        className={`${classes.chat_message_item} ${
          myChat ? classes.my_message : classes.other_message_container
        } `}
      >
        <div className={`${classes.chat_content_area} chat_message_content kalyani   ${myChat && sponsorUser ?classes.sponsor_message:''} ${!myChat && sponsorUser ?classes.freelancer_message:''}`}>
          {/* <div className="ripple" /> */}
          <Typography
            style={{
              wordBreak: "break-word",
              textAlign: "left"
            }}
            dangerouslySetInnerHTML={{ __html: message?.attributes?.deleted?"":message?.attributes?.message }}
          ></Typography>
          {message?.attributes?.deleted && (
            <span style={{ color: "red" }}>Message Deleted</span>
          )}
          <div className={classes.chat_docs_container}>
            {message?.attributes?.attachments?.map((docItem:any,index) => {
              const isLocalMessage=Boolean(message?.localId) || docItem?.name;
              return(
                <ChatFileViewer
                  key={index}
                  file={docItem}
                  isLocalImage={isLocalMessage}
                />
              )
            })}
          </div>
          </div>
        <div>
          {
            myChat && message?.attributes?.deleted ? null :(
              <ChatMessageAction
                myMessage={myChat}
                onReply={() => handleSetRepliedMessage(message)}
                onDelete={() => onDelete(message)}
                onEdit={()=>handleEditMessageOpen(message)}
                isDeleted={message?.attributes?.deleted}
              />
            )
          }
          
        </div>
      </div>
      <div
        className={`${
          myChat ? classes.my_message : classes.other_message_container
        }`}
      style={myChat?{marginRight:"15px"}:{marginLeft:"15px"}}
      >
          {
            !sendingError && message?.sending ? <p>Sending....</p>:(
              <Typography style={{fontSize:"12px"}}>{new Date(message?.attributes?.created_at).toLocaleDateString()} at  {new Date(message?.attributes?.created_at).toLocaleTimeString()}</Typography>
            )
          }
      </div>
    </div>
  );
};

export default MessageItem;
