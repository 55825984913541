import React from 'react';
import {Field, ErrorMessage} from 'formik';
import {TextField} from '@material-ui/core'
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import { error } from 'console';
import { Autocomplete } from '@material-ui/lab'
import './customAutocomplete.css'
import { CustomPaper } from './CountryTextInput.web';
interface Props{
    stateList:string[];
    setFieldValue:any;
    stateLoading:boolean;
    fetchCities:(stateName:string)=>void;
    country:string;
    value:string;
    tabIndex?:number;
    error?:string;
    onBlur?:any;
}
const StateTextInput:React.FC<Props> = ({stateList,setFieldValue,stateLoading,fetchCities,country,value,error,tabIndex,onBlur}) => {
    return (
      <>
        {
          country?.trim() && stateList?.length>0 ?(
            <>
              <div className="typography-label">State*</div>
              <Autocomplete
                tabIndex={tabIndex}
                value={value}
                data-testid="city"
                PaperComponent={CustomPaper}
                onChange={(e: any, newValue: any) => {
                  setFieldValue("state", newValue);
                  fetchCities(newValue)
                  setFieldValue("city", "");
                }}
                options={stateList}
                getOptionLabel={(option: any) => option}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Select your State"
                    name="state"
                    className="custom-autocomplete-textfield"
                  />
                )}
                id="state"
                disabled={!country?.trim()}
                onBlur={onBlur}
              />
                        {/* {!value && (
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error-class"
                          />
                        )} */}
                </>
          ):(
            <div>
              <label htmlFor="state">State*</label>
              <Field
                test-id="state"
                id="state"
                disabled={!country?.trim()}
                name="state"
                placeholder="Enter your State"
                tabIndex={10}
                onChange={(e:any)=>{
                  setFieldValue("state",e.target.value);
                  setFieldValue("city","");
                  }}
                onBlur={onBlur}
                />
                 
            </div>
          )
        }
      
      </>
      
      );
}
 
export default StateTextInput;