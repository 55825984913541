// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import CreateRestApiMessage from "../../../../components/src/CreateRestApiMessage.web";
import React from "react";
import { getStorageData } from "framework/src/Utilities";
import { TernaryCheck } from "../../../../components/src/Scale";
export const configJSON = require("../config");

export interface Props {
  classes?: any;
  loading?: boolean;
  filterOpen?: boolean;
  activeTabColor?: string;
  onSearch: (searchText: string) => void;
  onFilter: (query: string, selectedOptions: any) => void;
  onCancelFilter?: () => void;
  sortInOutside?: boolean;
  searchPlaceholder?: string;
  disableSort?: boolean;
  isUserFreelancer?: boolean;
}
interface ISortingType {
  name: string;
  value: string;
}
interface S {
  searchText: string;
  openFilter: boolean;
  activeSortingItem: number;
  dataList: {
    categoriesList: { name: string; id: number }[];
    languageList: string[];
    skillList: string[];
    locationList: string[];
    experienceList: string[];
    specialitieList: string[];
  };
  selectedCategories: string[];
  selectedLanguage: string[];
  selectedSkill: string[];
  selectedLocation: string[];
  selectedExperience: string[];
  selectedSpecialitie: string[];
  sortingOptions: ISortingType[];
  selectedSort: string;
  searchPlaceholder: string;
  selectedCategoryIds: number[];
}

interface SS { }

export default class ProjectFilterController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoryListApiCallId: string = "";
  getSkillListApiCallId: string = "";
  getSpecialityApiCallId: string = "";
  getLanguageListApiCallId: string = "";
  getCountryListApiCallId: string = "";
  getExperienceListApiCallId: string = "";
  searchTimeout: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);

    this.state = {
      searchText: "",
      openFilter: props.filterOpen || false,
      activeSortingItem: -1,
      dataList: {
        categoriesList: [],
        specialitieList: [],
        languageList: [],
        experienceList: ["Begginer", "Intemediate", "Expert"],
        locationList: ["India", "USA", "Europe"],
        skillList: [],
      },
      selectedCategories: [],
      selectedSpecialitie: [],
      selectedExperience: [],
      selectedLanguage: [],
      selectedLocation: [],
      selectedSkill: [],
      sortingOptions: [{
        name: "Latest",
        value: "sort=latest",
      }, {
        name: "Oldest",
        value: "sort=oldest"
      }
      ],
      selectedSort: "",
      searchPlaceholder: configJSON.placeHolderNameTxt,
      selectedCategoryIds: [],
    };
  }

  async componentDidMount() {
    const urlParamsProjectFilterMount = new URLSearchParams(window.location.search);
    await this.placeholder()
    const name = urlParamsProjectFilterMount.get('name') || "";
    if (name.length != 0) {
      this.setState({
        searchText: name
      }, () => this.props.onSearch(this.state.searchText))
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCategoryListApiCallId) {
        this.handleCategoryListPayload(responseJson);
      }
      if (apiRequestCallId === this.getLanguageListApiCallId) {
        this.handleLanguageListPayload(responseJson);
      }
      if (apiRequestCallId === this.getSpecialityApiCallId) {
        this.handleSpecialityListPayload(responseJson);
      }
      if (apiRequestCallId === this.getSkillListApiCallId) {
        this.handleSkillListPayload(responseJson);
      }
      if (apiRequestCallId === this.getCountryListApiCallId) {
        this.handleCountryDataListPayload(responseJson);
      }
      if (apiRequestCallId === this.getExperienceListApiCallId) {
        this.handleFreelancingExperiencePayload(responseJson);
      }
    }
  }
  handleFreelancingExperiencePayload=(response:any)=>{
    if(response?.errors){
      return;
    }
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        experienceList:response?.map((item:number)=>item.toString()) ||[]
      },
    }));
}
  handleCountryDataListPayload=(response:any)=>{
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        locationList: response?.countries_name || [],
      },
    }));
  }
  handleCategoryListPayload = (responseJson: any) => {
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        categoriesList: responseJson?.length > 0 ? responseJson : [],
      },
    }));
  };
  handleSkillListPayload = (responseJson: any) => {
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        skillList: responseJson?.length > 0 ? responseJson : [],
      },
    }));
  };

  handleOuterSortChange = (e: any) => {
    this.setState({
      selectedSort: e.target.value
    }, () => {
      this.onFilterHandler();
    })
  }

  handleLanguageListPayload = (responseJson: any) => {
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        languageList: responseJson?.length > 0 ? responseJson : [],
      },
    }));
  };
  handleSpecialityListPayload = (responseJson: any) => {
    const uniqueSpecialities = responseJson.filter((item: any, index: number, self: any[]) =>
      index === self.findIndex((t) => t.id === item.id && t.name === item.name)
    );
    this.setState((prev) => ({
      dataList: {
        ...prev.dataList,
        specialitieList: responseJson?.length > 0 ? uniqueSpecialities.map((data: {name: string})=> data.name) : [],
      },
    }));
  };

  handleSearchTextChange = (e: any) => {
    this.setState({ searchText: e.target.value });
  };
  handleSearchTextKeyPressed = (e: any) => {
    if (e?.key === "Enter") {
      this.onSearchHandler();
    }
  };
  onSearchHandler = () => {
    const { searchText } = this.state;
    this.props.onSearch(searchText);
  };

  getSortingKey = () => {
    const { activeSortingItem } = this.state;

    const sortOBJ:{
      [key:string]:string
    }={
      "0":"order=desc",
      "1":"order=asc",
      "2":"budget_order=desc",
      "3":"budget_order=asc",
      "4":"number_of_bidders_order=desc",
      "5":"number_of_bidders_order=asc",
    }
    return sortOBJ[activeSortingItem.toString()] || "";
  };

  onFilterHandler = () => {
    const {
      selectedCategories,
      selectedSpecialitie,
      selectedLanguage,
      selectedSkill,
      selectedExperience,
      selectedLocation,

    } = this.state;
    let queryParamsBid = [];

    if (selectedCategories.length > 0) {
      queryParamsBid.push(
        `project_category[]=${selectedCategories.join("&project_category[]=")}`
      );
    }

    if (selectedSpecialitie.length > 0) {
      queryParamsBid.push(
        `speciality[]=${selectedSpecialitie.join("&speciality[]=")}`
      );
    }
    if (selectedSkill?.length > 0) {
      queryParamsBid.push(`skills[]=${selectedSkill.join("&skills[]=")}`);
    }
    if (selectedLanguage.length > 0) {
      queryParamsBid.push(
        `language_of_converse[]=${selectedLanguage.join(
          "&language_of_converse[]="
        )}`
      );
    }
    if (selectedLocation.length > 0) {
      queryParamsBid.push(`country[]=${selectedLocation.join("&country[]=")}`);
    }
    if (selectedExperience.length > 0) {
      queryParamsBid.push(`expected_experience=${selectedExperience.join("&expected_experience=")}`);
    }
    let sortingText = "";
    if (this.props.sortInOutside) {
      sortingText = this.state.selectedSort;
    } else {
      sortingText = this.getSortingKey();
    }

    if (sortingText) {
      queryParamsBid.push(sortingText);
    }


    const queryStringBid = queryParamsBid.join("&");
    this.setState({
      openFilter: false,
    })
    this.props.onFilter(queryStringBid, {
      selectedCategories,
      selectedSpecialitie,
      selectedLanguage,
      selectedSkill,
      selectedExperience,
      selectedLocation,
      queryStringBid,
    });
  };

  handleOpenFilter = () => {
    this.setState(
      (prev) => ({ openFilter: !prev.openFilter }),
      () => {
        if (this.state.openFilter) {
          this.getLanguageList();
          this.getSkillList();
          this.getCategoryList();
          this.getCountryList();
          this.getExperienceList();
        }
      }
    );
  };
  handleSortingTabChange = (e: any, newValue: number) => {
    this.setState({ activeSortingItem: newValue });
  };

  handleClearSearchText = () => {
    this.setState({ searchText: "" });
    this.props.onSearch("");
  };
  handleSkillChange = (e: any) => {
    this.setState({ selectedSkill: e.target.value });
  };
  handleCategoryChange = (e: any) => {
    this.setState({ selectedCategories: e.target.value, selectedCategoryIds:[], selectedSpecialitie: [] })
    const selectedNames = e.target.value;
    
    const selectedIds = this.state.dataList.categoriesList
      .filter(category => selectedNames.includes(category.name))
      .map(category => category.id);

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

      this.setState(
      {
        selectedCategories: selectedNames,
        selectedCategoryIds: selectedIds
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.getSpecialityList();
        }, 600);
      }
    );
  };
  handleSpecialityChange = (e: any) => {
    this.setState({ selectedSpecialitie: e.target.value });
  };
  handleLanguageChange = (e: any) => {
    this.setState({ selectedLanguage: e.target.value });
  };
  handleLocationChange = (e: any) => {
    this.setState({ selectedLocation: e.target.value });
  };
  handleExperienceChange = (e: any) => {
    this.setState({ selectedExperience: e.target.value });
  };
  handleClearSelectedOptions = () => {
    this.setState({
      selectedCategories: [],
      selectedCategoryIds: [],
      selectedExperience: [],
      selectedLanguage: [],
      selectedLocation: [],
      selectedSkill: [],
      selectedSpecialitie: [],
      openFilter: false,
    });
    if (this.props.onCancelFilter) {
      this.props.onCancelFilter();
    }
  };
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    return header;
  }
  getExperienceList=async()=>{
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getFreelancingExperienceApiUrl}`,
      method: "GET",
      body: null,
    });

    this.getExperienceListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCountryList=async()=>{
    const header=await this.getHeader();
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCountryListApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getCountryListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCategoryList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/search_algorithms/fetch_category`,
      body: null,
      method: "GET",
    });

    this.getCategoryListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getSkillList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `/bx_block_profile/search_algorithms/fetch_skills`,
      body: null,
      method: "GET",
    });

    this.getSkillListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  getSpecialityList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const categoryIds = this.state.selectedCategoryIds;
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/search_algorithms/fetch_sub_categories?category_ids=[${categoryIds.join(',')}]`,
      body: null,
      method: "GET",
    });

    this.getSpecialityApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  placeholder = async()=> {
    const placeholder = await getStorageData("currentState")==="Sponsor"
    const projectPlaceholderUrls = ["/activeprojects","/projectinprogress","/projectonhold","/completedproject","/disputeproject","/closedproject","/draft", "/editExstingDraft", "/reUsePreviouProject"]
    const isProjectPlaceholderUrl = projectPlaceholderUrls.some((item: any) => 
      window.location.pathname.includes(item)
  );
  if(isProjectPlaceholderUrl){
    this.setState({searchPlaceholder: "Search Project, Skill, or Category"})
  } else {
    this.setState({
      searchPlaceholder: TernaryCheck(placeholder,configJSON.placeHolderNameTxtSponsor,configJSON.placeHolderNameTxt)
    })
  }
    
  }

  getLanguageList = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `/bx_block_profile/search_algorithms/fetch_language`,
      body: null,
      method: "GET",
    });

    this.getLanguageListApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
}

// Customizable Area End
