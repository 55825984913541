import { Dimensions, Platform } from "react-native";
import { toast } from "react-toastify";

//Screen Constatnts
const SCREEN_HEIGHT = 736;
const SCREEN_WIDTH = 414;

const { height, width } = Dimensions.get("window");

/**
 * Function to scale a value based on the size of the screen size and the original
 * size used on the design.
 */
export default function(units = 1) {
  return (width / SCREEN_WIDTH) * units;
}

const verticalScale = (size: number) => (height / SCREEN_HEIGHT) * size;

export { verticalScale };

export const toTitleCase = (text:string) => {
  return text.replace(/(^\w|\s\w)/g, (m:any) => m.toUpperCase())
}

export const TernaryCheck = (condition:any, value1:any, value2:any) => {
  return condition ? value1 : value2
}

export const TernaryCheckToast = (condition:any, value1:any) => {
  if(condition){
    toast.error(value1)
  }
  else toast.success("Record Added Successfully!")
}

export const checkMobileNumberLength = (value: any, dialCodeLength: any) => {
  if (!value) return false;
  let number = value.split("");
  number.splice(0, dialCodeLength);
  const formattedNumber = number.join("");
  const numberLength = formattedNumber.length;
  return numberLength >= 7 && numberLength <= 12;
}